import Report from '../Report';

const dateFormat = require('dateformat');

abstract class ReportBuilder {
    protected generateDescription(): string {
        // Generated on 1 March 2021 at 10:51 PM
        let description = dateFormat(new Date(), '"Generated on" d mmmm yyyy "at" h:MM TT');
        return description;
    }

    public abstract build(): Report;
}

export default ReportBuilder;
