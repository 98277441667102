import { createStyles, Grid, makeStyles, Theme, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import {
    ConversationMetric,
    GET_ACTIVE_USERS_ENDPOINT,
    MessagesSentMetric,
    MessageType,
    WorkplaceMetric
} from '../../types/AnalyticsApi';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';
import getFromAndToDate from '../../utils/getFromAndToDate';
import ConnectionsDataProvider from '../DataProviders/ConnectionsDataProvider';
import ConversationsDataProvider from '../DataProviders/ConversationsDataProvider';
import DashboardDataProvider from '../DataProviders/DashboardDataProvider';
import DataProvider from '../DataProviders/DataProvider';
import IdentitiesDataProvider from '../DataProviders/IdentitiesDataProvider';
import MessagesDataProvider from '../DataProviders/MessagesDataProvider';
import ProfessionsDataProvider from '../DataProviders/ProfessionsDataProvider';
import UsersDataProvider from '../DataProviders/UsersDataProvider';
import WorkplacesDataProvider from '../DataProviders/WorkplacesDataProvider';
import MetricContainer from '../Metric/MetricContainer';
import Panel from '../Panel/Panel';
import PanelContent from '../Panel/PanelContent';
import PanelTitle from '../Panel/PanelTitle';
import SectionHeader from '../Section/SectionHeader';
import DashboardUsersOnlineMetric from './DashboardUsersOnlineMetric';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacer: {
            padding: '0 30px',
            minWidth: '0px'
        },
        onlineText: {
            marginLeft: '10px',
            width: '115px',
            height: '19px',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff'
        }
    })
);

const average = (data: { value: number | null }[]) => {
    let values = data.filter((kvp) => kvp.value != null);
    return values.reduce((a: any, b: any) => a + b.value, 0) / values.length / 100;
};

interface DashboardFixedTimeMetricsProps {
    countryCode: string[] | null;
    companyId: string[] | null;
    departmentId: string[] | null;
    updateInterval?: number;
    permissions: PermissionsState;
}

interface DashboardFixedTimeMetricsState {
    dailyFromString: string;
    weeklyFromString: string;
    monthlyFromString: string;
}

const DashboardFixedTimeMetrics: React.FC<DashboardFixedTimeMetricsProps> = (props) => {
    const [state, setState] = useState<DashboardFixedTimeMetricsState>({
        dailyFromString: getFromAndToDate('Last 24 hours').from!.toISOString(),
        weeklyFromString: getFromAndToDate('Last 7 days').from!.toISOString(),
        monthlyFromString: getFromAndToDate('Last 30 days').from!.toISOString()
    });
    const classes = useStyles();

    const options = {
        countryCode: props.countryCode,
        companyId: props.companyId,
        departmentId: props.departmentId
    };

    // Update time range every once in awhile if needed
    useEffect(() => {
        if (!props.updateInterval) return;

        const timeout = setInterval(() => {
            setState((prevState: DashboardFixedTimeMetricsState) => ({
                dailyFromString: getFromAndToDate('Last 24 hours').from!.toISOString(),
                weeklyFromString: getFromAndToDate('Last 7 days').from!.toISOString(),
                monthlyFromString: getFromAndToDate('Last 30 days').from!.toISOString()
            }));
        }, props.updateInterval);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.updateInterval]);

    let { dailyFromString, weeklyFromString, monthlyFromString } = state;

    let isSuperAdmin = props.permissions.isSuperAdmin;

    return (
        <React.Fragment>
            {/* TODO seperate online metric from fixed time metrics
            At the moment all fixed metrics use the same time value to keep them in sync, but this can result in the
            online metrics appearing higher than it should be
            */}
            <Grid container item spacing={3} justify="space-between">
                <Grid item xs>
                    <SectionHeader>STATISTICS</SectionHeader>
                </Grid>
                <Grid item xs className={classes.spacer} />
                <Grid item xs>
                    <Tooltip title={'Number of users who connected in the last 60 seconds'}>
                        <span>
                            <svg width="14px" height="14px">
                                <circle cx="50%" cy="50%" r="5" stroke="#ffffff" strokeWidth="2" fill="#59c270" />
                            </svg>
                            <span className={classes.onlineText}>
                                <span>ONLINE: </span>
                                <DashboardUsersOnlineMetric {...options} />
                            </span>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid container item spacing={3}>
                <Grid item xs>
                    <Panel>
                        <PanelTitle>Total Users</PanelTitle>
                        <PanelContent>
                            <UsersDataProvider
                                crossSection
                                options={{
                                    ...options
                                }}
                            >
                                <MetricContainer />
                            </UsersDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                {isSuperAdmin && (
                    <>
                        <Grid item xs>
                            <Panel tooltipTitle="Number of active users from the last 24 hours / last 30 days, averaged over the last 30 days">
                                <PanelTitle>DAU/MAU</PanelTitle>
                                <PanelContent>
                                    <DataProvider
                                        src={GET_ACTIVE_USERS_ENDPOINT}
                                        dataFormat={'CROSS_SECTION'}
                                        options={{
                                            ...options,
                                            interval: 'M',
                                            numeratorInterval: 'd',
                                            rolling: true,
                                            percent: true,
                                            from: monthlyFromString
                                        }}
                                        responseHandler={(res: any) => {
                                            return [
                                                {
                                                    key: 'all',
                                                    value: average(res.data)
                                                }
                                            ];
                                        }}
                                    >
                                        <MetricContainer decimalFractionAsPercentage />
                                    </DataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                        <Grid item xs>
                            <Panel tooltipTitle="Number of active users from the last 7 days / last 30 days, averaged over the last 30 days">
                                <PanelTitle>WAU/MAU</PanelTitle>
                                <PanelContent>
                                    <DataProvider
                                        src={GET_ACTIVE_USERS_ENDPOINT}
                                        dataFormat={'CROSS_SECTION'}
                                        options={{
                                            ...options,
                                            interval: 'M',
                                            numeratorInterval: 'w',
                                            rolling: true,
                                            percent: true,
                                            from: monthlyFromString
                                        }}
                                        responseHandler={(res: any) => {
                                            return [
                                                {
                                                    key: 'all',
                                                    value: average(res.data)
                                                }
                                            ];
                                        }}
                                    >
                                        <MetricContainer decimalFractionAsPercentage />
                                    </DataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                    </>
                )}
            </Grid>

            {isSuperAdmin && (
                <>
                    <Grid container item spacing={3}>
                        <Grid item xs>
                            <Panel tooltipTitle="Number of users with a verified identity">
                                <PanelTitle>Users with a Verified Identity</PanelTitle>
                                <PanelContent>
                                    <IdentitiesDataProvider
                                        crossSection
                                        options={{
                                            ...options
                                        }}
                                    >
                                        <MetricContainer />
                                    </IdentitiesDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                        <Grid item xs>
                            <Panel tooltipTitle="Number of users with a verified profession">
                                <PanelTitle>Users with a Verified Profession</PanelTitle>
                                <PanelContent>
                                    <ProfessionsDataProvider
                                        crossSection
                                        options={{
                                            ...options
                                        }}
                                    >
                                        <MetricContainer />
                                    </ProfessionsDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                        <Grid item xs>
                            <Panel tooltipTitle="Number of users with a verified workspace">
                                <PanelTitle>Users with a Verified Workspace</PanelTitle>
                                <PanelContent>
                                    <WorkplacesDataProvider
                                        crossSection
                                        options={{
                                            ...options,
                                            metric: WorkplaceMetric.Verified
                                        }}
                                    >
                                        <MetricContainer />
                                    </WorkplacesDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid container item spacing={3}>
                <Grid item xs>
                    <SectionHeader>DAILY STATS</SectionHeader>
                </Grid>
                <Grid item xs>
                    <SectionHeader>WEEKLY STATS</SectionHeader>
                </Grid>
                <Grid item xs>
                    <SectionHeader>MONTHLY STATS</SectionHeader>
                </Grid>
            </Grid>

            <Grid container item spacing={3}>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of users who connected within the last 24 hours'}>
                        <PanelTitle>Daily Active Users (DAU)</PanelTitle>
                        <PanelContent>
                            <UsersDataProvider
                                active
                                crossSection
                                options={{
                                    ...options,
                                    from: dailyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </UsersDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of users who connected within the last 7 days'}>
                        <PanelTitle>Weekly Active Users (WAU)</PanelTitle>
                        <PanelContent>
                            <UsersDataProvider
                                active
                                crossSection
                                options={{
                                    ...options,
                                    from: weeklyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </UsersDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of users who connected within the last 30 days'}>
                        <PanelTitle>Monthly Active Users (MAU)</PanelTitle>
                        <PanelContent>
                            <UsersDataProvider
                                active
                                crossSection
                                options={{
                                    ...options,
                                    from: monthlyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </UsersDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
            </Grid>

            {isSuperAdmin && (
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of messages sent within the last 24 hours'}>
                            <PanelTitle>Daily Messages Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.NULL],
                                        from: dailyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of messages sent within the last 7 days'}>
                            <PanelTitle>Weekly Messages Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.NULL],
                                        from: weeklyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of messages sent within the last 30 days'}>
                            <PanelTitle>Monthly Messages Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.NULL],
                                        from: monthlyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            )}
            <Grid container item spacing={3}>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of messages exchanged with other users within the last 24 hours'}>
                        <PanelTitle>Daily Messages Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.NULL],
                                    from: dailyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of messages exchanged with other users within the last 7 days'}>
                        <PanelTitle>Weekly Messages Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.NULL],
                                    from: weeklyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of messages exchanged with other users within the last 30 days'}>
                        <PanelTitle>Monthly Messages Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.NULL],
                                    from: monthlyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
            </Grid>

            {isSuperAdmin && (
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of photos sent within the last 24 hours'}>
                            <PanelTitle>Daily Photos Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.Photo],
                                        from: dailyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of photos sent within the last 7 days'}>
                            <PanelTitle>Weekly Photos Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.Photo],
                                        from: weeklyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of photos sent within the last 30 days'}>
                            <PanelTitle>Monthly Photos Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.Photo],
                                        from: monthlyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            )}
            <Grid container item spacing={3}>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of photos exchanged with other users within the last 24 hours'}>
                        <PanelTitle>Daily Photos Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.Photo],
                                    from: dailyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of photos exchanged with other users within the last 7 days'}>
                        <PanelTitle>Weekly Photos Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.Photo],
                                    from: weeklyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of photos exchanged with other users within the last 30 days'}>
                        <PanelTitle>Monthly Photos Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.Photo],
                                    from: monthlyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
            </Grid>

            {isSuperAdmin && (
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of videos sent within the last 24 hours'}>
                            <PanelTitle>Daily Videos Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.Video],
                                        from: dailyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of videos sent within the last 7 days'}>
                            <PanelTitle>Weekly Videos Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.Video],
                                        from: weeklyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of videos sent within the last 30 days'}>
                            <PanelTitle>Monthly Videos Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.Video],
                                        from: monthlyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            )}

            <Grid container item spacing={3}>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of videos exchanged with other users within the last 24 hours'}>
                        <PanelTitle>Daily Videos Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.Video],
                                    from: dailyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of videos exchanged with other users within the last 7 days'}>
                        <PanelTitle>Weekly Videos Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.Video],
                                    from: weeklyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of videos exchanged with other users within the last 30 days'}>
                        <PanelTitle>Monthly Videos Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.Video],
                                    from: monthlyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
            </Grid>

            {isSuperAdmin && (
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of documents sent within the last 24 hours'}>
                            <PanelTitle>Daily Documents Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.PatientFile],
                                        from: dailyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of documents sent within the last 7 days'}>
                            <PanelTitle>Weekly Documents Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.PatientFile],
                                        from: weeklyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of documents sent within the last 30 days'}>
                            <PanelTitle>Monthly Documents Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        type: [MessageType.PatientFile],
                                        from: monthlyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            )}

            <Grid container item spacing={3}>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of documents exchanged with other users within the last 24 hours'}>
                        <PanelTitle>Daily Documents Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.PatientFile],
                                    from: dailyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of documents exchanged with other users within the last 7 days'}>
                        <PanelTitle>Weekly Documents Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.PatientFile],
                                    from: weeklyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>

                <Grid item xs>
                    <Panel tooltipTitle={'Number of documents exchanged with other users within the last 30 days'}>
                        <PanelTitle>Monthly Documents Exchanged</PanelTitle>
                        <PanelContent>
                            <MessagesDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    type: [MessageType.PatientFile],
                                    from: monthlyFromString,
                                    metric: MessagesSentMetric.SentTo
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </MessagesDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
            </Grid>

            <Grid container item spacing={3}>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of connection requests sent within the last 24 hours'}>
                        <PanelTitle>Daily Connections Sent</PanelTitle>
                        <PanelContent>
                            <ConnectionsDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    connectionType: ['Sent'],
                                    from: dailyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </ConnectionsDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of connection requests sent within the last 7 days'}>
                        <PanelTitle>Weekly Connections Sent</PanelTitle>
                        <PanelContent>
                            <ConnectionsDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    connectionType: ['Sent'],
                                    from: weeklyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </ConnectionsDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of connection requests sent within the last 30 days'}>
                        <PanelTitle>Monthly Connections Sent</PanelTitle>
                        <PanelContent>
                            <ConnectionsDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    connectionType: ['Sent'],
                                    from: monthlyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </ConnectionsDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
            </Grid>

            <Grid container item spacing={3}>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of connection requests accepted within the last 24 hours'}>
                        <PanelTitle>Daily Connections Accepted</PanelTitle>
                        <PanelContent>
                            <ConnectionsDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    connectionType: ['Accepted'],
                                    from: dailyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </ConnectionsDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of connection requests accepted within the last 7 days'}>
                        <PanelTitle>Weekly Connections Accepted</PanelTitle>
                        <PanelContent>
                            <ConnectionsDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    connectionType: ['Accepted'],
                                    from: weeklyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </ConnectionsDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
                <Grid item xs>
                    <Panel tooltipTitle={'Number of connection requests accepted within the last 30 days'}>
                        <PanelTitle>Monthly Connections Accepted</PanelTitle>
                        <PanelContent>
                            <ConnectionsDataProvider
                                crossSection
                                options={{
                                    ...options,
                                    connectionType: ['Accepted'],
                                    from: monthlyFromString
                                }}
                                forcedUpdateIntervalMilliseconds={null}
                                throttleMilliseconds={5000}
                            >
                                <MetricContainer />
                            </ConnectionsDataProvider>
                        </PanelContent>
                    </Panel>
                </Grid>
            </Grid>

            {isSuperAdmin && (
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of workspaces created within the last 24 hours'}>
                            <PanelTitle>Daily Workspaces Created</PanelTitle>
                            <PanelContent>
                                <WorkplacesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: dailyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </WorkplacesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of workspaces created within the last 7 days'}>
                            <PanelTitle>Weekly Workspaces Created</PanelTitle>
                            <PanelContent>
                                <WorkplacesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: weeklyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </WorkplacesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of workspaces created within the last 30 days'}>
                            <PanelTitle>Monthly Workspaces Created</PanelTitle>
                            <PanelContent>
                                <WorkplacesDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: monthlyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </WorkplacesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            )}

            {isSuperAdmin && (
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of group invites accepted within the last 24 houros'}>
                            <PanelTitle>Daily Group Invites Accepted</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: dailyFromString,
                                        metric: ConversationMetric.InvitesAccepted
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of group invites accepted within the last 7 days'}>
                            <PanelTitle>Weekly Group Invites Accepted</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: weeklyFromString,
                                        metric: ConversationMetric.InvitesAccepted
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of group invites accepted within the last 30 days'}>
                            <PanelTitle>Monthly Group Invites Accepted</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: monthlyFromString,
                                        metric: ConversationMetric.InvitesAccepted
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            )}

            {isSuperAdmin && (
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of conversations created within the last 24 hours'}>
                            <PanelTitle>Daily Conversations Created</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: dailyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of conversations created within the last 7 days'}>
                            <PanelTitle>Weekly Conversations Created</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: weeklyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of conversations created within the last 30 days'}>
                            <PanelTitle>Monthly Conversations Created</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: monthlyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            )}

            {isSuperAdmin && (
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of users who made a request to the dashboard within the last 24 hours'
                            }
                        >
                            <PanelTitle>Daily Dashboard Users</PanelTitle>
                            <PanelContent>
                                <DashboardDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: dailyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </DashboardDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={'Number of users who made a request to the dashboard within the last 7 days'}
                        >
                            <PanelTitle>Weekly Dashboard Users</PanelTitle>
                            <PanelContent>
                                <DashboardDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: weeklyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </DashboardDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={'Number of users who made a request to the dashboard within the last 30 days'}
                        >
                            <PanelTitle>Monthly Dashboard Users</PanelTitle>
                            <PanelContent>
                                <DashboardDataProvider
                                    crossSection
                                    options={{
                                        ...options,
                                        from: monthlyFromString
                                    }}
                                    forcedUpdateIntervalMilliseconds={null}
                                    throttleMilliseconds={5000}
                                >
                                    <MetricContainer />
                                </DashboardDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
};

DashboardFixedTimeMetrics.defaultProps = {
    updateInterval: 60000
};

function mapStateToProps(state: StoreState) {
    return {
        permissions: getPermissions(state)
    };
}

export default connect(mapStateToProps)(DashboardFixedTimeMetrics);
