import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Based on https://stackoverflow.com/a/54343182
const ScrollToTop: React.FC = () => {
    const history = useHistory();
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return null;
};

export default ScrollToTop;
