import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Auth from './components/Auth';
import CompaniesDataProvider from './components/DataProviders/CompaniesDataProvider';
import AuthCallback from './pages/AuthCallback';
import Cohorts from './pages/Cohorts';
import MessageStreamProvider from './components/DataProviders/MessageStreamProvider';
import Feed from './pages/Feed';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import DashboardViewer from './permissions/DashboardViewer';
import Dashboard from './pages/Dashboard';
import Layout from './components/Layout';

const AppRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Navbar />
            <Switch>
                <Route path="/signout-callback-oidc">
                    <AuthCallback isLogin={false} />
                </Route>

                <Route path="/signin-oidc">
                    <AuthCallback isLogin />
                </Route>

                <Auth permissions={[new DashboardViewer()]}>
                    <Route exact path="/cohorts">
                        <Auth permissions={[new DashboardViewer(true)]} silentRedirect>
                            <MessageStreamProvider>
                                <CompaniesDataProvider>
                                    <Layout suffix="Cohorts">
                                        <Cohorts />
                                    </Layout>
                                </CompaniesDataProvider>
                            </MessageStreamProvider>
                        </Auth>
                    </Route>

                    <Route exact path="/feed">
                        <Auth permissions={[new DashboardViewer(true)]} silentRedirect>
                            <MessageStreamProvider>
                                <CompaniesDataProvider>
                                    <Feed />
                                </CompaniesDataProvider>
                            </MessageStreamProvider>
                        </Auth>
                    </Route>

                    <Route exact path="/">
                        <MessageStreamProvider>
                            <CompaniesDataProvider>
                                <Dashboard />
                            </CompaniesDataProvider>
                        </MessageStreamProvider>
                    </Route>

                    <Redirect to="/" />
                </Auth>

                <Redirect to="/" />
            </Switch>
        </BrowserRouter>
    );
};

export default AppRouter;
