import React from 'react';
import { InviteMetric, TimeInterval, UsersJoinedMetric } from '../../types/AnalyticsApi';
import { CommonDataProviderOptions } from '../../types/DataProvider';
import InvitesDataProvider from '../DataProviders/InvitesDataProvider';
import UsersDataProvider from '../DataProviders/UsersDataProvider';
import LineChartContainer from '../LineChart/LineChartContainer';
import ChartSectionContainer from '../Section/ChartSectionContainer';
import SectionChart from '../Section/SectionChart';
import SectionHeader from '../Section/SectionHeader';
import DashboardInvitesStatsChart from './DashboardInvitesStatsChart';
import DashboardNewUserStatsChart from './DashboardNewUserStatsChart';
import DashboardRollingInvitesChart from './DashboardThreeSeriesInvitesChart';

interface DashboardInvitesChartsProps {
    countryCode: string[] | null;
    from: string | null;
    to: string | null;
    interval: TimeInterval;
    companyId: string[] | null;
    departmentId: string[] | null;
}

const DashboardInvitesCharts: React.FC<DashboardInvitesChartsProps> = (props) => {
    let { children, ...opts } = props;
    let options: CommonDataProviderOptions = opts;

    return (
        <React.Fragment>
            <DashboardRollingInvitesChart {...options} />
            <DashboardInvitesStatsChart {...options} />
            <DashboardNewUserStatsChart {...options} />

            <ChartSectionContainer>
                <SectionHeader>Average Number Of Invites Sent Per Inviter</SectionHeader>
                <SectionChart>
                    <InvitesDataProvider
                        options={{
                            ...props,
                            metric: InviteMetric.AverageNumberOfInvitesSentPerInviter
                        }}
                    >
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            colors={['#5cefff']}
                        />
                    </InvitesDataProvider>
                </SectionChart>
            </ChartSectionContainer>

            <ChartSectionContainer>
                <SectionHeader>Percentage Of Invites That Resulted In A User Onboarding Within 7 Days</SectionHeader>
                <SectionChart>
                    <InvitesDataProvider
                        options={{
                            ...props,
                            inviteeAcceptedInviteWithinSeconds: 86400 * 7, // 7 days
                            metric: InviteMetric.PercentageOnboarded
                        }}
                    >
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            colors={['#5cefff']}
                        />
                    </InvitesDataProvider>
                </SectionChart>
            </ChartSectionContainer>

            <ChartSectionContainer>
                <SectionHeader>Percentage Of New Users Who Onboarded Via An Invite Within 7 Days</SectionHeader>
                <SectionChart>
                    <UsersDataProvider
                        options={{
                            ...props,
                            acceptedInviteWithinSeconds: 86400 * 7, // 7 days
                            metric: UsersJoinedMetric.PercentageInvited
                        }}
                    >
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            colors={['#5cefff']}
                            decimalFractionAsPercentage
                        />
                    </UsersDataProvider>
                </SectionChart>
            </ChartSectionContainer>

            <ChartSectionContainer>
                <SectionHeader>
                    Percentage Of New Users Who Onboarded And Joined A Workplace Within 24 Hours
                </SectionHeader>
                <SectionChart>
                    <UsersDataProvider
                        options={{
                            ...props,
                            metric: UsersJoinedMetric.PercentageJoinedWorkplace,
                            timeSinceUserJoinedSeconds: 86400 // 24 hours
                        }}
                    >
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            colors={['#5cefff']}
                            decimalFractionAsPercentage
                        />
                    </UsersDataProvider>
                </SectionChart>
            </ChartSectionContainer>
        </React.Fragment>
    );
};

export default DashboardInvitesCharts;
