import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff'
        },
        sectionDescription: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff',
            marginBottom: '16px'
        },
        sectionChartContainer: {
            height: '384px',
            marginBottom: '20px'
        },
        chartSectionContainer: {
            '& $sectionHeader': {
                marginBottom: '16px'
            },
            '& $sectionDescription': {
                marginBottom: '16px'
            }
        }
    })
);

export default useStyles;
