import { applyMiddleware, createStore } from 'redux';
import { loadUser } from 'redux-oidc';
import thunk from 'redux-thunk';
import userManager from '../utils/userManager';
import { rootReducer } from './reducers/rootReducer';

const initialState = {};

export const configureStore = (state = initialState) => {
    return createStore(rootReducer, state, applyMiddleware(thunk));
};

const store = configureStore();

loadUser(store, userManager);

export default store;
