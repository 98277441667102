import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EventStream from '../../notification/EventStream';
import { getFilterOptions } from '../../redux/reducers/filtersReducer';
import { getMessageStream } from '../../redux/reducers/messageStreamReducer';
import {
    DEBUG,
    EventDTO,
    EventString,
    EVENT_STRINGS,
    GET_FEED_ENDPOINT,
    IdentityVerificationStatus,
    MessageType,
    PRODUCTION_TEST_COMPANY_IDS,
    ProfessionVerificationStatus,
    USER_OFFLINE,
    USER_ONLINE,
    WorkplaceVerificationStatus
} from '../../types/AnalyticsApi';
import { CommonDataProviderOptions } from '../../types/DataProvider';
import { ENVIRONMENT } from '../../utils/constants';
import removeNullValues from '../../utils/removeNullValues';
import { createUrl } from '../../utils/urls';
import Center from '../Center';
import CenteredProgress from '../CenteredProgress';
import Feed, { eventHandlers } from './Feed';
import { bisector } from 'd3-array';
import { Box } from '@material-ui/core';

export interface FeedContainerProps {
    messageStream: EventStream | null;
    bufferSize?: number;
    className?: string;
    user: any;
    eventStrings?: EventString[];
    filterFunc?: (evt: any) => boolean;
    companyId: string[] | null;
    paginated?: boolean;
    type?: MessageType[];
    mimeType?: string[];
    countryCode?: string[] | null;
    globalFilters: CommonDataProviderOptions | null;
}

interface FeedContainerState {
    data: EventDTO[] | null;
    isLoading: boolean;
    error: boolean;
    afterKey: string | null;
    page: number;
    pagedBufferSize: number;
    isFirstLoad: boolean;
    isLastPage: boolean;
}

const DEFAULT_EVENT_STRING_BLACKLIST: EventString[] = [USER_ONLINE, USER_OFFLINE, DEBUG];
const DEFAULT_EVENT_STRING_WHITELIST: EventString[] = EVENT_STRINGS.filter(
    (value) => !DEFAULT_EVENT_STRING_BLACKLIST.includes(value)
);

/**
 * Returns true if the given event contains only test companies as it's source, and false otherwise
 */
const isTestCompanyEvent = (evt: EventDTO) => {
    if ('workplaces' in evt && evt.workplaces) {
        return (
            evt.workplaces!.length > 0 &&
            evt!.workplaces.every((w) => PRODUCTION_TEST_COMPANY_IDS.includes(w.companyId))
        );
    }

    if ('companyId' in evt && evt.companyId) {
        return PRODUCTION_TEST_COMPANY_IDS.includes(evt.companyId);
    }

    // If this event has no workplaces or company id field
    if ('workplaces' in evt && evt.workplaces === null) {
        return false;
    }

    return true;
};

const feedBisector = bisector((a: EventDTO, b: EventDTO) => b.timestamp.getTime() - a.timestamp.getTime()).left;

const isEventFromCompany = (companyIds: string[], event: EventDTO): boolean => {
    if ('workplaces' in event && event.workplaces) {
        return (
            event.workplaces.length > 0 && event.workplaces.every((w) => companyIds.includes(w.companyId.toUpperCase()))
        );
    }

    if ('companyId' in event && event.companyId) {
        return companyIds.includes(event.companyId.toUpperCase());
    }

    return false;
};

const FeedContainer: React.FC<FeedContainerProps> = ({
    messageStream,
    bufferSize,
    className,
    user,
    eventStrings,
    filterFunc,
    companyId,
    paginated,
    type,
    mimeType,
    countryCode,
    globalFilters
}) => {
    const [state, setState] = useState<FeedContainerState>({
        data: null,
        error: false,
        afterKey: null,
        isLoading: true,
        page: 0,
        pagedBufferSize: bufferSize!,
        isFirstLoad: true,
        isLastPage: false
    });

    const handleLoadMore = () => {
        // Ignore any additional interactions until we've finished loading
        if (state.isLoading) return;

        // Do not mutate the previous state with a pre or post increment on prevState.page
        setState((prevState: FeedContainerState) => ({
            ...prevState,
            page: prevState.page + 1,
            pagedBufferSize: prevState.pagedBufferSize + bufferSize!,
            isLoading: true
        }));
    };

    const handleFeedUpdate = useCallback(
        (event: EventDTO) => {
            let eventString: EventString = event.eventString;

            if (ENVIRONMENT.isLocal || ENVIRONMENT.isStaging) {
                console.log('feed', event);
            }

            // Only update when one of the events we want to handle receives an update
            // and we are on the first page of events
            if (!(eventString in eventHandlers)) return;
            if (DEFAULT_EVENT_STRING_BLACKLIST.includes(eventString)) return;
            if ((eventStrings?.length && !eventStrings.includes(eventString))) return;
            if ((filterFunc && !filterFunc(event))) return;
            if (isTestCompanyEvent(event)) return;
            if ((countryCode?.length && event.countryCode && !countryCode.includes(event.countryCode))) return;
            if ((companyId?.length && !isEventFromCompany(companyId, event))) return;

            setState((prevState: FeedContainerState) => {
                event.timestamp = new Date(event.timestamp);

                if (prevState.isLoading) {
                    return prevState;
                }

                if (prevState.data === null) {
                    return {
                        ...prevState,
                        data: [event],
                        error: false,
                        afterKey: null,
                        isLoading: false
                    };
                }

                // Add this event to the feed's data, sort that data, and then trim the feed so there are no more than bufferSize items
                let idx = feedBisector(prevState.data, event);
                let newData = [...prevState.data.slice(0, idx), event, ...prevState.data.slice(idx)];

                // Remove the very last element if we have more than bufferSize elements
                if (newData.length > state.pagedBufferSize) {
                    newData = newData.slice(0, -1);
                }

                return {
                    ...prevState,
                    data: newData,
                    error: false,
                    isLoading: false
                };
            });
        },
        [state.pagedBufferSize, eventStrings, filterFunc, countryCode, companyId]
    );

    // For manual testing/simulating activity
    // useEffect(() => {
    //     let count = 0;
    //     let timeout = setInterval(() => {
    //         let event: any = {
    //             ConversationCreatedOnUtc: new Date().toISOString(),
    //             ConversationRole: "Administrator",
    //             ConversationType: "Chat",
    //             CountryCode: "GB",
    //             CreatedOnUtc: "new Date().toISOString()",
    //             EventId: (count++).toString(),
    //             EventString: "MESSAGE_SENT",
    //             IdentityVerificationStatus: 0,
    //             IsTestUser: false,
    //             ProfessionVerificationStatus: -100,
    //             Timestamp: new Date().toISOString(),
    //             WorkplaceVerificationStatus: 2,
    //             Workplaces: [
    //                 {
    //                     CompanyId: "debug",
    //                     DepartmentId: null
    //                 }
    //             ],
    //         }

    //         handleFeedUpdate(event);
    //     }, 5000);
    //     return () => {
    //         clearTimeout(timeout);
    //     }
    // }, [handleFeedUpdate])

    const loadEvents = useCallback(
        (abortController: AbortController) => {
            // Set loading state and clear data for first page
            if (state.page === 0) {
                setState((prevState: FeedContainerState) => ({
                    ...prevState,
                    data: null,
                    error: false,
                    afterKey: null,
                    isLoading: true
                }));
            }

            let queryParams: {
                companyId?: string[] | null;
                size: number;
                eventString?: EventString[];
                after?: string;
                type?: MessageType[];
                mimeType?: string[];
                countryCode?: string[] | null;
                professionVerificationStatus?: ProfessionVerificationStatus[] | null;
                identityVerificationStatus?: IdentityVerificationStatus[] | null;
                workplaceVerificationStatus?: WorkplaceVerificationStatus[] | null;
            } = {
                ...globalFilters,
                companyId: companyId,
                size: bufferSize!, // We always fetch bufferSize events, but the maximum number of events we can hold goes up for each page we request
                eventString: eventStrings && eventStrings.length > 0 ? eventStrings : DEFAULT_EVENT_STRING_WHITELIST, // Note that the name of the query param (the key) differs from the value by one character!
                type,
                mimeType,
                countryCode
            };

            queryParams = removeNullValues(queryParams);

            // Add afterKey to query params if we're loading the next page
            // We may want to construct the after key on our own based on what's in the feed at the time the user selects see more
            if (state.page !== 0) {
                // Construct afterkey from the last event in the feed
                let lastEvent = state.data![state.data!.length - 1];
                let timestamp = lastEvent.timestamp.getTime();
                let eventId = lastEvent.eventId;
                queryParams.after = JSON.stringify([timestamp, eventId]);
            }

            let url = createUrl(GET_FEED_ENDPOINT, queryParams);

            fetch(url, {
                signal: abortController.signal,
                headers: {
                    Authorization: 'Bearer ' + (user ? user.access_token : '')
                }
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    // Without the following condition we can get a memory leak if we try to set state
                    // after the fetch has been aborted due to a dismount
                    if (abortController.signal.aborted) {
                        return;
                    }

                    let resData: EventDTO[] = res.data.data.filter((evt: any) => {
                        if (filterFunc) {
                            return filterFunc(evt) && !isTestCompanyEvent(evt);
                        }
                        return !isTestCompanyEvent(evt);
                    });

                    // Parse the timestamps (in string format) into dates
                    for (let event of resData) {
                        event.timestamp = new Date(event.timestamp);
                    }

                    // The response may not be in order
                    resData.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

                    setState((prevState: FeedContainerState) => ({
                        ...prevState,
                        data: state.page === 0 ? resData : [...prevState.data!, ...resData],
                        afterKey: JSON.stringify(res.data.afterKey),
                        isLoading: false,
                        isFirstLoad: false,
                        isLastPage: res.data.data.length < queryParams.size
                    }));
                })
                .catch((e) => {
                    if (e.code === 20) {
                        // Abort error
                        return;
                    }

                    console.error(e);

                    setState((prevState: FeedContainerState) => ({
                        ...prevState,
                        data: null,
                        error: true,
                        afterKey: null,
                        isLoading: false,
                        isFirstLoad: false
                    }));
                });
        },
        // We don't want to trigger when state.data changes as this method updates state.data
        // eslint-disable-next-line
        [state.pagedBufferSize, companyId, eventStrings, filterFunc, user, state.page, countryCode, globalFilters]
    );

    // Fetch data on initial mount and update when new data is available
    useEffect(() => {
        const abortController = new AbortController();

        let removeCallback: any = null;

        if (messageStream) {
            removeCallback = messageStream.on('feed', handleFeedUpdate);
        }

        loadEvents(abortController);

        return () => {
            abortController.abort();
            if (removeCallback) removeCallback();
        };
    }, [handleFeedUpdate, loadEvents, messageStream]);

    // Reset the page and paged buffer size if the event strings or filters change
    useEffect(() => {
        setState((prevState: FeedContainerState) => ({
            ...prevState,
            page: 0,
            pagedBufferSize: bufferSize!
        }));
    }, [bufferSize, eventStrings, filterFunc, companyId]);

    if (state.error) {
        return (
            <div className={className}>
                <Center>An error occured while retrieving data.</Center>
            </div>
        );
    }

    // If it's the first load or we're loading the first page
    if (state.isFirstLoad || (state.isLoading && state.page === 0)) {
        return (
            <div className={className}>
                <CenteredProgress />
            </div>
        );
    }

    return (
        <div className={className}>
            {state.data != null && <Feed {...state} />}

            {paginated && state.isLoading && <Box height="40px"><CenteredProgress /></Box>}

            {paginated && !state.isLoading && state.data && state.data.length > 0 && (
                <Button fullWidth onClick={handleLoadMore} disabled={state.isLoading || state.isLastPage}>
                    More
                </Button>
            )}

            {state.data && state.data.length === 0 && <Center>No data available</Center>}
        </div>
    );
};

FeedContainer.defaultProps = {
    bufferSize: 100
};

const mapStateToProps = (state: any) => ({
    messageStream: getMessageStream(state),
    user: state.oidc.user,
    globalFilters: getFilterOptions(state)
});

export default connect(mapStateToProps)(FeedContainer);
