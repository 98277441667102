import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import TimeRangeSelect from '../components/Controls/TimeRangeSelect';
import CohortsDataProvider from '../components/DataProviders/CohortsDataProvider';
import HeatMapContainer from '../components/HeatMap/HeatMapContainer';
import { TimeInterval } from '../types/AnalyticsApi';

interface ICohortsState {
    from: string | null;
    to: string | null;
    interval: TimeInterval;
}

const Cohorts: React.FC = () => {
    const [state, setState] = useState<ICohortsState>({
        from: null,
        to: null,
        interval: 'd'
    });

    const handleTimeRangeChange = (from: Date | null, to: Date | null, interval: TimeInterval) => {
        setState((prevState) => ({
            ...prevState,
            from: from?.toISOString() || null,
            to: to?.toISOString() || null,
            interval
        }));
    };

    return (
        <Grid container direction="column">
            <Grid item>
                <TimeRangeSelect onChange={handleTimeRangeChange} />
            </Grid>
            <Grid item>
                <CohortsDataProvider options={state}>
                    <HeatMapContainer />
                </CohortsDataProvider>
            </Grid>
        </Grid>
    );
};

export default Cohorts;
