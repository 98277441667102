import { HubConnectionBuilder, IRetryPolicy, RetryContext } from '@microsoft/signalr';
import EventStream from './EventStream';

class ExponentialBackoffRetryPolicy implements IRetryPolicy {
    maxDelayMilliseconds: number = 60000;

    constructor(maxDelayMilliseconds: number = 60000) {
        this.maxDelayMilliseconds = maxDelayMilliseconds;
    }

    nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
        return Math.max(this.maxDelayMilliseconds, 2 ** retryContext.previousRetryCount * 1000);
    }
}

/**
 * Used to setup a SignalR client that forwards hub invocations of the given method names
 * to the corresponding EventStream methods of the same name
 */
export class SignalREventStreamBuilder {
    signalRBaseUrl: string;
    methodNames: string[];

    /**
     * Construct a SignalR client that forwards hub invocations of the given method names
     * to the corresponding EventStream methods of the same name
     */
    constructor(signalRBaseUrl: string, methodNames: string[]) {
        this.signalRBaseUrl = signalRBaseUrl;
        this.methodNames = methodNames;
    }

    /**
     * Creates an EventStream from the configuration options specified in this builder.
     *
     * **Note: the SignalRClient connects asynchonously in the background
     */
    build = (token: string) => {
        const eventStream = new EventStream();

        const connection = new HubConnectionBuilder()
            .withUrl(this.signalRBaseUrl, {
                accessTokenFactory: () => token
            })
            .withAutomaticReconnect(new ExponentialBackoffRetryPolicy())
            .build();

        for (let methodName of this.methodNames) {
            // console.log('Registered SignalR method for:', methodName);
            connection.on(methodName, (data: any) => {
                eventStream.send(methodName, JSON.parse(data));
            });
        }

        connection.onreconnecting((error) => {
            console.log('SignalR reconnecting...');
        });

        connection.onreconnected((connectionId) => {
            console.log('SignalR connection re-established.');
        });

        connection.start().catch((error: any) => {
            // Notify user, try to connect in background, etc
            console.error('SignalR connection failed to start');
        });

        return eventStream;
    };
}
