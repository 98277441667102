import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface PanelTitleProps {
    children: React.ReactText;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panelTitle: {
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#8f8e94',
            marginBottom: '3px'
        }
    })
);

const PanelTitle: React.FC<PanelTitleProps> = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.panelTitle}>{children}</div>;
};

export default PanelTitle;
