export interface Country {
    code: string | null;
    displayName: string;
}

export interface CountryState {
    selectedCountries: Country[] | null;
}

export const COUNTRY_SELECTION = 'COUNTRY_SELECTION';

export interface SelectCountryAction {
    type: typeof COUNTRY_SELECTION;
    countries: Country[] | null;
}
