import { Checkbox, createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import matchSorter from 'match-sorter';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCountries } from '../../redux/actions/selectCountryAction';
import { getSelectedCountries } from '../../redux/reducers/countryReducer';
import { Country } from '../../types/Countries';

export interface CountryOrRegion extends Country {
    isRegion?: boolean;
    regionCode?: string;
}

const COUNTRIES: CountryOrRegion[] = [
    {
        isRegion: true,
        code: 'NA',
        displayName: 'North America',
        regionCode: 'NA'
    },
    {
        isRegion: true,
        code: 'ANZ',
        displayName: 'Australia & New Zealand',
        regionCode: 'ANZ'
    },
    {
        isRegion: true,
        code: 'EUR',
        displayName: 'Europe',
        regionCode: 'EUR'
    },
    {
        isRegion: true,
        code: 'AFR',
        displayName: 'Africa',
        regionCode: 'AFR'
    },
    {
        isRegion: true,
        code: 'AS',
        displayName: 'Asia',
        regionCode: 'AS'
    },
    {
        regionCode: 'NA',
        code: 'US',
        displayName: 'US - United States of America'
    },
    {
        regionCode: 'ANZ',
        code: 'NZ',
        displayName: 'NZ - New Zealand'
    },
    {
        regionCode: 'ANZ',
        code: 'AU',
        displayName: 'AU - Australia'
    },
    {
        regionCode: 'EUR',
        code: 'GB',
        displayName: 'GB - United Kingdom'
    },
    {
        displayName: 'IE - Ireland',
        code: 'IE',
        regionCode: 'EUR'
    },
    {
        displayName: 'CH - Switzerland',
        code: 'CH',
        regionCode: 'EUR'
    },
    {
        displayName: 'ZA - South Africa',
        code: 'ZA',
        regionCode: 'AFR'
    },
    {
        displayName: 'ZM - Zambia',
        code: 'ZM',
        regionCode: 'AFR'
    },
    {
        displayName: 'ZW - Zimbabwe',
        code: 'ZW',
        regionCode: 'AFR'
    },
    {
        displayName: 'SN - Senegal',
        code: 'SN',
        regionCode: 'AFR'
    },
    {
        displayName: 'NG - Nigeria',
        code: 'NG',
        regionCode: 'AFR'
    },
    {
        displayName: 'TZ - Tanzania',
        code: 'TZ',
        regionCode: 'AFR'
    },
    {
        displayName: 'KE - Kenya',
        code: 'KE',
        regionCode: 'AFR'
    },
    {
        displayName: 'PK - Pakistan',
        code: 'PK',
        regionCode: 'AS'
    },
];

const REGION_COUNTRY_CODES: {
    [x: string]: string[];
} = {};

const REGION_CONTRIES: {
    [x: string]: Country[];
} = {};

for (let item of COUNTRIES) {
    if (item.isRegion) {
        continue;
    }

    if (item.regionCode! in REGION_COUNTRY_CODES) {
        REGION_COUNTRY_CODES[item.regionCode!].push(item.code!);
    } else {
        REGION_COUNTRY_CODES[item.regionCode!] = [item.code!];
    }

    if (item.regionCode! in REGION_CONTRIES) {
        REGION_CONTRIES[item.regionCode!].push(item);
    } else {
        REGION_CONTRIES[item.regionCode!] = [item];
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputRoot: {
            height: '38px',
            width: '239px',
            padding: '0 0 0 6px !important'
        },
        popper: {
            width: '300px !important'
        }
    })
);

const filterOptions = (options: Country[], { inputValue }: { inputValue: string }) => {
    return matchSorter(options, inputValue, {
        keys: ['displayName', 'code']
    });
};

interface CountrySelectProps {
    className?: any;
    countries: Country[] | null;
    selectCountries: (countries: Country[] | null) => void;
}

interface CountrySelectState {
    countries: Country[] | null;
}

const CountrySelect: React.FC<CountrySelectProps> = ({ className, countries, selectCountries }) => {
    const [state, setState] = useState<CountrySelectState>({
        countries: []
    });
    const classes = useStyles();

    let countryCodes = state.countries?.map((country) => country.code) ?? [];

    useEffect(() => {
        setState({
            countries: countries ?? []
        });
    }, [countries]);

    const handleChange = (event: ChangeEvent<{}>, values: CountryOrRegion[] | null) => {
        if (!values || values.length === 0) {
            selectCountries(null);
            return;
        }

        // There will only ever be one region in our values as regions are not persisted in our state but are instead
        // only used as controls
        let region = values.filter((value) => value.isRegion)[0];
        if (region) {
            handleRegionClick(region);
            return;
        }

        let countries = values.filter((value) => !value.isRegion);
        selectCountries(countries);
    };

    const renderTags = (countries: Country[], getTagProps: Function) => {
        return (
            <span
                style={{
                    paddingLeft: '4px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '160px',
                    whiteSpace: 'nowrap'
                }}
            >
                {countries.length === 1
                    ? countries[0].displayName
                    : countries.map((country) => country.code).join(', ')}
            </span>
        );
    };

    const handleRegionClick = (option: CountryOrRegion) => {
        let isCompleteSelection = REGION_COUNTRY_CODES[option.code!].every((code) => countryCodes.includes(code));
        let filtered =
            state.countries?.filter((country) => !REGION_COUNTRY_CODES[option.code!].includes(country.code!)) ?? [];

        // If this region is fully selected, unselect everything in this region, otherwise select everything in this region
        if (!isCompleteSelection) {
            filtered = [...filtered, ...REGION_CONTRIES[option.code!]];
        }

        selectCountries(filtered);
    };

    return (
        <React.Fragment>
            <Autocomplete
                multiple
                disableCloseOnSelect
                groupBy={(options: CountryOrRegion) => (options.isRegion ? 'Region' : 'Country')}
                className={className || null}
                options={COUNTRIES}
                getOptionLabel={(option: Country) => option.displayName}
                onChange={handleChange}
                autoSelect={false}
                value={state.countries ?? undefined}
                filterOptions={filterOptions}
                classes={{
                    inputRoot: classes.inputRoot,
                    popper: classes.popper
                }}
                renderTags={renderTags}
                renderOption={(option, { selected }) => {
                    let isChecked = option.isRegion
                        ? REGION_COUNTRY_CODES[option.code!].every((code) => countryCodes.includes(code))
                        : selected;
                    let isIndeterminate =
                        !isChecked &&
                        option.isRegion &&
                        REGION_COUNTRY_CODES[option.code!].some((code) => countryCodes.includes(code));
                    return (
                        <React.Fragment>
                            <Checkbox
                                checked={isChecked}
                                indeterminate={isIndeterminate}
                                color="default"
                                style={{
                                    marginRight: '4px',
                                    marginLeft: '-10px',
                                    padding: '4px'
                                }}
                            />
                            {option.displayName}
                        </React.Fragment>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={'Country'}
                        placeholder={state.countries && state.countries.length > 0 ? undefined : 'All'}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                )}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    countries: getSelectedCountries(state)
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            selectCountries: selectCountries
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);
