import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import './App.css';
import AppRouter from './AppRouter';

// const lightTheme = createMuiTheme();

const darkTheme = createMuiTheme({
    palette: {
        background: {
            default: '#000'
        },
        type: 'dark'
    }
});

const App: React.FC = () => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline>
                    <AppRouter />
                </CssBaseline>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
