import { Button } from '@material-ui/core';
import jwt from 'jsonwebtoken';
import Oidc from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { UserState } from 'redux-oidc';
import Permission from '../permissions/Permission';
import { AccessToken } from '../types/Permissions';
import { StoreState } from '../types/Store';
import { ENVIRONMENT } from '../utils/constants';
import userManager from '../utils/userManager';
import Center from './Center';
import CenteredProgress from './CenteredProgress';

if (ENVIRONMENT.isLocal || ENVIRONMENT.isStaging) {
    Oidc.Log.logger = console;
    Oidc.Log.level = Oidc.Log.DEBUG;
}

const signin = (state: any) =>
    userManager.signinRedirect({
        prompt: 'login',
        state
    });

export const signOut = async (id_token_hint: string, state: any) => {
    setSignoutFlag();
    await userManager.clearStaleState();
    await userManager.signoutRedirect({ state, id_token_hint });
};

/** Used to prevent an automatic redirect to the signin flow while a signout is in progress. */
const SIGNOUT_FLAG = 'isSigningOut';
export const setSignoutFlag = () => localStorage.setItem(SIGNOUT_FLAG, 'true');
export const clearSignOutFlag = () => localStorage.removeItem(SIGNOUT_FLAG);
export const isSigningOut = () => localStorage.getItem(SIGNOUT_FLAG);

interface AuthProps {
    children: any;
    permissions?: Permission[];
    silentRedirect?: boolean;
    oidc: UserState;
}

interface AuthState {
    isLoading: boolean;
    isAuthorized: boolean;
}

const Auth: React.FC<AuthProps> = ({ children, permissions, silentRedirect, oidc }) => {
    const [state, setState] = useState<AuthState>({
        isLoading: true,
        isAuthorized: false
    });
    const location = useLocation();
    const { user, isLoadingUser } = oidc;

    useEffect(() => {
        if (isSigningOut() || isLoadingUser) return;

        // Check roles
        if (!user || user.expired) {
            signin({ location });
        } else if (permissions) {
            const jwtPayload = jwt.decode(user.access_token, { json: true });
            const token: AccessToken = { role: jwtPayload?.role };
            const hasRoles = Boolean(token?.role);

            for (let permission of permissions) {
                if (!hasRoles || !permission.testAndApplyRestrictions(token)) {
                    setState((prevState: AuthState) => ({
                        ...prevState,
                        isLoading: false,
                        isAuthorized: false
                    }));
                    return;
                }
            }

            setState((prevState: AuthState) => ({
                ...prevState,
                isLoading: false,
                isAuthorized: true
            }));
        }
    }, [user, permissions, location, isLoadingUser]);

    const handleLogout = () => {
        if (!user) return;
        signOut(user.id_token, { location });
    };

    if (state.isLoading || !user || user.expired) {
        return <CenteredProgress />;
    }

    if (!state.isAuthorized && silentRedirect) {
        return <Redirect to="/" />;
    }

    if (!state.isAuthorized) {
        return (
            <Center>
                <div style={{ marginBottom: '16px' }}>Sorry, you don't have permission to view this page.</div>
                <Button variant="outlined" onClick={handleLogout}>
                    Logout
                </Button>
            </Center>
        );
    }

    return children;
};

function mapStateToProps(state: StoreState) {
    return {
        oidc: state.oidc
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
