const queryString = require('query-string');

export const createUrl = (src: string, queryParams?: {}) => {
    let { url, query } = queryString.parseUrl(src);
    return (
        url +
        '?' +
        queryString.stringify({
            ...queryParams,
            ...query
        })
    );
};
