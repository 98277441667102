import { setCompanyPermissions, setSuperAdminStatus } from '../redux/actions/permissionsActions';
import store from '../redux/store';
import { AccessToken } from '../types/Permissions';
import Permission from './Permission';

const DASHBOARD_VIEWER = 'DashboardViewer';

class DashboardViewer extends Permission {
    private isSuperAdminOnly: boolean;

    constructor(isSuperAdminOnly: boolean = false) {
        super();
        this.isSuperAdminOnly = isSuperAdminOnly;
    }

    private isMatch = (role: string): boolean => {
        if (role === DASHBOARD_VIEWER) return true;

        if (role.startsWith(DASHBOARD_VIEWER + '|CID|') && !this.isSuperAdminOnly) {
            return role.split('|')[2].length > 0;
        }

        return false;
    };

    private getRoles = (token: AccessToken): string[] => {
        if (!token.role) {
            return [];
        }
        let roles = token.role instanceof Array ? token.role : [token.role];
        return roles.filter(this.isMatch);
    };

    test = (token: AccessToken) => {
        if (!token.role) {
            return false;
        }
        let roles = this.getRoles(token);
        return roles.length > 0;
    };

    applyRestrictions = (token: AccessToken) => {
        let roles = this.getRoles(token);

        let isSuperAdmin: boolean = false;
        let authorizedCompanyIds: string[] = [];

        if (roles.length === 1 && roles[0] === DASHBOARD_VIEWER) {
            isSuperAdmin = true;
        } else if (roles.length > 0) {
            authorizedCompanyIds = roles
                .filter((role) => role.startsWith(DASHBOARD_VIEWER + '|CID|'))
                .map((role) => role.split('|')[2]);
        }

        store.dispatch(setSuperAdminStatus(isSuperAdmin));
        store.dispatch(setCompanyPermissions(authorizedCompanyIds));
    };
}

export default DashboardViewer;
