import { DOCUMENT_MIMETYPES, IMAGE_MIMETYPES, MessagesSentMetric, MessageType } from '../../../types/AnalyticsApi';
import Chart from '../Chart';
import { ChartOptions } from '../Charts/ChartBase';
import DailyActiveUsersChart from '../Charts/DailyActiveUsersChart';
import FilesCreatedChart from '../Charts/FilesCreatedChart';
import MessagesSentChart from '../Charts/MessagesSentChart';
import UserChart from '../Charts/UsersChart';
import Page from '../Page';
import Report from '../Report';
import FilteredReportBuilder from './FilteredReportBuilder';

/**
 * Used to build a report with the following charts:
 *
 * - Total users (Cumulative sum)
 * - Messages exchanged
 * - Photos exchanged
 * - Photos captured
 * - Documents exchanged
 * - Documents created
 * - Active Users (Rolling)
 * - DAU/MAU
 * - WAU/MAU
 * - Messages exchanged per company/department
 * - Photos captured per company/department
 * - Photos exchanged per company/department
 * - Documents created per company/department
 * - Documents exchanged per company/department
 */
class CustomerReportBuilder extends FilteredReportBuilder {
    private report: Report = new Report();

    constructor(filters: ChartOptions) {
        super(filters);
        this.report.setHeading(this.heading).setDescription(this.generateDescription());
    }

    public build(): Report {
        let pages: Chart[][] = [
            [
                new UserChart(this.filters, {}).setTitle('Total Users'),
                new DailyActiveUsersChart(this.filters, {})
            ],
            [
                new MessagesSentChart(this.filters, {
                    type: [MessageType.NULL],
                    metric: MessagesSentMetric.SentTo
                }).setTitle('Messages Exchanged'),
                new FilesCreatedChart(this.filters, {
                    mimeType: IMAGE_MIMETYPES
                }).setTitle('Photos Captured'),
                new MessagesSentChart(this.filters, {
                    type: [MessageType.Photo],
                    metric: MessagesSentMetric.SentTo
                }).setTitle('Photos Exchanged')
            ],
            [
                new FilesCreatedChart(this.filters, {
                    mimeType: DOCUMENT_MIMETYPES
                }).setTitle('Documents Created'),
                new MessagesSentChart(this.filters, {
                    type: [MessageType.PatientFile],
                    metric: MessagesSentMetric.SentTo
                }).setTitle('Documents Exchanged')
            ],
            [
                new MessagesSentChart(this.groupedFilters, {
                    type: [MessageType.NULL],
                    metric: MessagesSentMetric.SentTo
                }).setTitle('Messages Exchanged' + this.groupingSuffix),
                new FilesCreatedChart(this.groupedFilters, {
                    mimeType: IMAGE_MIMETYPES
                }).setTitle('Photos Captured' + this.groupingSuffix),
                new MessagesSentChart(this.groupedFilters, {
                    type: [MessageType.Photo],
                    metric: MessagesSentMetric.SentTo
                }).setTitle('Photos Exchanged' + this.groupingSuffix)
            ],
            [
                new FilesCreatedChart(this.groupedFilters, {
                    mimeType: DOCUMENT_MIMETYPES
                }).setTitle('Documents Created' + this.groupingSuffix),
                new MessagesSentChart(this.groupedFilters, {
                    type: [MessageType.PatientFile],
                    metric: MessagesSentMetric.SentTo
                }).setTitle('Documents Exchanged' + this.groupingSuffix)
            ]
        ];

        pages.forEach((page) => {
            let p = new Page();
            page.forEach((chart) => p.addChart(chart));
            this.report.addPage(p);
        });

        return this.report;
    }
}

export default CustomerReportBuilder;
