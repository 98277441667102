import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface PanelContentProps {
    children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panelContent: {
            fontFamily: 'Roboto',
            fontSize: '42px',
            fontWeight: 300,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff'
        }
    })
);

const PanelContent: React.FC<PanelContentProps> = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.panelContent}>{children}</div>;
};

export default PanelContent;
