import React from 'react';
import useStyles from './styles';

interface SectionDescriptionProps {
    children: React.ReactNode;
}

/**
 * Renders a section description (e.g. a description for a chart below a section header).
 */
const SectionDescription: React.FC<SectionDescriptionProps> = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.sectionDescription}>{children}</div>;
};

export default SectionDescription;
