const uuidv4 = require('uuid/v4');

/**
 * Provides a way to send messages that can trigger registered listener methods
 */
class EventStream {
    callbackDict: {
        [methodName: string]: {
            [id: string]: (message: string) => void;
        };
    };

    constructor() {
        this.callbackDict = {};
    }

    /**
     * Executes all registered methods with the given name with the given message as a paramater
     */
    send = (methodName: string, message: string) => {
        // execute all on("methodName") callbacks
        for (let callbackId in this.callbackDict[methodName]) {
            this.callbackDict[methodName][callbackId](message);
        }
    };

    /**
     * Registers a method with the given name and returns a method that can be used to remove the given method
     */
    on = (methodName: string, callback: (message: any) => void) => {
        const id = uuidv4();

        if (this.callbackDict[methodName] === undefined) {
            this.callbackDict[methodName] = {
                [id]: callback
            };
        } else {
            this.callbackDict[methodName][id] = callback;
        }

        return () => delete this.callbackDict[methodName][id];
    };
}

export default EventStream;
