import { Box } from '@material-ui/core';
import { Theme } from '@nivo/core';
import { HeatMapDatum, ResponsiveHeatMap } from '@nivo/heatmap';
import dateFormat from 'dateformat';
import React from 'react';
import { Cohort, KeyValuePair } from '../../types/Cohort';

export type onCellClickCallback = (cohort: Cohort, activity: KeyValuePair<Date, number>) => void;

export interface HeatMapProps {
    data: Cohort[];
    onCellClick: onCellClickCallback;
}

const getCohortName = (cohort: Cohort): string => {
    return dateFormat(cohort.startDate) + ' - ' + dateFormat(cohort.stopDate);
};

const getKeys = (data: Cohort[]): string[] => {
    return data.map((value: Cohort, index: number) => index + '');
};

/**
 * Convert data into the format nivo uses
 */
const formatData = (keys: string[], data: Cohort[]): HeatMapDatum[] => {
    // The first cohort has all of the time intervals we need
    let n = data[0].activity.length;

    let datums = data.map((cohort, i) => {
        let datum: HeatMapDatum = {
            id: keys[i]
        };

        for (let j = 0; j < n; j++) {
            datum[j] = cohort.activity[j]?.value ?? -1;
        }

        // cohort.activity.forEach((activity, i) => {
        //     datum[i] = activity.value;
        // });

        return datum;
    });

    return datums;
};

// Unfortunately I couldn't find a type declaration for this in Nivo
type CellShapeProps = {
    x: number;
    y: number;
    value: any;
    color: any;
    opacity: number;
    borderWidth: any;
    borderColor: any;
    height: any;
    width: any;
    textColor: any;
    data: any;
    onHover: any;
    onLeave: any;
    onClick: any;
};

const createCustomCellGenerator = (cohortData: Cohort[], onCellClick: onCellClickCallback) => {
    const customCell = ({
        x,
        y,
        value,
        color,
        opacity,
        borderWidth,
        borderColor,
        data,
        height,
        width,
        textColor,
        onHover,
        onLeave,
        onClick
    }: CellShapeProps) => {
        if (value === -1 || typeof value !== 'number') {
            return null;
        }

        return (
            <g
                transform={`translate(${x}, ${y})`}
                onMouseEnter={onHover}
                onMouseMove={onHover}
                onMouseLeave={onLeave}
                onClick={async (event) => {
                    let cohort = cohortData[data.yKey];
                    let activity = cohort.activity[data.xKey];

                    if (activity.value) {
                        onCellClick(cohort, activity);
                    }
                }}
            >
                <rect
                    fill={color}
                    fillOpacity={opacity}
                    width={width}
                    height={height}
                    x={-width / 2}
                    y={-height / 2}
                    stroke={borderColor}
                    strokeWidth={borderWidth}
                />
                {width > 20 && (
                    <text dominantBaseline="central" textAnchor="middle" style={{ fill: textColor }}>
                        {value.toFixed()}
                    </text>
                )}
            </g>
        );
    };

    return customCell;
};

const createRenderTickGenerator = (cohortData: Cohort[]) => {
    const renderTick = (data: any) => {
        return (
            <g transform={`translate(${data.x}, ${data.y})`}>
                <line
                    x1="0"
                    x2={data.lineX}
                    y1="0"
                    y2={data.lineY}
                    style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }}
                />
                <text
                    transform={`translate(${data.textX}, ${data.textY})`}
                    dominantBaseline={data.textBaseline}
                    textAnchor={data.textAnchor}
                    style={{ fill: 'rgb(255, 255, 255)', fontFamily: 'sans-serif', fontSize: '11px' }}
                >
                    {getCohortName(cohortData[data.tickIndex])}
                </text>
            </g>
        );
    };
    return renderTick;
};

const createTooltipRenderer = (cohortData: Cohort[]) => {
    const renderTooltip = (data: any) => {
        let cohort = cohortData[data.yKey];
        // let activity = cohort.activity[data.xKey];

        return (
            <div
                style={{
                    background: 'black',
                    padding: '9px 12px',
                    borderRadius: '10px'
                }}
            >
                <div
                    style={{
                        color: 'white',
                        padding: '3px 0'
                    }}
                >
                    <strong>{getCohortName(cohort)}</strong> - {data.xKey}: {data.value}
                </div>
            </div>
        );
    };
    return renderTooltip;
};

const theme: Theme = {
    axis: {
        ticks: {
            text: {
                fill: '#ffffff'
            }
        }
    },
    dots: {
        text: {
            fill: '#ffffff'
        }
    },
    tooltip: {
        basic: {
            background: 'black',
            padding: '9px 12px',
            borderRadius: '10px'
        },
        container: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        }
    }
};

const HeatMap: React.FC<HeatMapProps> = ({ data, onCellClick }) => {
    const keys = getKeys(data);
    const formattedData = formatData(keys, data);

    return (
        <Box width="100%" height={data.length * 50 + 'px'}>
            <ResponsiveHeatMap
                theme={theme}
                data={formattedData}
                keys={data.map((_, i) => i + '')}
                margin={{ top: 30, right: 60, bottom: 60, left: 300 }}
                forceSquare={false}
                axisTop={{
                    orient: 'top',
                    tickSize: 5,
                    tickPadding: 5,
                    legend: '',
                    legendOffset: 36
                }}
                minValue={0}
                padding={2}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    renderTick: createRenderTickGenerator(data)
                }}
                animate={false}
                cellOpacity={1}
                hoverTarget="rowColumn"
                cellHoverOthersOpacity={0.75}
                cellShape={createCustomCellGenerator(data, onCellClick)}
                // @ts-ignore
                tooltip={createTooltipRenderer(data)}
                // @ts-ignore
                // tooltip={}
            />
        </Box>
    );
};

export default HeatMap;
