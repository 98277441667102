import React from 'react';
import {
    ConversationMetric,
    EventString,
    GET_CONVERSATIONS_ENDPOINT,
    GET_TOTAL_CONVERSATIONS_ENDPOINT
} from '../../types/AnalyticsApi';
import CrossSectionalData from '../../types/CrossSectionalData';
import { ConversationsOptions, DataProviderOptions } from '../../types/DataProvider';
import TimeSeriesData from '../../types/TimeseriesData';
import DataProvider, { OptionalDataProviderProps } from './DataProvider';

interface ConversationsDataProviderProps extends OptionalDataProviderProps {
    children: any;
    options?: ConversationsOptions;
    crossSection?: boolean;
}

const ConversationsDataProvider: React.FC<ConversationsDataProviderProps> = ({
    children,
    options,
    crossSection,
    ...other
}) => {
    const crossSectionalResponseHandler = (res: any, options: DataProviderOptions): CrossSectionalData => {
        let crossSectionalData: CrossSectionalData = [];

        crossSectionalData.push({
            key: 'all',
            value: res.data
        });

        return crossSectionalData;
    };

    const timeSeriesResponseHandler = (res: any, options: DataProviderOptions): TimeSeriesData => {
        console.log('timeSeriesResponseHandlerRes', res);
        console.log('timeSeriesResponseHandlerOptions', options);
        return [];
    };

    let updateOnEventStrings: EventString[] = [];
    if (options?.metric === ConversationMetric.InvitesAccepted) {
        updateOnEventStrings = ['CONVERSATION_INVITE_ACCEPTED'];
    } else {
        updateOnEventStrings = ['CONVERSATION_CREATED'];
    }

    return (
        <DataProvider
            dataFormat={crossSection ? 'CROSS_SECTION' : 'TIME_SERIES'}
            src={crossSection ? GET_TOTAL_CONVERSATIONS_ENDPOINT : GET_CONVERSATIONS_ENDPOINT}
            options={options}
            responseHandler={crossSection ? crossSectionalResponseHandler : timeSeriesResponseHandler}
            updateOnEventStrings={updateOnEventStrings}
            {...other}
        >
            {children}
        </DataProvider>
    );
};

export default ConversationsDataProvider;
