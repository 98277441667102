import { getSelectedCountries } from '../redux/reducers/countryReducer';
import store from '../redux/store';

const getGlobalCountryCode = () => {
    let state = store.getState();
    let countries = getSelectedCountries(state);

    if (countries === null) {
        return null;
    }

    return countries.filter(country => country.code !== null).map((country) => country.code!);
};

export default getGlobalCountryCode;
