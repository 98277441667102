import { TimeSeriesDataPoint } from './CompositeDataProvider';

const apiBaseUrl = process.env.REACT_APP_REPORTS_API_URL;

export const POST_REPORT_ENDPOINT = `${apiBaseUrl}/app/report`;

export type ChartOptionsKey =
    | 'title'
    | 'description'
    | 'percentage'
    | 'data'
    | 'xAxisLabel'
    | 'yAxisLabel'
    | 'legends'
    | 'legendFontSize'
    | 'valueLabels'
    | 'valueLabelFontSize'
    | 'chartType'
    | 'yAxisMinValue'
    | 'yAxisMaxValue'
    | 'barWidth';

export const ChartOptionsKeyMappings: {
    [key in ChartOptionsKey]: string;
} = {
    title: 'title',
    description: 'description',
    percentage: 'percentage',
    data: 'data',
    xAxisLabel: 'x_axis_label',
    yAxisLabel: 'y_axis_label',
    legends: 'legends',
    legendFontSize: 'legend_font_size',
    valueLabels: 'value_labels',
    valueLabelFontSize: 'value_label_font_size',
    chartType: 'chart_type',
    yAxisMinValue: 'y_axis_min_value',
    yAxisMaxValue: 'y_axis_max_value',
    barWidth: 'bar_width'
};

export type PageOptionsKey = 'width' | 'height' | 'dpi' | 'charts';

export const PageOptionsKeyMappings: {
    [key in PageOptionsKey]: string;
} = {
    width: 'width',
    height: 'height',
    dpi: 'dpi',
    charts: 'charts'
};

export type ReportOptionsKey = 'pages' | 'heading' | 'description';

export const ReportOptionsKeyMappings: {
    [key in ReportOptionsKey]: string;
} = {
    pages: 'pages',
    heading: 'heading',
    description: 'description'
};

export type ChartType = 'bar' | 'line';

export interface ChartOptions {
    title?: string;
    description?: string;
    percentage?: boolean;
    data: TimeSeriesDataPoint[];
    xAxisLabel?: string;
    yAxisLabel?: string;
    legends?: boolean;
    legendFontSize?: number;
    valueLabels?: boolean;
    valueLabelFontSize?: number;
    chartType?: ChartType;
    yAxisMinValue?: number;
    yAxisMaxValue?: number;
    barWidth?: number;
}

export interface PageOptions {
    width?: number;
    height?: number;
    dpi?: number;
    charts: ChartOptions[];
}

export interface ReportOptions {
    pages: PageOptions[];
    heading?: string;
    description?: string;
}
