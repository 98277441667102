import useStyles from './styles';
import React from 'react';

interface ChartSectionContainerProps {
    children: React.ReactNode;
}

/**
 * Container for a chart section. Applies some additional styles to child
 * section elements.
 */
const ChartSectionContainer: React.FC<ChartSectionContainerProps> = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.chartSectionContainer}>{children}</div>;
};

export default ChartSectionContainer;
