import { TimeRange } from '../components/Controls/TimeRangeSelect';
import moment, { unitOfTime } from 'moment';

const unitOfTimeDayMappings: {
    [x: string]: number;
} = {
    days: 1,
    weeks: 7,
    months: 30,
    years: 365
};

const getFromAndToDate = (
    timeRange: TimeRange,
    start?: Date | null,
    stop?: Date | null,
    relativeTimeRangeValue: number = 1,
    relativeTimeRangeUnit: unitOfTime.DurationConstructor = 'd'
) => {
    let now = new Date();
    let from: Date = start || now;
    let to: Date = stop || now;

    switch (timeRange) {
        case 'Overall': {
            return {
                from: null,
                to: null
            };
        }
        case 'Today': {
            return {
                from: moment(new Date()).startOf('day').toDate(),
                to: null
            };
        }
        case 'This week': {
            return {
                from: moment(new Date()).startOf('week').toDate(),
                to: null
            };
        }
        case 'Last 24 hours': {
            // We DON'T subtract 1 hour less here as it's more intuitive to a user if the stop date is the same hour 24 hours ago
            return {
                from: moment(new Date()).subtract(24, 'hours').startOf('hour').toDate(),
                to: null
            };
        }
        case 'Last 7 days': {
            // We subtract 1 day less as we include the current day
            return {
                from: moment(new Date()).subtract(6, 'days').startOf('day').toDate(),
                to: null
            };
        }
        case 'Last 14 days': {
            // We subtract 1 day less as we include the current day
            return {
                from: moment(new Date()).subtract(13, 'days').startOf('day').toDate(),
                to: null
            };
        }
        case 'Last 30 days': {
            // We subtract 1 day less as we include the current day
            return {
                from: moment(new Date()).subtract(29, 'days').startOf('day').toDate(),
                to: null
            };
        }
        case 'Last 60 days': {
            // We subtract 1 day less as we include the current day
            return {
                from: moment(new Date()).subtract(59, 'days').startOf('day').toDate(),
                to: null
            };
        }
        case 'Last 365 days': {
            // We subtract 1 day less as we include the current day
            return {
                from: moment(new Date()).subtract(364, 'days').startOf('day').toDate(),
                to: null
            };
        }
        case 'Last...': {
            if (relativeTimeRangeUnit === 'days' && relativeTimeRangeValue === 1) {
                return {
                    from: moment(new Date()).subtract(24, 'hours').startOf('hour').toDate(),
                    to: null
                };
            }

            return {
                // We subtract 1 day less as we include the current day
                from: moment(new Date())
                    .subtract(relativeTimeRangeValue * unitOfTimeDayMappings[relativeTimeRangeUnit] - 1, 'days')
                    .startOf('day')
                    .toDate(),
                to: null
            };
        }
        case 'Day': {
            let fromMoment = moment(from);
            from = fromMoment.startOf('day').toDate();
            to = fromMoment.endOf('day').toDate();
            break;
        }
        case 'Week': {
            let fromMoment = moment(from);
            from = fromMoment.startOf('week').toDate();
            to = fromMoment.endOf('week').toDate();
            break;
        }
        case 'Month': {
            let fromMoment = moment(from);
            from = fromMoment.startOf('month').toDate();
            to = fromMoment.endOf('month').toDate();
            break;
        }
        case 'Year': {
            let fromMoment = moment(from);
            from = fromMoment.startOf('year').toDate();
            to = fromMoment.endOf('year').toDate();
            break;
        }
        case 'Custom': {
            let fromMoment = moment(from || moment(now).subtract(7, 'days').toDate());
            let toMoment = moment(to || now);
            from = fromMoment.startOf('day').toDate();
            to = toMoment.endOf('day').toDate();
        }
    }

    return {
        from,
        to
    };
};

export default getFromAndToDate;
