import { GET_FILES_ENDPOINT } from '../../../types/AnalyticsApi';
import { HandledData, Source, TimeSeriesDataPoint } from '../../../types/CompositeDataProvider';
import { FilesOptions } from '../../../types/DataProvider';
import { formatKeys } from '../lib';
import ChartBase, { ChartOptions } from './ChartBase';

class FilesCreatedChart extends ChartBase {
    private filesFilters: FilesOptions;

    constructor(filters: ChartOptions, filesFilters: FilesOptions) {
        super(filters);
        this.filesFilters = filesFilters;
        this.setTitle('Files Created');
    }

    protected getSources(): Promise<Source[]> {
        let sources: Source[] = [
            {
                id: 'filesCreated',
                src: GET_FILES_ENDPOINT,
                options: {
                    ...this.filters,
                    ...this.filesFilters
                },
                handler: (res) => res.data
            }
        ];
        return Promise.resolve(sources);
    }

    protected parseRespose(data: HandledData[]): Promise<TimeSeriesDataPoint[]> {
        formatKeys(data);
        let handledData = data[0];
        return Promise.resolve(handledData.data);
    }
}

export default FilesCreatedChart;
