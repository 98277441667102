import ChartBase, { ChartOptions } from './ChartBase';
import { HandledData, Source, TimeSeriesDataPoint } from '../../../types/CompositeDataProvider';
import { GET_MESSAGES_ENDPOINT } from '../../../types/AnalyticsApi';
import { MessagesOptions } from '../../../types/DataProvider';
import { formatKeys } from '../lib';

class MessagesSentChart extends ChartBase {
    private messageFilters: MessagesOptions;

    constructor(filters: ChartOptions, messageFilters: MessagesOptions) {
        super(filters);
        this.messageFilters = messageFilters;
        this.setTitle("Messages Sent")
    }

    protected getSources(): Promise<Source[]> {
        let sources: Source[] = [
            {
                id: 'messagesSent',
                src: GET_MESSAGES_ENDPOINT,
                options: {
                    ...this.filters,
                    ...this.messageFilters
                },
                handler: (res) => res.data
            }
        ];
        return Promise.resolve(sources);
    }

    protected parseRespose(data: HandledData[]): Promise<TimeSeriesDataPoint[]> {
        formatKeys(data);
        let handledData = data[0];
        return Promise.resolve(handledData.data);
    }
}

export default MessagesSentChart;
