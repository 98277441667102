import EventStream from '../../notification/EventStream';
import { SignalREventStreamBuilder } from '../../notification/SignalR';
import { NEGOTIATE_BASE_URL } from '../../types/AnalyticsApi';
import { INITIALIZE_MESSAGE_STREAM, MessageStreamActionTypes } from '../../types/MessageStream';
import { getMessageStream } from '../reducers/messageStreamReducer';

export const initializeMessageStreamAction = (messageStream: EventStream): MessageStreamActionTypes => {
    return {
        type: INITIALIZE_MESSAGE_STREAM,
        messageStream
    };
};

export const initializeMessageStream = (token: string) => {
    return (dispatch: (action: object) => void, getState: any) => {
        if (getMessageStream(getState()) !== null) {
            return;
        }

        // TBD: pass an access token or something while doing this (backend needs to be updated to handle this)
        let messageStream = new SignalREventStreamBuilder(NEGOTIATE_BASE_URL, ['update', 'feed']).build(token);
        dispatch(initializeMessageStreamAction(messageStream));
    };
};
