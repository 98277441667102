import { CommonDataProviderOptions } from '../../types/DataProvider';
import {
    FilterActionTypes,
    FiltersState,
    FILTER_FROM,
    FILTER_GROUP_BY,
    FILTER_IDENTITY_VERIFICATION_STATUS,
    FILTER_INTERVAL,
    FILTER_PROFESSION_VERIFICATION_STATUS,
    FILTER_TO,
    FILTER_WORKPLACE_VERIFICATION_STATUS
} from '../../types/Filters';
import { StoreState } from '../../types/Store';

export const initialState: FiltersState = {
    from: null,
    to: null,
    interval: null,
    groupBy: null,
    professionVerificationStatuses: null,
    identityVerificationStatuses: null,
    workplaceVerificationStatuses: null
};

const filtersReducer = (state = initialState, action: FilterActionTypes): FiltersState => {
    switch (action.type) {
        case FILTER_FROM:
            return {
                ...state,
                from: action.from
            };
        case FILTER_TO:
            return {
                ...state,
                to: action.to
            };
        case FILTER_INTERVAL:
            return {
                ...state,
                interval: action.interval
            };
        case FILTER_GROUP_BY:
            return {
                ...state,
                groupBy: action.groupBy
            };
        case FILTER_PROFESSION_VERIFICATION_STATUS:
            return {
                ...state,
                professionVerificationStatuses: action.professionVerificationStatuses
            };
        case FILTER_IDENTITY_VERIFICATION_STATUS:
            return {
                ...state,
                identityVerificationStatuses: action.identityVerificationStatuses
            };
        case FILTER_WORKPLACE_VERIFICATION_STATUS:
            return {
                ...state,
                workplaceVerificationStatuses: action.workplaceVerificationStatuses
            };
        default:
            return state;
    }
};

export default filtersReducer;

export const getFilters = (state: StoreState) => state.filters;

export const getFilterOptions = (state: StoreState): CommonDataProviderOptions => {
    let {
        professionVerificationStatuses,
        identityVerificationStatuses,
        workplaceVerificationStatuses,
        ...options
    } = getFilters(state);

    let filterOptions: CommonDataProviderOptions = {
        ...options,
        professionVerificationStatus: professionVerificationStatuses,
        identityVerificationStatus: identityVerificationStatuses,
        workplaceVerificationStatus: workplaceVerificationStatuses
    };

    return filterOptions;
};

const getProfessionVerificationStatuses = (state: StoreState) => state.filters.professionVerificationStatuses;
const getIdentityVerificationStatuses = (state: StoreState) => state.filters.identityVerificationStatuses;
const getWorkplaceVerificationStatuses = (state: StoreState) => state.filters.workplaceVerificationStatuses;

const ReturnsSingleValue = <TOut>(func: (state: StoreState) => TOut[] | null) => {
    return (state: StoreState) => {
        let value = func(state);
        return value ? value[0] : null;
    };
};

export const getProfessionVerificationStatus = ReturnsSingleValue(getProfessionVerificationStatuses);
export const getIdentityVerificationStatus = ReturnsSingleValue(getIdentityVerificationStatuses);
export const getWorkplaceVerificationStatus = ReturnsSingleValue(getWorkplaceVerificationStatuses);
