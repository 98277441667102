import { GET_ACTIVE_USERS_ENDPOINT } from '../../../types/AnalyticsApi';
import { HandledData, Source, TimeSeriesDataPoint } from '../../../types/CompositeDataProvider';
import { UsersOptions } from '../../../types/DataProvider';
import { formatKeys } from '../lib';
import ChartBase, { ChartOptions } from './ChartBase';

class ActiveUsersChart extends ChartBase {
    private usersOptions: UsersOptions;

    constructor(filters: ChartOptions, usersOptions: UsersOptions) {
        super(filters);
        this.usersOptions = usersOptions;
        this.setTitle("Active Users");
    }

    protected getSources(): Promise<Source[]> {
        let sources: Source[] = [
            {
                id: 'activeUsers',
                src: GET_ACTIVE_USERS_ENDPOINT,
                options: {
                    ...this.filters,
                    ...this.usersOptions
                },
                handler: (res) => res.data
            }
        ];
        return Promise.resolve(sources);
    }

    protected parseRespose(data: HandledData[]): Promise<TimeSeriesDataPoint[]> {
        formatKeys(data);
        let handledData = data[0];
        return Promise.resolve(handledData.data);
    }
}

export default ActiveUsersChart;
