import { AccessToken } from '../types/Permissions';

abstract class Permission {
    /**
     * Returns true if the given token has this permissions, otherwise false.
     */
    abstract test: (token: AccessToken) => boolean;

    /**
     * Returns the result of Permission.test() and calls Permission.applyRestrictions() if the given token has this
     * permission.
     */
    testAndApplyRestrictions = (token: AccessToken): boolean => {
        if (this.test(token)) {
            this.applyRestrictions(token);
            return true;
        }
        return false;
    };

    /**
     * This should be called after Permission.test() if a user has this permission. Applies any restrictions this
     * permission has.
     */
    applyRestrictions = (token: AccessToken): void => {};
}

export default Permission;
