import { withStyles, Tabs, Theme, createStyles, Tab } from '@material-ui/core';
import React from 'react';

// Styled tabs from: https://material-ui.com/components/tabs/
export interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    variant?: 'scrollable',
    scrollButtons?: 'auto'
}

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > div': {
            // maxWidth: 40,
            width: '100%',
            backgroundColor: '#fbae17'
        }
    }
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export interface StyledTabProps {
    label: string;
}

export const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            color: '#fff',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '16px',
            minWidth: 'min-content',
            padding: '3px 15px',
            '&:focus': {
                opacity: 1
            }
        }
    })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export default StyledTabs;
