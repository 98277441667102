import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { signOut } from '../Auth';

interface AccountButtonProps {
    user: any;
}

const AccountButton: React.FC<AccountButtonProps> = ({ user }) => {
    const location = useLocation();

    const handleLogout = async () => {
        await signOut(user.id_token, { location });
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleLogout}>
                Logout
            </Button>
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.oidc.user
    };
}

export default connect(mapStateToProps)(AccountButton);
