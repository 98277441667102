import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Report from '../../services/reports/Report';
import ComprehensiveReportBuilder from '../../services/reports/Reports/ComprehensiveReportBuilder';
import CustomerReportBuilder from '../../services/reports/Reports/CustomerReportBuilder';
import WorkplaceReportBuilder from '../../services/reports/Reports/WorkplaceReportBuilder';
import { TimeInterval } from '../../types/AnalyticsApi';
import CenteredProgress from '../CenteredProgress';

const dateFormat = require('dateformat');

type ExportOption = 'Export' | 'Export for customer' | 'Export All';

const options: ExportOption[] = ['Export', 'Export for customer', 'Export All'];

interface ExportButtonProps {
    from: string | null;
    to: string | null;
    interval: TimeInterval;
    companyId: string[] | null;
    departmentId: string[] | null;
    user?: any;
}

interface ExportButtonState {
    isLoading: boolean;
    abortController: AbortController;
    exportOptionsOpen: boolean;
    selectedIndex: number;
}

const ExportButton: React.FC<ExportButtonProps> = ({ from, to, interval, companyId, departmentId, user }) => {
    const [state, setState] = useState<ExportButtonState>({
        isLoading: false,
        abortController: new AbortController(),
        exportOptionsOpen: false,
        selectedIndex: 0
    });

    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleClick = async () => {
        setState((prevState: ExportButtonState) => ({
            ...prevState,
            isLoading: true
        }));

        let filterOptions = {
            from,
            to,
            interval,
            companyId,
            departmentId
        };

        let report: Report;
        switch (options[state.selectedIndex]) {
            case 'Export':
                report = new WorkplaceReportBuilder(filterOptions).build();
                break;
            case 'Export for customer':
                report = new CustomerReportBuilder(filterOptions).build();
                break;
            case 'Export All':
                report = new ComprehensiveReportBuilder(filterOptions).build();
                break;
            default:
                throw Error('Unhandled export option');
        }

        let dateString = dateFormat(new Date(), 'dd-mm-yyyy');
        let filename = `Report-${dateString}.pdf`;

        await report.save(filename, state.abortController);

        setState((prevState: ExportButtonState) => ({
            ...prevState,
            isLoading: false
        }));
    };

    const handleDialogClose = () => {
        // Abort any operations currently in progress if the dialog is closed
        state.abortController.abort();

        setState((prevState: ExportButtonState) => ({
            ...prevState,
            isLoading: false,
            abortController: new AbortController()
        }));
    };

    // Abort any in-progress requests on dismount and init a new abort controller
    // on mount
    useEffect(() => {
        setState((prevState: ExportButtonState) => ({
            ...prevState,
            abortController: new AbortController()
        }));

        return () => {
            state.abortController.abort();
        };
        // Including state.abortController in deps would create a render loop
        // eslint-disable-next-line
    }, []);

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setState((prevState: ExportButtonState) => ({
            ...prevState,
            selectedIndex: index,
            exportOptionsOpen: false
        }));
    };

    const handleToggle = () => {
        setState((prevState: ExportButtonState) => ({
            ...prevState,
            exportOptionsOpen: !prevState.exportOptionsOpen
        }));
    };

    const handleExportOptionsClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setState((prevState: ExportButtonState) => ({
            ...prevState,
            exportOptionsOpen: false
        }));
    };

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClick} disabled={state.isLoading}>
                Export
            </Button> */}
            <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" disabled={state.isLoading}>
                <Button onClick={handleClick}>{options[state.selectedIndex]}</Button>
                <Button
                    size="small"
                    aria-controls={state.exportOptionsOpen ? 'split-button-menu' : undefined}
                    aria-expanded={state.exportOptionsOpen ? 'true' : undefined}
                    aria-label="select export"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                open={state.exportOptionsOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleExportOptionsClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            disabled={index === options.length + 1}
                                            selected={index === state.selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <Dialog
                open={state.isLoading}
                fullWidth
                maxWidth="xs"
                PaperProps={{
                    style: {
                        backgroundColor: '#222'
                    }
                }}
            >
                <DialogTitle>Generating Report...</DialogTitle>
                <DialogContent>
                    <CenteredProgress />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.oidc.user
});

export default connect(mapStateToProps)(ExportButton);
