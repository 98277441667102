const removeNullValues = (obj) => {
    let clean = Object.assign({}, obj);

    for (let key in clean) {
        if (clean[key] === null || clean[key] === undefined) {
            delete clean[key];
        }
    }

    return clean;
};

export default removeNullValues;
