import {
    CompaniesState,
    COMPANY_SELECTION,
    DEPARTMENT_SELECTION,
    FetchCompaniesActionType,
    FETCH_COMPANIES_ERROR,
    FETCH_COMPANIES_IS_LOADING,
    FETCH_COMPANIES_SUCCESS,
    SelectCompanyActionType
} from '../../types/Companies';
import { StoreState } from '../../types/Store';

const initialState: CompaniesState = {
    status: null,
    data: null,
    isLoading: null,
    companyDictionary: null,
    departmentDictionary: null,
    selectedCompany: null,
    selectedDepartment: null
};

const companiesReducer = (
    state = initialState,
    action: FetchCompaniesActionType | SelectCompanyActionType
): CompaniesState => {
    switch (action.type) {
        case FETCH_COMPANIES_IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_COMPANIES_SUCCESS:
            return {
                ...state,
                status: action.status,
                data: action.data,
                isLoading: false,
                companyDictionary: action.companyDictionary,
                departmentDictionary: action.departmentDictionary
            };
        case FETCH_COMPANIES_ERROR:
            return {
                ...state,
                status: action.status,
                isLoading: false
            };
        case COMPANY_SELECTION:
            return {
                ...state,
                selectedCompany: action.company,
                selectedDepartment: null
            };
        case DEPARTMENT_SELECTION:
            return {
                ...state,
                selectedDepartment: action.department
            };
        default:
            return state;
    }
};

export default companiesReducer;

export const getCompanies = (state: StoreState) => state.companies;
