import { GET_ACTIVE_USERS_ENDPOINT } from '../../../types/AnalyticsApi';
import { DataPoint, HandledData, Source, TimeSeriesDataPoint } from '../../../types/CompositeDataProvider';
import { UsersOptions } from '../../../types/DataProvider';
import { formatKeys, getMappedData } from '../lib';
import ChartBase, { ChartOptions } from './ChartBase';

class DailyActiveUsersChart extends ChartBase {
    private static DAU = 'Daily Active Users';

    private usersOptions: UsersOptions;

    constructor(filters: ChartOptions, usersOptions: UsersOptions) {
        super(filters);
        this.usersOptions = usersOptions;
        this.setTitle('Daily Active Users');
        this.setLegends(true);
        this.setValueLabels(false);
    }

    protected getSources(): Promise<Source[]> {
        let options = {
            ...this.filters,
            ...this.usersOptions
        };
        let sources: Source[] = [
            {
                id: DailyActiveUsersChart.DAU,
                src: GET_ACTIVE_USERS_ENDPOINT,
                options: {
                    ...options,
                    interval: 'd',
                    rolling: true
                },
                handler: (res) => res.data
            }
        ];
        return Promise.resolve(sources);
    }

    protected parseRespose(data: HandledData[]): Promise<TimeSeriesDataPoint[]> {
        formatKeys(data);
        let mappedData = getMappedData(data);
        let mergedData = this.mergeActiveUsersData(
            mappedData[DailyActiveUsersChart.DAU]
        );
        return Promise.resolve(mergedData);
    }

    private mergeActiveUsersData(dau: TimeSeriesDataPoint[]) {
        let mergedData: TimeSeriesDataPoint[] = [];
        for (let i = 0; i < dau.length; i++) {
            let subgroups: DataPoint[] = [];
            let dataPoint: TimeSeriesDataPoint = {
                key: dau[i].key,
                value: subgroups
            };

            subgroups.push({ key: DailyActiveUsersChart.DAU, value: dau[i].value as number });

            mergedData.push(dataPoint);
        }
        return mergedData;
    }
}

export default DailyActiveUsersChart;
