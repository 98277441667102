import React from 'react';
import useStyles from './styles';

interface SectionHeaderProps {
    children: React.ReactNode;
}

/**
 * Renders a section header (e.g. a header for a number of panels).
 */
const SectionHeader: React.FC<SectionHeaderProps> = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.sectionHeader}>{children}</div>;
};

export default SectionHeader;
