import React from 'react';
import {
    AnalyticsEndpoint,
    EventString,
    GET_ACTIVE_USERS_ENDPOINT,
    GET_TOTAL_ACTIVE_USERS_ENDPOINT,
    GET_TOTAL_USERS_ENDPOINT,
    GET_USERS_ENDPOINT,
    USER_ONLINE
} from '../../types/AnalyticsApi';
import CrossSectionalData from '../../types/CrossSectionalData';
import { DataProviderOptions, UsersOptions } from '../../types/DataProvider';
import TimeSeriesData, { TimeSeriesDataItem } from '../../types/TimeseriesData';
import { getCompanyOrDepartmentName } from '../../utils/getCompanyOrDepartmentName';
import DataProvider, { OptionalDataProviderProps } from './DataProvider';

interface UsersDataProviderProps extends OptionalDataProviderProps {
    children: any;
    options?: UsersOptions;
    top?: number | null;
    includeOtherGroup?: boolean;
    crossSection?: boolean;
    active?: boolean;
    debug?: boolean;
    responseHandler?: (res: any, options: DataProviderOptions) => TimeSeriesData | CrossSectionalData;
}

const UsersDataProvider: React.FC<UsersDataProviderProps> = ({
    children,
    options,
    top,
    includeOtherGroup,
    crossSection,
    active,
    responseHandler,
    ...other
}) => {
    // The active user aggregation doesn't have any subgrouping implemented at the moment, so these are not applicable
    if (active) {
        top = null;
        includeOtherGroup = false;
    }

    const timeSeriesResponseHandler = (res: any, options: DataProviderOptions): TimeSeriesData => {
        if (res.data.length === 0) {
            return [];
        }

        let timeSeriesData: TimeSeriesData = [];
        let topKeys = new Set();
        let isGrouped = options && options.groupBy;

        if (top && isGrouped) {
            let lastValue = res.data[res.data.length - 1].value;
            lastValue.sort((a: any, b: any) => a.value - b.value);

            for (let item of lastValue.slice(-top)) {
                topKeys.add(item.key);
            }
        }

        for (let item of res.data) {
            if (!(item.value instanceof Array)) {
                timeSeriesData.push({
                    key: item.key,
                    value: [
                        {
                            key: 'All',
                            value: item.value
                        }
                    ]
                });
            } else {
                // Replace IDs with their corresponding name if possible
                let timeSeriesDataSubItems: TimeSeriesDataItem<number>[] = [];
                let otherCount = 0;
                let hasOtherItems = false;

                for (let subItem of item.value) {
                    if (top && !topKeys.has(subItem.key)) {
                        hasOtherItems = true;
                        otherCount += subItem.value;
                        continue;
                    }

                    subItem.key = getCompanyOrDepartmentName(subItem.key);

                    timeSeriesDataSubItems.push(subItem);
                }

                if (includeOtherGroup && hasOtherItems) {
                    timeSeriesDataSubItems.push({
                        key: `Items ranked below top ${top}`,
                        value: otherCount
                    });
                }

                timeSeriesData.push({
                    key: item.key,
                    value: timeSeriesDataSubItems
                });
            }
        }

        return timeSeriesData;
    };

    const crossSectionalResponseHandler = (res: any, options: DataProviderOptions): CrossSectionalData => {
        let crossSectionalData: CrossSectionalData = [];
        let totals: {
            [id: string]: number;
        } = {};

        let topKeys = new Set();
        let isGrouped = options && options.groupBy;

        if (top && isGrouped) {
            res.data.sort((a: any, b: any) => a.value - b.value);

            for (let item of res.data.slice(-top)) {
                topKeys.add(item.key);
            }
        }

        if (!options.groupBy) {
            crossSectionalData.push({
                key: 'all',
                value: res.data
            });
        } else {
            // Accumulate the value of the groups from the response
            for (let item of res.data) {
                totals[item.key] = item.value;
            }

            let otherCount = 0;
            let hasOtherItems = false;

            for (let key in totals) {
                if (top && !topKeys.has(key)) {
                    hasOtherItems = true;
                    otherCount += totals[key];
                    continue;
                }

                let name = getCompanyOrDepartmentName(key);

                crossSectionalData.push({
                    key: name,
                    value: totals[key]
                });
            }

            if (includeOtherGroup && hasOtherItems) {
                crossSectionalData.push({
                    key: `Items ranked below top ${top}`,
                    value: otherCount
                });
            }
        }

        return crossSectionalData;
    };

    let userJoinedEventString: EventString = 'USER_JOINED';
    let userLeftEventString: EventString = 'USER_LEFT';

    if (options && options.groupBy) {
        switch (options.groupBy) {
            case 'companyId':
                userJoinedEventString = 'USER_JOINED_COMPANY';
                userLeftEventString = 'USER_LEFT_COMPANY';
                break;
            case 'departmentId':
                userJoinedEventString = 'USER_JOINED_DEPARTMENT';
                userLeftEventString = 'USER_LEFT_DEPARTMENT';
                break;
            default:
                if (options.departmentId) {
                    userJoinedEventString = 'USER_JOINED_DEPARTMENT';
                    userLeftEventString = 'USER_LEFT_DEPARTMENT';
                } else if (options.companyId) {
                    userJoinedEventString = 'USER_JOINED_COMPANY';
                    userLeftEventString = 'USER_LEFT_COMPANY';
                }
                break;
        }
    }

    let src: AnalyticsEndpoint;
    let updateOnEventStrings: EventString[];

    if (active) {
        updateOnEventStrings = [USER_ONLINE];
        if (crossSection) {
            src = GET_TOTAL_ACTIVE_USERS_ENDPOINT;
        } else {
            src = GET_ACTIVE_USERS_ENDPOINT;
        }
    } else {
        updateOnEventStrings = [userJoinedEventString, userLeftEventString];
        if (crossSection) {
            src = GET_TOTAL_USERS_ENDPOINT;
        } else {
            src = GET_USERS_ENDPOINT;
        }
    }
    return (
        <DataProvider
            dataFormat={crossSection ? 'CROSS_SECTION' : 'TIME_SERIES'}
            src={src}
            options={options}
            responseHandler={
                responseHandler || (crossSection ? crossSectionalResponseHandler : timeSeriesResponseHandler)
            }
            updateOnEventStrings={updateOnEventStrings}
            {...other}
        >
            {children}
        </DataProvider>
    );
};

export default UsersDataProvider;
