import React from 'react';

export interface CenterProps {
    vertical?: boolean;
    horizontal?: boolean;
}

/**
 * Centers it's children horizontally and vertically within it's parent.
 */
const Center: React.FC<CenterProps> = ({children, vertical, horizontal}) => {
    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: horizontal ? 'center' : 'normal',
                alignItems: vertical ? 'center' : 'normal',
                flexDirection: 'column'
            }}
        >
            {children}
        </div>
    );
};

Center.defaultProps = {
    vertical: true,
    horizontal: true
}

export default Center;
