import {
    PermissionsState,
    SetPermissionsActionType,
    SET_COMPANY_PERMISSIONS,
    SET_SUPER_ADMIN_STATUS
} from '../../types/Permissions';
import { StoreState } from '../../types/Store';

const initialState: PermissionsState = {
    isSuperAdmin: false,
    companyIds: []
};

const permissionsReducer = (state = initialState, action: SetPermissionsActionType): PermissionsState => {
    switch (action.type) {
        case SET_SUPER_ADMIN_STATUS:
            return {
                ...state,
                isSuperAdmin: action.isSuperAdmin
            };
        case SET_COMPANY_PERMISSIONS:
            return {
                ...state,
                companyIds: action.companyIds
            };
        default:
            return state;
    }
};

export default permissionsReducer;

export const getPermissions = (state: StoreState) => state.permissions;
