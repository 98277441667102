enum Environment {
    Staging = 'staging',
    Production = 'production',
    Enterprise = 'enterprise'
}

export const ENVIRONMENT = {
    isLocal: window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1',
    isStaging: process.env.REACT_APP_ENVIRONMENT === Environment.Staging,
    isProd: process.env.REACT_APP_ENVIRONMENT === Environment.Production,
    isEnterprise: process.env.REACT_APP_ENVIRONMENT === Environment.Enterprise,
};
