import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import Clock from './Clock';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        datetime: {
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff'
        }
    })
);

/**
 * Renders a clock.
 */
const PageClock: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.datetime}>
            <Clock />
        </div>
    );
};

export default PageClock;
