import { createStyles, makeStyles, Theme as MUITheme } from '@material-ui/core';
import { BarDatum, BarExtendedDatum, ResponsiveBar } from '@nivo/bar';
import { Theme } from '@nivo/core';
import React from 'react';
import CrossSectionalData from '../../types/CrossSectionalData';
import TimeSeriesData, { TimeSeriesDataItem } from '../../types/TimeseriesData';
import { BarChartContainerProps } from './BarChartContainer';

const dateFormat = require('dateformat');
const numeral = require('numeral');

export interface BarChartProps extends BarChartContainerProps {
    data: TimeSeriesData;
    diverging: boolean;
    crossSection?: boolean;
}

const getKeys = (data: TimeSeriesData | CrossSectionalData, crossSection?: boolean) => {
    let keys: string[] = [];

    if (crossSection) {
        for (let item of data as CrossSectionalData) {
            keys.push(item.key);
        }
        return keys;
    }

    // Check if data contains TimeSeriesData<number>
    for (let item of data) {
        if (!(item.value instanceof Array)) {
            keys.push('all');
            return keys;
        }
    }

    // Add the key of every sub item to our keys
    for (let item of data as TimeSeriesDataItem<TimeSeriesDataItem<number>[]>[]) {
        if (item.value.length === 0) {
            continue;
        }

        for (let subItem of (item.value as unknown) as TimeSeriesDataItem<number>[]) {
            if (!keys.includes(subItem.key)) {
                keys.push(subItem.key);
            }
        }
    }

    return keys;
};

const getTimestamps = (data: TimeSeriesData) => {
    let keys: string[] = [];

    for (let item of data) {
        keys.push(item.key);
    }

    return keys;
};

const getFormattedTimeseriesData = (data: TimeSeriesData, diverging: boolean) => {
    let formattedData = [];

    let lastValueDict: {
        [key: string]: {
            lastUpdated: number;
            value: number;
        };
    } = {};

    let keys = getKeys(data);
    if (diverging) {
        for (let key of keys) {
            lastValueDict[key] = {
                lastUpdated: 0,
                value: 0
            };
        }
    }

    if (keys.length === 0) {
        for (let timestamp of getTimestamps(data)) {
            formattedData.push({
                timestamp,
                All: 0
            });
        }
        return formattedData;
    }

    let count = 0;
    for (let item of data) {
        let group: any = {};
        group['key'] = item.key;

        // Check if data contains TimeSeriesDataItem<number>[]>
        if (item.value instanceof Array) {
            if (item.value.length === 0) {
                group['all'] = 0;
            } else {
                for (let subItem of item.value as TimeSeriesDataItem<number>[]) {
                    let value;
                    if (diverging) {
                        // Check that the last value was from the previous series value,
                        // otherwise treat the last value as zero
                        if (count - lastValueDict[subItem.key].lastUpdated !== 1) {
                            value = subItem.value;
                        } else {
                            value = subItem.value - lastValueDict[subItem.key].value;
                        }

                        lastValueDict[subItem.key].lastUpdated = count;
                        lastValueDict[subItem.key].value = subItem.value;
                    } else {
                        value = subItem.value;
                    }
                    group[subItem.key] = value;
                }
            }
        } else {
            let value;
            if (diverging) {
                if (count - lastValueDict[item.key].lastUpdated !== 1) {
                    value = item.value;
                } else {
                    value = item.value - lastValueDict['all'].value;
                }
                lastValueDict['all'].lastUpdated = count;
                lastValueDict['all'].value = item.value;
            } else {
                value = item.value;
            }
            group['all'] = value;
        }

        formattedData.push(group);
        count++;
    }
    return formattedData;
};

// eslint-disable-next-line
const getTickInterval = (numDatapoints: number, targetNumTicks?: number) => {
    let tickInterval = 1;
    let target = targetNumTicks || 10;

    // Adjust tickinterval based on number of datapoints
    if (numDatapoints > target) {
        tickInterval = tickInterval * Math.ceil(numDatapoints / target);
    }

    return tickInterval;
};

const getFormattedCrossSectionData = (data: CrossSectionalData): BarDatum[] => {
    let barChartData: BarDatum[] = [];

    for (let item of data) {
        barChartData.push({
            key: item.key,
            [item.key]: item.value
        });
    }

    return barChartData;
};

const customTickRenderer = (
    tick: any,
    tickInterval: number,
    labelDateFormat: string,
    crossSection?: boolean,
    labelFormatter?: (label: string) => string
) => {
    if (tick.tickIndex % tickInterval !== 0) {
        return null;
    }

    let label = crossSection ? tick.value : dateFormat(tick.value, labelDateFormat);

    if (labelFormatter) {
        label = labelFormatter(label);
    }

    return (
        <g transform={`translate(${tick.x},${tick.y + 22})`}>
            {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
            <text
                // transform={`rotate(-20)`}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fill: '#fff',
                    fontSize: 11
                }}
            >
                {label}
            </text>
        </g>
    );
};

const customLabel = (datum: BarDatum) => {
    let label = numeral(datum.value).format('0.[0]a');
    return `${label}`;
};

const customTooltip = (datum: BarExtendedDatum, tooltipDateFormat: string) => {
    let xLabel = dateFormat(datum.indexValue, tooltipDateFormat);
    let items: any = [];

    for (let key in datum.data) {
        if (key === 'key') continue;
        let value = datum.data[key];

        let item = (
            <div
                key={key}
                style={{
                    color: key === datum.id ? datum.color : '#fff',
                    padding: '3px 0'
                }}
            >
                <strong>{key}</strong> [{value}]
            </div>
        );

        items.push(item);
    }

    return (
        <React.Fragment>
            <div>{xLabel}</div>
            {items}
        </React.Fragment>
    );
};

const theme: Theme = {
    axis: {
        ticks: {
            text: {
                fill: '#ffffff'
            }
        }
    },
    tooltip: {
        container: {
            background: 'black',
            padding: '9px 12px',
            borderRadius: '10px'
        }
    }
};

const useStyles = makeStyles((theme: MUITheme) =>
    createStyles({
        chartPanelContainer: {
            flex: 1,
            height: '100%',
            width: '100%',
            position: 'relative'
        },
        chartBackground: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 120,
            left: 0,
            backgroundColor: '#111111',
            padding: '0 0 40px 60px',
            backgroundClip: 'content-box'
        },
        chartContainer: {
            position: 'absolute',
            width: '100%',
            height: '100%'
        }
    })
);

/**
 * A bar chart.
 *
 * Note: the chart must be placed within a container which has a height and width set.
 */
const BarChart: React.FC<BarChartProps> = ({
    data,
    mode,
    xLegend,
    yLegend,
    margin,
    dataProviderOptions,
    diverging,
    crossSection
}) => {
    const classes = useStyles();

    let formattedData = crossSection ? getFormattedCrossSectionData(data) : getFormattedTimeseriesData(data, diverging);
    let keys = getKeys(data, crossSection);
    let adjustedMargin = Object.assign({}, margin);
    // let isSingleSeries = keys.length === 1;
    let isSingleSeries = dataProviderOptions && !dataProviderOptions.groupBy;

    if (crossSection) {
        formattedData.sort((a, b) => {
            return a[a.key] - b[b.key];
        });
    }

    let tickInterval = getTickInterval(data.length, 15);
    if (!crossSection && dataProviderOptions) {
        // Ensure there's enough space for the bottom legends
        if (!isSingleSeries && adjustedMargin?.right && adjustedMargin.right < 120) {
            adjustedMargin.right = 120;
        }
    }

    let tickFormat = 'mmm dd, yyyy';
    let tooltipDateFormat = 'd mmm yyyy';
    let labelFormatter: ((label: string) => string) | undefined;
    if (dataProviderOptions && dataProviderOptions.interval) {
        switch (dataProviderOptions.interval) {
            case 'h':
                tickFormat = 'hTT';
                tooltipDateFormat = 'hTT';
                break;
            case 'd':
                tickFormat = 'd mmm';
                tooltipDateFormat = 'd mmm';
                break;
            case 'w':
                tickFormat = 'd mmm';
                tooltipDateFormat = 'd mmm';
                labelFormatter = (label: string) => `Week starting ${label}`;
                break;
            case 'M':
                tickFormat = 'mmm';
                tooltipDateFormat = 'mmm yyyy';
                break;
            case 'y':
                tickFormat = 'mmm yyyy';
                tooltipDateFormat = 'yyyy';
                break;
        }
    }

    // Potential method to truncate long legend labels (if placing them on the bottom)
    // - Map truncated legend names to their full name while displaying tooltips and
    //   map full names to their short names when creating formatted data
    // - Use a custom legends component, but we'll have to manually sync the colors with the legends
    //   (probably want to do this anyway as we'll want to control colors)

    return (
        <div className={classes.chartPanelContainer}>
            <div className={classes.chartBackground} style={{ right: `${adjustedMargin.right}px` }} />

            <div className={classes.chartContainer}>
                <ResponsiveBar
                    data={formattedData}
                    keys={keys}
                    indexBy={'key'}
                    groupMode={mode}
                    margin={adjustedMargin}
                    axisBottom={{
                        renderTick: (tick: any) =>
                            customTickRenderer(tick, tickInterval, tickFormat, crossSection, labelFormatter),
                        legend: xLegend,
                        legendPosition: 'middle',
                        legendOffset: adjustedMargin?.bottom ? adjustedMargin.bottom - 10 : -40,
                        tickPadding: 0
                    }}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 25,
                        tickRotation: 0,
                        tickValues: 6,
                        legend: yLegend,
                        legendPosition: 'middle',
                        legendOffset: adjustedMargin?.left ? 10 - adjustedMargin.left : -40
                    }}
                    theme={theme}
                    enableLabel={true}
                    label={customLabel}
                    labelSkipHeight={24}
                    labelSkipWidth={24}
                    enableGridY={false}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.5]] }}
                    animate={true}
                    legends={
                        crossSection || isSingleSeries
                            ? undefined
                            : [
                                  {
                                      dataFrom: 'keys',
                                      anchor: 'right',
                                      direction: 'column',
                                      translateX: 120,
                                      translateY: 0,
                                      itemsSpacing: 2,
                                      itemWidth: 100,
                                      itemHeight: 20,
                                      itemOpacity: 0.85,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      itemTextColor: '#ffffff',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]
                    }
                    markers={
                        !diverging
                            ? undefined
                            : [
                                  {
                                      axis: 'y',
                                      value: 0,
                                      lineStyle: { stroke: '#333333', strokeWidth: 0.5 }
                                  }
                              ]
                    }
                    tooltip={(data) => customTooltip(data, tooltipDateFormat)}
                />
            </div>
        </div>
    );
};

BarChart.defaultProps = {
    margin: {
        top: 20,
        left: 60,
        right: 20,
        bottom: 40
    },
    crossSection: false
};

export default BarChart;
