import React from 'react';
import { GET_TOTAL_PROFESSIONS_VERIFIED_ENDPOINT } from '../../types/AnalyticsApi';
import CrossSectionalData from '../../types/CrossSectionalData';
import { ProfessionsOptions, DataProviderOptions } from '../../types/DataProvider';
import DataProvider, { OptionalDataProviderProps } from './DataProvider';

interface ProfessionsDataProviderProps extends OptionalDataProviderProps {
    children: any;
    options?: ProfessionsOptions;
    crossSection?: boolean;
}
const ProfessionsDataProvider: React.FC<ProfessionsDataProviderProps> = ({
    children,
    options,
    crossSection,
    ...other
}) => {
    const crossSectionalResponseHandler = (res: any, options: DataProviderOptions): CrossSectionalData => {
        let crossSectionalData: CrossSectionalData = [];

        crossSectionalData.push({
            key: 'all',
            value: res.data
        });

        return crossSectionalData;
    };

    return (
        <DataProvider
            dataFormat={'CROSS_SECTION'}
            src={GET_TOTAL_PROFESSIONS_VERIFIED_ENDPOINT}
            options={options}
            responseHandler={crossSectionalResponseHandler}
            updateOnEventStrings={['PROFESSION_VERIFIED', 'PROFESSION_UNVERIFIED']}
            {...other}
        >
            {children}
        </DataProvider>
    );
};

export default ProfessionsDataProvider;
