import { IconButton, Typography } from '@material-ui/core';
import { EmojiPeople } from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LinkIcon from '@material-ui/icons/Link';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import VideocamIcon from '@material-ui/icons/Videocam';
import AddCommentIcon from '@material-ui/icons/AddComment';
import moment from 'moment';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as IdentityVerifiedIcon } from '../../assets/icons/identity-verified.svg';
import { ReactComponent as ProfessionVerifiedIcon } from '../../assets/icons/profession-verified.svg';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import {
    EventDTO,
    EventString,
    JoinMethod,
    MessageType,
    PRODUCTION_TEST_COMPANY_IDS,
    Workplace
} from '../../types/AnalyticsApi';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';
import { CreateUserProfileUrl } from '../../types/SuperAdmin';
import { getCompanyOrDepartmentName } from '../../utils/getCompanyOrDepartmentName';
import FeedItem from './FeedItem';

const dateFormat = require('dateformat');

const getFormattedCompanyNames = (workplaces?: Workplace[]) => {
    if (!workplaces) return 'Error - field missing.';
    if (workplaces.length === 0) return '';
    return (
        ' @ ' +
        workplaces
            .filter((wp) => !PRODUCTION_TEST_COMPANY_IDS.includes(wp.companyId))
            .map((wp) => {
                if (wp.departmentId) {
                    return `${getCompanyOrDepartmentName(wp.companyId)}, ${getCompanyOrDepartmentName(
                        wp.departmentId
                    )}`;
                }
                return getCompanyOrDepartmentName(wp.companyId);
            })
            .sort()
            .join('; ')
    );
};

const handleMessageSentEvent = (evt: EventDTO) => {
    let messageType;
    let conversationType;
    let text;
    let icon;
    let workplaces = getFormattedCompanyNames(evt.workplaces);

    switch (evt.type) {
        case MessageType.Photo:
            messageType = 'Photo';
            icon = <ImageIcon style={{ fill: '#49a9fc' }} />;
            break;
        case MessageType.Video:
            messageType = 'Video';
            icon = <VideocamIcon style={{ fill: '#49a9fc' }} />;
            break;
        case MessageType.PatientFile:
            messageType = 'Document';
            icon = <InsertDriveFileIcon style={{ fill: '#49a9fc' }} />;
            break;
        default:
            messageType = 'Message';
            icon = <ChatBubbleIcon style={{ fill: '#49a9fc' }} />;
    }

    switch (evt.conversationType) {
        case 'Chat':
            conversationType = 'chat';
            break;
        case 'Group':
            conversationType = 'group';
            break;
        case 'Case':
            conversationType = 'case';
            break;
    }

    text = `${messageType} sent to a ${conversationType}` + workplaces;

    return { text, icon };
};

const handleFileCreated = (evt: EventDTO) => {
    let fileType;
    let workplaces = getFormattedCompanyNames(evt.workplaces);
    let text;
    let icon;

    if (evt.mimeType && evt.mimeType.startsWith('image')) {
        fileType = 'Photo captured';
        icon = <ImageIcon style={{ fill: '#49a9fc' }} />;
    } else if (evt.mimeType && evt.mimeType.startsWith('video')) {
        fileType = 'Video captured';
        icon = <VideocamIcon style={{ fill: '#49a9fc' }} />;
    } else {
        fileType = 'File created';
        icon = <InsertDriveFileIcon style={{ fill: '#49a9fc' }} />;
    }

    text = fileType + workplaces;

    return { text, icon };
};

const handleUserJoined = (evt: EventDTO) => {
    let text = 'A new user joined Celo';
    let icon = <PersonAddIcon style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

const handleUserLeft = (evt: EventDTO) => {
    let text = 'A user left Celo';
    let icon = <PersonAddDisabledIcon style={{ fill: '#f44336' }} />;
    return { text, icon };
};

const handleUserJoinedCompany = (evt: EventDTO) => {
    let joinMethodString = 'email or admin';
    switch (evt.joinOnMethod) {
        case JoinMethod.Admin:
            joinMethodString = 'admin';
            break;
        case JoinMethod.InviteLink:
            joinMethodString = 'invite link';
            break;
        case JoinMethod.Email:
            joinMethodString = 'email';
            break;
        case JoinMethod.Creation:
            joinMethodString = 'creation';
            break;
    }
    let text = `User joined workspace "${getCompanyOrDepartmentName(evt.companyId!)}" via ${joinMethodString}`;
    let icon = <PersonAddIcon style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

const handleUserLeftCompany = (evt: EventDTO) => {
    let text = `User left workspace "${getCompanyOrDepartmentName(evt.companyId!)}"`;
    let icon = <PersonAddDisabledIcon style={{ fill: '#f44336' }} />;
    return { text, icon };
};

const handleUserJoinedDepartment = (evt: EventDTO) => {
    let departmentName = getCompanyOrDepartmentName(evt.departmentId!, 'No department');
    let companyName = getCompanyOrDepartmentName(evt.companyId!);
    let text = `User joined department "${departmentName}" @ ${companyName}`;
    let icon = <PersonAddIcon style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

const handleUserLeftDepartment = (evt: EventDTO) => {
    let departmentName = getCompanyOrDepartmentName(evt.departmentId!, 'No department');
    let companyName = getCompanyOrDepartmentName(evt.companyId!);
    let text = `User left department "${departmentName}" @ ${companyName}`;
    let icon = <PersonAddDisabledIcon style={{ fill: '#f44336' }} />;
    return { text, icon };
};

const handleConnectionRequestSent = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `Connection request sent` + workplaces;
    let icon = <GroupIcon style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

const handleConnectionRequestAccepted = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `Connection request accepted` + workplaces;
    let icon = <GroupAddIcon style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

const handleConnectionRequestDeclined = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `Connection request declined` + workplaces;
    let icon = <GroupAddIcon style={{ fill: '#f44336' }} />;
    return { text, icon };
};

const handleConnectionRemoved = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `Connection removed` + workplaces;
    let icon = <GroupIcon style={{ fill: '#f44336' }} />;
    return { text, icon };
};

const handleProfessionVerified = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `User verified their profession` + workplaces;
    let icon = <ProfessionVerifiedIcon style={{ height: '24px', minWidth: '24px' }} />;
    return { text, icon };
};

const handleProfessionUnverified = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `User lost their profession verification status` + workplaces;
    let icon = <ProfessionVerifiedIcon style={{ height: '24px', minWidth: '24px' }} />;
    return { text, icon };
};

const handleIdentityVerified = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `User verified their identity` + workplaces;
    let icon = <IdentityVerifiedIcon style={{ height: '24px', minWidth: '24px' }} />;
    return { text, icon };
};

const handleIdentityUnverified = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `User lost their identity verification status` + workplaces;
    let icon = <IdentityVerifiedIcon style={{ height: '24px', minWidth: '24px' }} />;
    return { text, icon };
};

// const handleUserOnlineEvent = (evt: EventDTO) => {
//     // let names = getFormattedCompanyNames(evt.workplaces!);
//     // let text = 'User online @ ' + names;
//     let text = 'User online';
//     let icon = <CloudIcon style={{ fill: '#49a9fc' }} />;
//     return { text, icon };
// };

// const handleUserOfflineEvent = (evt: EventDTO) => {
//     // let names = getFormattedCompanyNames(evt.workplaces!);
//     let text = 'User offline';
//     let icon = <CloudOffIcon style={{ fill: '#49a9fc' }} />;
//     return { text, icon };
// };

const handleInviteSentEvent = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `User sent an invite` + workplaces;
    let icon = <EmailIcon style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

const handleWorkspaceCreated = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `Workspace created` + workplaces;
    let icon = <BusinessIcon style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

const handleDebugEvent = (evt: EventDTO) => {
    return { text: 'DEBUG' };
};

const handleConversationInviteAccepted = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let text = `User accepted a group invite` + workplaces;
    let icon = <EmojiPeople style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

const handleConversationCreatedEvent = (evt: EventDTO) => {
    let workplaces = getFormattedCompanyNames(evt.workplaces!);
    let message = 'Conversation created';

    switch (evt.conversationType) {
        case 'Chat':
            message = 'Chat created';
            break;
        case 'Group':
            message = 'Group chat created';
            break;
        case 'Case':
            message = 'Case created';
            break;
        default:
            break;
    }

    let text = message + workplaces;
    let icon = <AddCommentIcon style={{ fill: '#49a9fc' }} />;
    return { text, icon };
};

export const eventHandlers: {
    [key in EventString]: ((evt: EventDTO) => { text: string; icon?: any }) | null;
} = {
    MESSAGE_SENT: handleMessageSentEvent,
    FILE_CREATED: handleFileCreated,
    WORKSPACE_CREATED: handleWorkspaceCreated,
    COMPANY_REGISTERED: null,
    USER_JOINED: handleUserJoined,
    USER_LEFT: handleUserLeft,
    USER_JOINED_COMPANY: handleUserJoinedCompany,
    USER_LEFT_COMPANY: handleUserLeftCompany,
    USER_JOINED_DEPARTMENT: handleUserJoinedDepartment,
    USER_LEFT_DEPARTMENT: handleUserLeftDepartment,
    CONNECTION_REQUEST_SENT: handleConnectionRequestSent,
    CONNECTION_REQUEST_ACCEPTED: handleConnectionRequestAccepted,
    CONNECTION_REQUEST_DECLINED: handleConnectionRequestDeclined,
    CONNECTION_REMOVED: handleConnectionRemoved,
    IDENTITY_VERIFIED: handleIdentityVerified,
    IDENTITY_UNVERIFIED: handleIdentityUnverified,
    PROFESSION_VERIFIED: handleProfessionVerified,
    PROFESSION_UNVERIFIED: handleProfessionUnverified,
    COMPANY_VERIFICATION_STATUS_UPDATED: null,
    USER_ONLINE: null,
    USER_OFFLINE: null,
    INVITE_SENT: handleInviteSentEvent,
    CONVERSATION_CREATED: handleConversationCreatedEvent,
    CONVERSATION_INVITE_ACCEPTED: handleConversationInviteAccepted,
    DEBUG: handleDebugEvent
};

const getFeedItem = (evt: EventDTO, key: string | number, isSuperAdmin: boolean = false) => {
    // Unhandled event, do nothing
    if (!(evt.eventString in eventHandlers)) {
        return null;
    }

    let handler = eventHandlers[evt.eventString as EventString];
    let time = new Date(evt.timestamp);

    if (handler) {
        let { text, icon } = handler(evt);
        let countryCodePrefix = isSuperAdmin ? `${evt.countryCode || 'ANZ'} - ` : '';
        text = countryCodePrefix.toUpperCase() + text;

        let iconButton: any = null;
        if (evt.userId && isSuperAdmin) {
            let url = CreateUserProfileUrl(evt.userId);
            iconButton = (
                <IconButton href={url} target="_blank" referrerPolicy="noreferrer noopener" style={{ padding: 0 }}>
                    {icon || <LinkIcon />}
                </IconButton>
            );
        }

        return <FeedItem text={text} icon={iconButton || icon} time={time} key={key} />;
    }

    return null;
};

const getDateDivider = (date: Date, key: string | number) => {
    return (
        <div key={key} style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Typography color="textSecondary" display="block" variant="caption">
                {dateFormat(date, 'dddd d mmmm yyyy')}
            </Typography>
        </div>
    );
};

export interface FeedProps {
    data: EventDTO[] | null;
    permissions: PermissionsState;
}

const Feed: React.FC<FeedProps> = ({ data, permissions }) => {
    // Used to give each list item a unique key
    const keyCount = useRef(0);
    if (data === null) return null;

    let lastEvt: EventDTO | null = null;
    let items: (JSX.Element | null)[] = [];
    for (let i = 0; i < data.length; i++) {
        let evt = data[i];

        // If the first event's day isn't today, create a label to indicate as much
        if (i === 0 && !moment(evt.timestamp).isSame(new Date(), 'd')) {
            items.push(getDateDivider(evt.timestamp, keyCount.current++));
        }
        // If this events's day isn't the same as the last day create a label to indicate
        else if (lastEvt !== null && !moment(evt.timestamp).isSame(lastEvt.timestamp, 'd')) {
            items.push(getDateDivider(evt.timestamp, keyCount.current++));
        }

        items.push(getFeedItem(evt, keyCount.current++, permissions.isSuperAdmin));

        lastEvt = evt;
    }

    return <React.Fragment>{items}</React.Fragment>;
};

const mapStateToProps = (state: StoreState) => ({
    permissions: getPermissions(state)
});

export default connect(mapStateToProps)(Feed);
