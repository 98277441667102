import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

export interface LegendItem {
    name: string;
    color: string;
}

export interface LegendProps {
    items: LegendItem[];
}

const useStyles = makeStyles(() =>
    createStyles({
        legendsContainer: {
            marginBottom: '21px'
        },
        legendText: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff',
            paddingLeft: '5px'
        }
    })
);

const Legend: React.FC<LegendProps> = ({ items }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.legendsContainer} justify="flex-end" alignItems="center">
            <Grid item container xs={4} spacing={3} justify="flex-end" alignItems="center">
                {items.map((item) => (
                    <Grid item key={item.name}>
                        <Grid container>
                            <svg width="15px" height="15px">
                                <rect width="15px" height="15px" fill={item.color} />
                                <rect x={1} y={1} width="13px" height="13px" fill="none" stroke="#000000" />
                            </svg>
                            <span className={classes.legendText}>{item.name}</span>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default Legend;
