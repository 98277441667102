import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import { CommonDataProviderOptions } from '../../types/DataProvider';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';
import getFromAndToDate from '../../utils/getFromAndToDate';
import UsersDataProvider from '../DataProviders/UsersDataProvider';
import LineChartContainer from '../LineChart/LineChartContainer';
import ChartSectionContainer from '../Section/ChartSectionContainer';
import SectionChart from '../Section/SectionChart';
import SectionDescription from '../Section/SectionDescription';
import SectionHeader from '../Section/SectionHeader';

interface DashboardFixedTimeChartsProps {
    companyId: string[] | null;
    departmentId: string[] | null;
    updateInterval?: number;
    countryCode: string[] | null;
    permissions: PermissionsState;
}

interface DashboardFixedTimeChartsState {
    from: Date;
}

const DashboardFixedTimeCharts: React.FC<DashboardFixedTimeChartsProps> = (props) => {
    const [state, setState] = useState<DashboardFixedTimeChartsState>({
        from: getFromAndToDate('Last 30 days').from!
    });

    // Update range every once in awhile if needed
    useEffect(() => {
        if (!props.updateInterval) return;

        const timeout = setInterval(() => {
            setState((prevState: DashboardFixedTimeChartsState) => ({
                from: getFromAndToDate('Last 30 days').from!
            }));
        }, props.updateInterval);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.updateInterval]);

    let options: CommonDataProviderOptions = {
        countryCode: props.countryCode,
        companyId: props.companyId,
        departmentId: props.departmentId,
        interval: 'd',
        from: state.from.toISOString()
    };

    let deltaOptions: CommonDataProviderOptions = {
        ...options,
        from: moment(state.from).subtract(1, 'day').toISOString()
    };

    let isSuperAdmin = props.permissions.isSuperAdmin;

    return (
        <React.Fragment>
            <ChartSectionContainer>
                <SectionHeader>Total Users</SectionHeader>
                <SectionChart>
                    <UsersDataProvider options={options} throttleMilliseconds={5000}>
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            tickValue={10}
                            tickFormat={'%-d/%m/%Y'}
                            tooltipDateFormat={'dddd d/mm/yyyy'}
                            colors={['#f4ff81']}
                        />
                    </UsersDataProvider>
                </SectionChart>
            </ChartSectionContainer>
            <ChartSectionContainer>
                <SectionHeader>Δ Total Users</SectionHeader>
                <SectionChart>
                    <UsersDataProvider options={deltaOptions} throttleMilliseconds={5000}>
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            tickValue={10}
                            tickFormat={'%-d/%m/%Y'}
                            tooltipDateFormat={'dddd d/mm/yyyy'}
                            colors={['#f4ff81']}
                            diverging
                        />
                    </UsersDataProvider>
                </SectionChart>
            </ChartSectionContainer>

            {isSuperAdmin && (
                <>
                    <ChartSectionContainer>
                        <SectionHeader>DAU/MAU</SectionHeader>
                        <SectionDescription>
                            Number of active users from the last 24 hours / last 30 days
                        </SectionDescription>
                        <SectionChart>
                            <UsersDataProvider
                                options={{
                                    ...options,
                                    interval: 'M',
                                    numeratorInterval: 'd',
                                    rolling: true,
                                    percent: true
                                }}
                                active={true}
                                throttleMilliseconds={5000}
                            >
                                <LineChartContainer
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 75,
                                        bottom: 40
                                    }}
                                    tickValue={10}
                                    tickFormat={'%-d/%m/%Y'}
                                    tooltipDateFormat={'dddd d/mm/yyyy'}
                                    colors={['#f4ff81']}
                                    decimalFractionAsPercentage
                                />
                            </UsersDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>

                    <ChartSectionContainer>
                        <SectionHeader>WAU/MAU</SectionHeader>
                        <SectionDescription>
                            Number of active users from the last 7 days / last 30 days
                        </SectionDescription>
                        <SectionChart>
                            <UsersDataProvider
                                options={{
                                    ...options,
                                    interval: 'M',
                                    numeratorInterval: 'w',
                                    rolling: true,
                                    percent: true
                                }}
                                active={true}
                                throttleMilliseconds={5000}
                            >
                                <LineChartContainer
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 75,
                                        bottom: 40
                                    }}
                                    tickValue={10}
                                    tickFormat={'%-d/%m/%Y'}
                                    tooltipDateFormat={'dddd d/mm/yyyy'}
                                    colors={['#f4ff81']}
                                    decimalFractionAsPercentage
                                />
                            </UsersDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>
                </>
            )}
        </React.Fragment>
    );
};

DashboardFixedTimeCharts.defaultProps = {};

function mapStateToProps(state: StoreState) {
    return {
        permissions: getPermissions(state)
    };
}

export default connect(mapStateToProps)(DashboardFixedTimeCharts);
