import React, { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { CallbackComponent, SignoutCallbackComponent } from 'redux-oidc';
import Center from '../components/Center';
import CenteredProgress from '../components/CenteredProgress';
import userManager from '../utils/userManager';

interface AuthCallbackProps {
    isLogin: boolean;
}

interface AuthCallbackState {
    isLoading: boolean;
}

const AuthCallback: React.FC<AuthCallbackProps> = ({ isLogin }) => {
    const [state, setState] = useState<AuthCallbackState>({
        isLoading: true
    });
    const history = useHistory();

    let progress = <CenteredProgress />;

    let redirect = (
        <Center>
            <Redirect to="/" />
            <div>Redirecting...</div>
            <div>
                Click
                <Link to="/" replace>
                    here
                </Link>
                if you are not redirected within a few seconds.
            </div>
        </Center>
    );

    let callback = null;
    if (isLogin) {
        callback = (
            <CallbackComponent
                userManager={userManager}
                successCallback={(user) => {
                    if (user.state?.location) {
                        history.push(user.state.location);
                    } else {
                        setState({
                            isLoading: false
                        });
                    }
                }}
                errorCallback={(error) => {
                    console.error(error);
                    setState({
                        isLoading: false
                    });
                }}
            >
                {progress}
            </CallbackComponent>
        );
    } else {
        callback = (
            <SignoutCallbackComponent
                userManager={userManager}
                successCallback={(user) => {
                    if (user.state?.location) {
                        history.push(user.state.location);
                    } else {
                        setState({
                            isLoading: false
                        });
                    }
                }}
                errorCallback={(error) => {
                    console.error(error);
                    setState({
                        isLoading: false
                    });
                }}
            >
                {progress}
            </SignoutCallbackComponent>
        );
    }

    return state.isLoading ? callback : redirect;
};

export default AuthCallback;
