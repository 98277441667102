import { Container, createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserState } from 'redux-oidc';
import appInfo from '../../package.json';
import CenteredProgress from '../components/CenteredProgress';
import DepartmentSelect from '../components/Controls/DepartmentSelect';
import ExportButton from '../components/Controls/ExportButton';
import GlobalControls from '../components/Controls/GlobalControls';
import StyledTabs, { StyledTab } from '../components/Controls/StyledTabs';
import TimeRangeSelect from '../components/Controls/TimeRangeSelect';
import DashboardBarCharts from '../components/Dashboard/DashboardBarCharts';
import DashboardFixedTimeCharts from '../components/Dashboard/DashboardFixedTimeCharts';
import DashboardFixedTimeMetrics from '../components/Dashboard/DashboardFixedTimeMetrics';
import DashboardInvitesCharts from '../components/Dashboard/DashboardInvitesCharts';
import DashboardInvitesMetrics from '../components/Dashboard/DashboardInvitesMetrics';
import DashboardLineCharts from '../components/Dashboard/DashboardLineCharts';
import DashboardMetrics from '../components/Dashboard/DashboardMetrics';
import FeedContainer from '../components/Feed/FeedContainer';
import PageClock from '../components/PageClock';
import PageHeader from '../components/PageHeader';
import SectionHeader from '../components/Section/SectionHeader';
import Snackbar from '../components/Snackbar';
import EventStream from '../notification/EventStream';
import { getCompanies } from '../redux/reducers/companiesReducer';
import { getSelectedCountries } from '../redux/reducers/countryReducer';
import { getMessageStream } from '../redux/reducers/messageStreamReducer';
import { getPermissions } from '../redux/reducers/permissionsReducer';
import { TimeInterval } from '../types/AnalyticsApi';
import { CompaniesState } from '../types/Companies';
import { Department } from '../types/CompanyData';
import { Country } from '../types/Countries';
import { PermissionsState } from '../types/Permissions';
import getGlobalCountryCode from '../utils/getGlobalCountryCode';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            paddingBottom: '80px'
        },
        container: {
            padding: '20px 50px 0px 50px',
            display: 'flex',
            flexDirection: 'column'
        },
        panelsContainer: {
            marginBottom: '38px',
            height: '100%'
        },
        activityFeed: {
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: '#111111',
            height: '100%',
            position: 'relative',
            paddingBottom: '40px',
            display: 'flex',
            minHeight: '300px'
        },
        feedContainer: {
            overflow: 'hidden',
            width: '100%'
        },
        activityFeedSeeMore: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ff8fad',
            textDecoration: 'none',
            position: 'absolute',
            bottom: '20px',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        chartPanelControls: {
            marginBottom: '21px'
        },
        activityFeedPanelContainer: {
            minHeight: '500px'
        },
        version: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '16px',
            color: '#616161',
            fontSize: '12px'
        }
    })
);

const tabIndices = {
    Statistics: 0,
    Overview: 1,
    Invites: 2
};

const tabOptions = {
    0: 'Statistics',
    1: 'Overview'
};

const superAdminTabOptions = Object.assign(
    {
        2: 'Invites'
    },
    tabOptions
);

const arrayEquals = (a: any, b: any) => {
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    if (a.length !== b.length) return false;

    const set1 = new Set(a);
    const set2 = new Set(b);

    for (let elem of Array.from(set1.values())) {
        if (!set2.has(elem)) {
            return false;
        }
    }

    return true;
};

interface DashboardProps {
    user: UserState;
    companies: CompaniesState;
    messageStream: EventStream | null;
    selectedCountries: Country[] | null;
    permissions: PermissionsState;
}

interface DashboardState {
    from: string | null;
    to: string | null;
    interval: TimeInterval;
    companyId: string[] | null;
    departmentId: string[] | null;
    previousDepartmentId: string[] | null;
    tabIndex: number;
    countryCode: string[] | null;
}

const Dashboard: React.FC<DashboardProps> = ({ user, companies, messageStream, selectedCountries, permissions }) => {
    const [state, setState] = useState<DashboardState>({
        from: null,
        to: null,
        interval: 'd',
        companyId: companies.selectedCompany ? [companies.selectedCompany.id] : null,
        departmentId: null,
        previousDepartmentId: null,
        tabIndex: 0,
        countryCode: getGlobalCountryCode()
    });
    const classes = useStyles();

    // Update selected company
    useEffect(() => {
        const companyId = companies.selectedCompany ? [companies.selectedCompany.id] : null;

        if (arrayEquals(state.companyId, companyId)) return;

        setState((prevState: DashboardState) => {
            return {
                ...prevState,
                companyId,
                departmentId: null,
                previousDepartmentId: null
            };
        });
    }, [companies, state.companyId]);

    // Update selected country
    useEffect(() => {
        const countryCode = getGlobalCountryCode();

        if (arrayEquals(state.countryCode, countryCode)) return;

        setState((prevState: DashboardState) => {
            return {
                ...prevState,
                countryCode
            };
        });
    }, [selectedCountries, state.countryCode]);

    const handleTimeRangeSelect = (from: Date | null, to: Date | null, interval: TimeInterval) => {
        setState((prevState: DashboardState) => {
            return {
                ...prevState,
                from: from ? from.toISOString() : null,
                to: to ? to.toISOString() : null,
                interval: interval
            };
        });
    };

    const handleDepartmentSelect = (department: Department | null) => {
        setState((prevState: DashboardState) => {
            let departmentId = department ? [department.id] : null;
            return {
                ...prevState,
                departmentId,
                previousDepartmentId: departmentId
            };
        });
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        // Reset the selected department when we change to the statistics tab
        let departmentId = newValue === tabIndices.Statistics ? null : state.previousDepartmentId;
        setState((prevState) => {
            return {
                ...prevState,
                tabIndex: newValue,
                departmentId
            };
        });
    };

    let content = null;
    let isSuperAdmin = permissions.isSuperAdmin;

    if (!companies || companies.isLoading === null || companies.isLoading || (isSuperAdmin && !messageStream)) {
        return <CenteredProgress />;
    }

    if (state.tabIndex === tabIndices.Statistics) {
        content = (
            <React.Fragment>
                <div className={classes.container}>
                    <Grid container justify="space-between" className={classes.panelsContainer}>
                        <Grid container item xs={permissions.isSuperAdmin ? 8 : 'auto'} spacing={2}>
                            <DashboardFixedTimeMetrics {...state} />
                        </Grid>
                        {permissions.isSuperAdmin && (
                            <Grid container item xs={4} spacing={3} direction="column">
                                <Grid item>
                                    <SectionHeader>ACTIVITY FEED</SectionHeader>
                                </Grid>
                                <Grid item xs className={classes.activityFeedPanelContainer}>
                                    <Paper className={classes.activityFeed}>
                                        <FeedContainer className={classes.feedContainer} bufferSize={75} {...state} />
                                        <Link to="/feed" className={classes.activityFeedSeeMore}>
                                            See more...
                                        </Link>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </div>
                <div className={classes.container}>
                    <DashboardFixedTimeCharts {...state} />
                </div>
            </React.Fragment>
        );
    } else if (state.tabIndex === tabIndices.Overview) {
        content = (
            <React.Fragment>
                <div className={classes.container}>
                    <Grid container className={classes.chartPanelControls} justify="space-between" alignItems="center">
                        <Grid item>
                            <Grid container spacing={3}>
                                {companies.selectedCompany && (
                                    <Grid item>
                                        <DepartmentSelect
                                            value={state.departmentId ? state.departmentId[0] : null}
                                            company={companies.selectedCompany}
                                            onChange={handleDepartmentSelect}
                                        />
                                    </Grid>
                                )}

                                <Grid item>
                                    <TimeRangeSelect onChange={handleTimeRangeSelect} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {isSuperAdmin && (
                            <Grid item>
                                <ExportButton {...state} />
                            </Grid>
                        )}
                    </Grid>

                    <Grid container justify="space-between" className={classes.panelsContainer}>
                        <Grid container item spacing={2}>
                            <DashboardMetrics {...state} />
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.container}>
                    <DashboardLineCharts {...state} />
                </div>

                <div className={classes.container}>
                    <DashboardLineCharts {...state} page={1} />
                </div>

                <div className={classes.container}>
                    <DashboardLineCharts {...state} page={2} />
                </div>

                <div className={classes.container}>
                    <DashboardBarCharts {...state} />
                </div>

                <div className={classes.container}>
                    <DashboardBarCharts {...state} page={1} />
                </div>
            </React.Fragment>
        );
    } else if (state.tabIndex === tabIndices.Invites) {
        content = (
            <React.Fragment>
                <Snackbar
                    message="Invites is currently semi-functional. Until issues are resolved it should not be relied on."
                    severity="warning"
                    autoHideDuration={5000}
                />
                <div className={classes.container}>
                    {/* Chart controls */}
                    <Grid container className={classes.chartPanelControls} justify="space-between" alignItems="center">
                        <Grid item>
                            <Grid container spacing={3}>
                                {companies.selectedCompany && (
                                    <Grid item>
                                        <DepartmentSelect
                                            value={state.departmentId ? state.departmentId[0] : null}
                                            company={companies.selectedCompany}
                                            onChange={handleDepartmentSelect}
                                        />
                                    </Grid>
                                )}

                                <Grid item>
                                    <TimeRangeSelect onChange={handleTimeRangeSelect} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justify="space-between" className={classes.panelsContainer}>
                        <Grid container item spacing={2}>
                            <DashboardInvitesMetrics {...state} />
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.container}>
                    <DashboardInvitesCharts {...state} />
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Container maxWidth={'xl'} className={classes.pageContainer}>
                <div className={classes.container}>
                    <PageHeader>
                        {companies.selectedCompany ? companies.selectedCompany.name : 'Celo'} Dashboard
                    </PageHeader>

                    <PageClock />

                    <GlobalControls />

                    {/* Statistics and overview tabs */}
                    <StyledTabs value={state.tabIndex} onChange={handleTabChange} aria-label="dashboard-tabs">
                        {Object.values(permissions.isSuperAdmin ? superAdminTabOptions : tabOptions).map((value) => (
                            <StyledTab label={value} key={value} />
                        ))}
                    </StyledTabs>
                </div>

                {content}
            </Container>
            <div className={classes.version}>Version {appInfo.version}</div>
        </React.Fragment>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.oidc.user,
        messageStream: getMessageStream(state),
        companies: getCompanies(state),
        selectedCountries: getSelectedCountries(state),
        permissions: getPermissions(state)
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
