import React from 'react';
import { Cohort } from '../types/Cohort';
import { CompaniesState } from '../types/Companies';
import { CrossSectionDataPoint, DataFormat, TimeSeriesDataPoint } from '../types/CompositeDataProvider';
import { DataProviderOptions } from '../types/DataProvider';
import { FiltersState } from '../types/Filters';
import CenteredProgress from './CenteredProgress';
import ErrorMessage from './ErrorMessage';

export interface ChartProps {
    xLegend?: string;
    yLegend?: string;
    margin?: Partial<{
        top: number;
        left: number;
        right: number;
        bottom: number;
    }>;
    companies?: CompaniesState;
    filters?: FiltersState;
    dataFormat?: DataFormat;
    data?: TimeSeriesDataPoint[] | CrossSectionDataPoint[] | Cohort[];
    isFirstLoad?: boolean;
    disableLoadingIndicator?: boolean;
    dataProviderOptions?: DataProviderOptions;
    metric?: boolean;
    error?: boolean;
    renderLoadingIndicator?: () => React.ReactNode;
    renderLoadingError?: () => React.ReactNode;
}

const ChartContainer: React.FC<ChartProps> = (props) => {
    const {
        children,
        isFirstLoad,
        disableLoadingIndicator,
        data,
        error,
        companies,
        metric,
        renderLoadingIndicator,
        renderLoadingError,
        ...other
    } = props;

    if (isFirstLoad && !disableLoadingIndicator) {
        if (renderLoadingIndicator) {
            return <React.Fragment>{renderLoadingIndicator()}</React.Fragment>;
        }
        return <CenteredProgress />;
    }

    if (error) {
        return <ErrorMessage>{renderLoadingError!()}</ErrorMessage>;
    }

    return (
        <React.Fragment>
            {React.Children.map(children, (child: any, index: number) => {
                return React.cloneElement(child, {
                    ...other
                });
            })}
        </React.Fragment>
    );
};

ChartContainer.defaultProps = {
    renderLoadingError: () => 'Error Retrieving data'
};

export default ChartContainer;
