import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { throttle } from "throttle-debounce";
import EventStream from "../../notification/EventStream";
import { getCompanies } from "../../redux/reducers/companiesReducer";
import { getSelectedCountries } from "../../redux/reducers/countryReducer";
import { getFilterOptions } from "../../redux/reducers/filtersReducer";
import { getMessageStream } from "../../redux/reducers/messageStreamReducer";
import { DatabaseUpdate, EventString } from "../../types/AnalyticsApi";
import { CompaniesState } from "../../types/Companies";
import {
  CrossSectionDataPoint,
  CROSS_SECTION,
  HandledData,
  Source,
  TimeSeriesDataPoint,
  TIME_SERIES,
} from "../../types/CompositeDataProvider";
import { Country } from "../../types/Countries";
import {
  CommonDataProviderOptions,
  DataProviderOptions,
} from "../../types/DataProvider";
import removeNullValues from "../../utils/removeNullValues";
import { createUrl } from "../../utils/urls";

var moment = require("moment-timezone");

interface CommonCompositeDataProviderProps {
  companies: CompaniesState;
  children: any;
  options?: DataProviderOptions;
  messageStream: EventStream | null;
  sources: Source[];
  updateOnEventStrings?: EventString[];
  throttleMilliseconds?: number;
  forcedUpdateIntervalMilliseconds?: number | null;
  user: any;
  debug?: boolean;
  selectedCountries: Country[] | null;
  globalFilters: CommonDataProviderOptions | null;
}

interface TimeSeriesDataProviderProps extends CommonCompositeDataProviderProps {
  dataFormat: typeof TIME_SERIES;
  merger: (data: HandledData[]) => TimeSeriesDataPoint[];
}

interface CrossSectionDataProviderProps
  extends CommonCompositeDataProviderProps {
  dataFormat?: typeof CROSS_SECTION;
  merger: (data: HandledData[]) => CrossSectionDataPoint[];
}

type CompositeDataProviderProps =
  | TimeSeriesDataProviderProps
  | CrossSectionDataProviderProps;

interface CompositeDataProviderState {
  data: TimeSeriesDataPoint[] | CrossSectionDataPoint[] | null;
  isLoading: boolean;
  isFirstLoad: boolean;
  dataProviderOptions?: DataProviderOptions;
  error: boolean;
}

/**
 * Fetches data from the given sources. Resolves with an array of HandledData objects.
 */
export const fetchAnalyticsData = async (
  abortController: AbortController,
  accessToken: string,
  sources: Source[],
  options: DataProviderOptions
) => {
  let promises: Promise<HandledData>[] = [];

  for (let item of sources) {
    const mergedOptions: DataProviderOptions = removeNullValues({
      ...options,
      ...item.options,
      utcOffset: moment.tz.guess(),
    });
    let url = createUrl(item.src, mergedOptions);
    let resolve: (value: HandledData) => void;
    let reject: (reason?: any) => void;
    let promise = new Promise<HandledData>((res, rej) => {
      resolve = res;
      reject = rej;
    });
    promises.push(promise);

    fetch(url, {
      signal: abortController.signal,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }
        return res.json();
      })
      .then((res) => {
        resolve({
          id: item.id,
          src: item.src,
          data: item.handler(res, mergedOptions),
          options: mergedOptions,
        });
      })
      .catch((error) => {
        reject(error);
      });
  }

  return Promise.all(promises);
};

const CompositeDataProvider: React.FC<CompositeDataProviderProps> = ({
  throttleMilliseconds,
  user,
  options,
  globalFilters,
  sources,
  merger,
  messageStream,
  updateOnEventStrings,
  dataFormat,
  companies,
  forcedUpdateIntervalMilliseconds,
  children,
}) => {
  const [state, setState] = useState<CompositeDataProviderState>({
    data: null,
    isLoading: true,
    isFirstLoad: true,
    error: false,
  });

  const fetchData = useMemo(
    () =>
      throttle(
        throttleMilliseconds!,
        (abortController: AbortController, firstLoad?: boolean) => {
          // Without the following condition we can get a memory leak if we try to set state
          // after the fetch has been aborted due to a dismount
          if (abortController.signal.aborted) {
            return;
          }

          setState((prevState: CompositeDataProviderState) => {
            return {
              ...prevState,
              isLoading: true,
              isFirstLoad: firstLoad ? true : prevState.isFirstLoad,
              error: false,
            };
          });

          let accessToken = user ? user.access_token : "";
          let commonOptions: CommonDataProviderOptions = options || {};

          // Merge global options with the options provided and override global options with the ones provided
          let mergedOptions: CommonDataProviderOptions = {
            ...globalFilters,
            ...commonOptions,
          };

          fetchAnalyticsData(
            abortController,
            accessToken,
            sources,
            mergedOptions
          )
            .then((values) => {
              // Without the following condition we can get a memory leak if we try to set state
              // after the fetch has been aborted due to a dismount
              if (abortController.signal.aborted) {
                return;
              }

              setState((prevState: CompositeDataProviderState) => ({
                ...prevState,
                data: merger(values),
                isFirstLoad: false,
                isLoading: false,
                dataProviderOptions: mergedOptions,
              }));
            })
            .catch((error) => {
              if (error.code === 20) {
                // Abort error
                return;
              }
              console.error(error);

              setState((prevState: CompositeDataProviderState) => {
                return {
                  ...prevState,
                  data: null,
                  isFirstLoad: false,
                  isLoading: false,
                  dataProviderOptions: mergedOptions,
                  error: true,
                };
              });
            });
        }
      ),
    [merger, options, sources, globalFilters, throttleMilliseconds, user]
  );

  // Fetch data on initial mount and update when new data is available
  useEffect(() => {
    const abortController = new AbortController();
    let removeEventListener: () => Boolean;

    // Initial fetch to synchronize local data
    fetchData(abortController, true);

    // Fetch data if one of the events we're monitoring has new data
    if (messageStream && updateOnEventStrings) {
      removeEventListener = messageStream.on(
        "update",
        (message: DatabaseUpdate) => {
          if (
            message.eventStrings.some((elem) =>
              updateOnEventStrings!.includes(elem)
            )
          ) {
            fetchData(abortController);
          }
        }
      );
    }

    return () => {
      abortController.abort();
      if (removeEventListener) {
        removeEventListener();
      }
    };
    // We don't want to include state.data in our dependency array as this will create a rendering loop
    // eslint-disable-next-line
  }, [
    options,
    companies,
    fetchData,
    messageStream,
    updateOnEventStrings,
    dataFormat,
    sources,
    globalFilters,
  ]);

  // Periodic forced update
  // This is to keep the axis on charts, etc, up to date in cases where no natural updates occur.
  useEffect(() => {
    if (!forcedUpdateIntervalMilliseconds) {
      return;
    }
    const abortController = new AbortController();

    let timeout = setInterval(() => {
      fetchData(abortController);
    }, forcedUpdateIntervalMilliseconds);

    return () => {
      abortController.abort();
      clearTimeout(timeout);
    };
  }, [forcedUpdateIntervalMilliseconds, fetchData]);

  return (
    <React.Fragment>
      {React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          ...state,
          dataFormat: dataFormat,
          companies: companies,
        });
      })}
    </React.Fragment>
  );
};

CompositeDataProvider.defaultProps = {
  dataFormat: "TIME_SERIES",
  throttleMilliseconds: 500,
  forcedUpdateIntervalMilliseconds: 60000,
};

const mapStateToProps = (state: any) => ({
  messageStream: getMessageStream(state),
  companies: getCompanies(state),
  user: state.oidc.user,
  selectedCountries: getSelectedCountries(state),
  globalFilters: getFilterOptions(state),
});

export default connect(mapStateToProps)(CompositeDataProvider);
