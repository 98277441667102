import { Container, createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CenteredProgress from '../components/CenteredProgress';
import GlobalControls from '../components/Controls/GlobalControls';
import StyledTabs, { StyledTab } from '../components/Controls/StyledTabs';
import FeedContainer from '../components/Feed/FeedContainer';
import PageClock from '../components/PageClock';
import PageHeader from '../components/PageHeader';
import EventStream from '../notification/EventStream';
import { getCompanies } from '../redux/reducers/companiesReducer';
import { getSelectedCountries } from '../redux/reducers/countryReducer';
import { getMessageStream } from '../redux/reducers/messageStreamReducer';
import { getPermissions } from '../redux/reducers/permissionsReducer';
import {
    DOCUMENT_MIMETYPES,
    EventDTO,
    EventString,
    IdentityVerificationStatus,
    IMAGE_MIMETYPES,
    MessageType,
    ProfessionVerificationStatus,
    VIDEO_MIMETYPES
} from '../types/AnalyticsApi';
import { CompaniesState } from '../types/Companies';
import { Country } from '../types/Countries';
import { PermissionsState } from '../types/Permissions';
import getGlobalCountryCodes from '../utils/getGlobalCountryCode';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '20px 50px 0px 50px',
            display: 'flex',
            flexDirection: 'column'
        },
        feedContainer: {
            marginTop: '18px',
            marginBottom: '46px',
            padding: '37px 35px',
            borderRadius: '10px',
            backgroundColor: '#111111',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        }
    })
);

const filters = {
    0: 'All',
    1: 'Users',
    2: 'Messages',
    3: 'Photos',
    4: 'Videos',
    5: 'Documents',
    6: 'Connections',
    7: 'Verifications',
    8: 'Invites',
    9: 'Workspaces',
    10: "Conversations"
};

// Indices of tabs that only a super admin should see
const superAdminOnlyFilterIndices: number[] = [8, 9, 10];

const NonSuperAdminFilters = Object.fromEntries(
    Object.entries(filters).filter(([key, value]) => !superAdminOnlyFilterIndices.includes(Number(key)))
);

type filterKeys = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

// Used to filter event types, but is not sufficient to filter by fields on the events themselves
// e.g. to distinguish between a text message and a photo message the "type" field in a "MESSAGE_SENT" event must be inspected
const filterEventStrings: {
    [key in filterKeys]: EventString[];
} = {
    0: [],
    1: [
        'USER_JOINED',
        'USER_JOINED_COMPANY',
        'USER_JOINED_DEPARTMENT',
        'USER_LEFT',
        'USER_LEFT_COMPANY',
        'USER_LEFT_DEPARTMENT'
    ],
    2: ['MESSAGE_SENT'],
    3: ['MESSAGE_SENT', 'FILE_CREATED'],
    4: ['MESSAGE_SENT', 'FILE_CREATED'],
    5: ['FILE_CREATED'],
    6: ['CONNECTION_REQUEST_SENT', 'CONNECTION_REQUEST_ACCEPTED', 'CONNECTION_REQUEST_DECLINED', 'CONNECTION_REMOVED'],
    7: ['PROFESSION_VERIFIED', 'IDENTITY_VERIFIED', 'PROFESSION_UNVERIFIED', 'IDENTITY_UNVERIFIED'],
    8: ['INVITE_SENT', 'CONVERSATION_INVITE_ACCEPTED'],
    9: ['WORKSPACE_CREATED'],
    10: ['CONVERSATION_CREATED']
};

// Used to determine whether an event should be included in the activity feed
// null = no filtering needed, all events that match filterEventStrings are included
// otherwise the value is a function take takes in an event of type filterEventStrings[key] and returns true if that event should be included
const filterFunctions: {
    [key in filterKeys]: ((evt: EventDTO) => boolean) | null;
} = {
    0: null,
    1: null,
    2: (evt) => evt.type === MessageType.NULL,
    3: (evt) => {
        if (evt.eventString === 'MESSAGE_SENT' && evt.type === MessageType.Photo) {
            return true;
        } else if (evt.eventString === 'FILE_CREATED' && evt.mimeType && evt.mimeType.startsWith('image')) {
            return true;
        }
        return false;
    },
    4: (evt) => {
        if (evt.eventString === 'MESSAGE_SENT' && evt.type === MessageType.Video) {
            return true;
        } else if (evt.eventString === 'FILE_CREATED' && evt.mimeType && evt.mimeType.startsWith('video')) {
            return true;
        }
        return false;
    },
    5: (evt) => !(evt.mimeType && evt.mimeType.startsWith('image')),
    6: null,
    7: null,
    8: null,
    9: null,
    10: null
};

// Specify what message types to retrieve for each filter option (only applies if MESSAGE_SENT events are retrieved, null to apply no filtering)
const messageTypes: { [key in filterKeys]: MessageType[] | null } = {
    0: null,
    1: null,
    2: null,
    3: [MessageType.Photo],
    4: [MessageType.Video],
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
    10: null
};

// Specify what files types to retrieve for each filter option (only applies if MESSAGE_SENT events are retrieved, null to apply no filtering)
const mimeTypes: { [key in filterKeys]: string[] | null } = {
    0: null,
    1: null,
    2: null,
    3: IMAGE_MIMETYPES,
    4: VIDEO_MIMETYPES,
    5: DOCUMENT_MIMETYPES,
    6: null,
    7: null,
    8: null,
    9: null,
    10: null
};

interface FeedProps {
    user: any;
    companies: CompaniesState;
    messageStream: EventStream | null;
    selectedCountries: Country[] | null;
    permissions: PermissionsState;
}

interface FeedState {
    tabIndex: number;
    countryCodes: string[] | null;
    companyId: string[] | null;
    departmentId: string[] | null;
    professionVerificationStatus: ProfessionVerificationStatus[] | null;
    identityVerificationStatus: IdentityVerificationStatus[] | null;
}

const Feed: React.FC<FeedProps> = ({ companies, messageStream, selectedCountries, permissions }) => {
    const [state, setState] = useState<FeedState>({
        tabIndex: 0,
        countryCodes: null,
        companyId: null,
        departmentId: null,
        professionVerificationStatus: null,
        identityVerificationStatus: null
    });
    const classes = useStyles();

    // Update selected country
    useEffect(() => {
        setState((prevState: FeedState) => ({
            ...prevState,
            countryCode: selectedCountries ? selectedCountries.map((country) => country.code) : null
        }));
    }, [selectedCountries]);

    // Update selected company
    useEffect(() => {
        setState((prevState: FeedState) => {
            return {
                ...prevState,
                companyId: companies.selectedCompany ? [companies.selectedCompany.id] : null,
                departmentId: null
            };
        });
    }, [companies]);

    // Update selected country
    useEffect(() => {
        setState((prevState: FeedState) => ({
            ...prevState,
            countryCodes: getGlobalCountryCodes()
        }));
    }, [selectedCountries]);

    if (!companies || companies.isLoading === null || companies.isLoading || !messageStream) {
        return <CenteredProgress />;
    }

    let filterOptions = permissions.isSuperAdmin ? filters : NonSuperAdminFilters;

    return (
        <Container maxWidth={'xl'}>
            <div className={classes.container}>
                <PageHeader>
                    {companies.selectedCompany ? companies.selectedCompany.name : 'Celo'} Activity Feed
                </PageHeader>

                <PageClock />

                <GlobalControls />

                <StyledTabs
                    value={state.tabIndex}
                    onChange={(event, newValue) => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                tabIndex: newValue
                            };
                        });
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="filters"
                >
                    {Object.entries(filterOptions).map(([key, value]) => (
                        <StyledTab label={value} key={key} />
                    ))}
                </StyledTabs>

                <Paper className={classes.feedContainer}>
                    <FeedContainer
                        {...state}
                        bufferSize={100}
                        eventStrings={filterEventStrings[state.tabIndex as filterKeys]}
                        filterFunc={filterFunctions[state.tabIndex as filterKeys] || undefined}
                        paginated
                        type={messageTypes[state.tabIndex as filterKeys] || undefined}
                        mimeType={mimeTypes[state.tabIndex as filterKeys] || undefined}
                    />
                </Paper>
            </div>
        </Container>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.oidc.user,
        messageStream: getMessageStream(state),
        companies: getCompanies(state),
        selectedCountries: getSelectedCountries(state),
        permissions: getPermissions(state)
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
