import { GroupingMethod, TimeInterval } from '../../../types/AnalyticsApi';
import { HandledData, Source, TimeSeriesDataPoint } from '../../../types/CompositeDataProvider';
import { fetchStats } from '../../stats/lib';
import Chart from '../Chart';

export interface ChartOptions {
    from?: string | null;
    to?: string | null;
    interval: TimeInterval;
    companyId?: string[] | null;
    departmentId?: string[] | null;
    groupBy?: GroupingMethod | null;
}

abstract class ChartBase extends Chart {
    protected filters: ChartOptions = {
        interval: 'd'
    };

    constructor(filters: ChartOptions) {
        super();

        this.filters = {
            ...this.filters,
            ...filters
        };

        this.setChartType(this.filters.groupBy ? 'bar' : 'line');
        this.setLegends(this.filters.groupBy ? true : false)
    }

    protected async getData(abortController: AbortController): Promise<TimeSeriesDataPoint[]> {
        try {
            let sources = await this.getSources();
            let response = await fetchStats(abortController, sources, this.filters);
            let data = await this.parseRespose(response);
            return data;
        } catch (e) {
            throw Error('Failed to get data.');
        }
    }

    protected abstract getSources(): Promise<Source[]>;

    protected abstract parseRespose(data: HandledData[]): Promise<TimeSeriesDataPoint[]>;
}

export default ChartBase;
