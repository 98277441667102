import store from '../redux/store';

export const getCompanyOrDepartmentName = (id: string, nullString?: string) => {
    let state = store.getState();
    let normalizedId = id?.toUpperCase();
    let name = normalizedId;

    // Need to add some way of replacing null ids with "No Company" or "No Department"
    if (id === null || id === undefined) {
        return nullString || 'No Department';
    }

    // If company state hasn't been loaded for some reason
    if (!state.companies) {
        return normalizedId || 'UNKNOWN';
    }

    if (state.companies.companyDictionary && state.companies.companyDictionary[normalizedId]) {
        name = state.companies.companyDictionary[normalizedId].name;
    }

    if (state.companies.departmentDictionary && state.companies.departmentDictionary[normalizedId]) {
        name = state.companies.departmentDictionary[normalizedId].name;
    }

    return name || normalizedId || 'UNKNOWN';
};
