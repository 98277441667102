import { Box, FormControlLabel, Grid, Switch } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCompanies } from '../../redux/reducers/companiesReducer';
import store from '../../redux/store';
import { EventString, GET_USER_ENTITIES_ENDPOINT } from '../../types/AnalyticsApi';
import { Cohort, KeyValuePair } from '../../types/Cohort';
import { CompaniesState } from '../../types/Companies';
import { StoreState } from '../../types/Store';
import { createUrl } from '../../utils/urls';
import CenteredProgress from '../CenteredProgress';
import ErrorMessage from '../ErrorMessage';
import HeatMap from './HeatMap';
import HeatMapDialog from './HeatMapDialog';

interface GetUsersParameters {
    startDate: Date;
    stopDate: Date;
    eventStrings: EventString[];
    abortController: AbortController;
    companyIds?: string[] | null;
}

const getUsers = async ({
    startDate,
    stopDate,
    eventStrings,
    abortController,
    companyIds
}: GetUsersParameters): Promise<string[]> => {
    const access_token = store.getState().oidc.user?.access_token;

    if (!access_token) return Promise.reject();

    let url = createUrl(GET_USER_ENTITIES_ENDPOINT, {
        from: startDate.toISOString(),
        to: stopDate.toISOString(),
        eventString: eventStrings,
        companyId: companyIds
    });

    try {
        let res = await fetch(url, {
            headers: {
                Authorization: `Bearer ${access_token}`
            },
            signal: abortController.signal
        });
        let data = await res.json();
        return data.data;
    } catch (e) {
        return Promise.reject(e);
    }
};

export interface HeatMapContainerProps {
    loading?: boolean;
    isFirstLoad?: boolean;
    error?: boolean | null;
    data?: Cohort[] | null;
    companies: CompaniesState;
}

const HeatMapContainer: React.FC<HeatMapContainerProps> = ({ loading, isFirstLoad, error, data, companies }) => {
    const [userIds, setUserIds] = useState<string[] | null>(null);
    const [getUserIdsError, setGetUserIdsError] = useState(null);
    const [open, setOpen] = useState(false);
    const [displayData, setDisplayData] = useState(data);
    const [percentage, setPercentage] = useState(false);
    const [partial, setPartial] = useState(true);
    const [abortController, setAbortController] = useState(new AbortController());

    const onCellClick = async (cohort: Cohort, activity: KeyValuePair<Date, number>) => {
        if (!displayData) return;
        setOpen(true);

        let timeRange = moment(displayData[0].stopDate).diff(displayData[0].startDate);

        let activityStopDate = moment(activity.key).add(timeRange).toDate();
        let eventStrings: EventString[] = [];

        if (cohort.startDate === activity.key) {
            eventStrings.push(companies.selectedCompany ? 'USER_JOINED_COMPANY' : 'USER_JOINED');
        } else {
            eventStrings.push('USER_ONLINE');
        }

        let results: string[][];
        try {
            results = await Promise.all([
                getUsers({
                    startDate: cohort.startDate,
                    stopDate: cohort.stopDate,
                    eventStrings: companies.selectedCompany ? ['USER_JOINED_COMPANY'] : ['USER_JOINED'],
                    abortController,
                    companyIds: companies.selectedCompany ? [companies.selectedCompany.id] : null
                }),
                getUsers({
                    startDate: activity.key,
                    stopDate: activityStopDate,
                    eventStrings: eventStrings,
                    abortController,
                    companyIds: companies.selectedCompany ? [companies.selectedCompany.id] : null
                })
            ]);
        } catch (error) {
            if (error.name === 'AbortError') {
                setGetUserIdsError(null);
                setUserIds(null);
                return;
            }
            setGetUserIdsError(error);
            setUserIds(null);
            return;
        }

        let cohortUserIds = results[0];

        let activityUserIds = cohortUserIds;
        if (activity.key.getTime() !== cohort.startDate.getTime()) {
            activityUserIds = results[1].filter((userId) => cohortUserIds.includes(userId));
        }

        activityUserIds.sort();

        setGetUserIdsError(null);
        setUserIds(activityUserIds);
    };

    const stripPartialData = (cohorts: Cohort[]) => {
        let newData: Cohort[] = [];

        for (let i = 0; i < cohorts.length - 1; i++) {
            let cohort = { ...cohorts[i] };
            cohort.activity = cohorts[i].activity.slice(0, -1);
            newData.push(cohort);
        }

        return newData;
    };

    const togglePartial = () => {
        if (!data) return;

        if (partial) {
            setDisplayData(stripPartialData(data));
        } else {
            setDisplayData(data);
        }
        setPartial(!partial);
    };

    const toPercentage = (cohorts: Cohort[]) => {
        let newData: Cohort[] = [];

        for (let i = 0; i < cohorts.length; i++) {
            let cohort = { ...cohorts[i] };
            const peak = Math.max(...cohort.activity.map((kvp) => kvp.value));
            cohort.activity = cohort.activity.map((activity) => ({
                key: activity.key,
                value: activity.value > 0 ? (activity.value / peak) * 100 : 0
            }));
            newData.push(cohort);
        }

        return newData;
    };

    const togglePercentage = () => {
        if (!data) return;

        if (percentage) {
            setDisplayData(data);
        } else {
            setDisplayData(toPercentage(data));
        }
        setPercentage(!percentage);
    };

    const handleDialogClose = () => {
        abortController.abort();
        setAbortController(new AbortController());
        setOpen(false);
        setGetUserIdsError(null);
        setUserIds(null);
    };

    useEffect(() => {
        if (!data) return;

        let newData = data;

        if (!partial) {
            newData = stripPartialData(newData);
        }

        if (percentage) {
            newData = toPercentage(newData);
        }

        setDisplayData(newData);
    }, [data, partial, percentage]);

    if (error) {
        return (
            <ErrorMessage>
                <pre>{JSON.stringify(error, null, 2)}</pre>
            </ErrorMessage>
        );
    }

    if (loading || !displayData || !data) {
        return <CenteredProgress />;
    }

    return (
        <>
            <Box p={2}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={!partial} onChange={togglePartial} />}
                            label="Hide Partial Data"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={percentage} onChange={togglePercentage} />}
                            label="Percentage"
                        />
                    </Grid>
                </Grid>
            </Box>
            <HeatMap data={displayData} onCellClick={onCellClick} />
            <HeatMapDialog userIds={userIds} open={open} error={getUserIdsError} onClose={handleDialogClose} />
        </>
    );
};

const mapStateToProps = (state: StoreState) => ({
    companies: getCompanies(state)
});

export default connect(mapStateToProps)(HeatMapContainer);
