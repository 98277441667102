import { Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { User } from 'oidc-client';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPermissions } from '../redux/reducers/permissionsReducer';
import { PermissionsState } from '../types/Permissions';
import { StoreState } from '../types/Store';
import AccountButton from './Controls/AccountButton';
import CompanyDrawer from './Controls/CompanyDrawer';

interface NavbarProps {
    user: User | undefined;
    permissions: PermissionsState;
}

const Navbar: React.FC<NavbarProps> = ({ user, permissions }) => {
    const isSuperAdmin = permissions.isSuperAdmin;
    const displayCompanyDrawer = isSuperAdmin || permissions.companyIds.length > 1;

    // Position from the left as otherwise the nav area moves when the drawer is opened
    let left = 'calc(100vw - 446px)';
    if (!isSuperAdmin) {
        left = 'calc(100vw - 244px)';
    }

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            titleContainer: {
                marginBottom: '5px'
            },
            title: {
                fontFamily: 'Roboto',
                fontSize: '36px',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                color: '#49a9fc'
            },
            nav: {
                zIndex: theme.zIndex.appBar,
                position: 'absolute',
                [theme.breakpoints.down('sm')]: {
                    position: 'relative'
                },
                top: '35px',
                left
            },
            navContent: {
                height: '48px'
            }
        })
    );

    const classes = useStyles();

    if (!user) {
        return null;
    }

    return (
        <Grid container className={classes.titleContainer} justify="space-between" alignItems="center">
            <Grid item className={classes.nav}>
                <Grid container className={classes.navContent} justify="space-between" alignItems="center">
                    <Grid item>
                        <Button component={Link} to="/">
                            Home
                        </Button>
                    </Grid>
                    {isSuperAdmin && (
                        <Grid item>
                            <Button component={Link} to="/feed">
                                Activity Feed
                            </Button>
                        </Grid>
                    )}
                    {isSuperAdmin && (
                        <Grid item>
                            <Button component={Link} to="/cohorts">
                                Cohorts
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <AccountButton />
                    </Grid>
                    {displayCompanyDrawer && (
                        <Grid item>
                            <CompanyDrawer />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

function mapStateToProps(state: StoreState) {
    return {
        user: state.oidc.user,
        permissions: getPermissions(state)
    };
}

export default connect(mapStateToProps)(Navbar);
