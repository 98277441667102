import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import {
    DOCUMENT_MIMETYPES,
    IMAGE_MIMETYPES,
    MessagesSentMetric,
    MessageType,
    TimeInterval,
    VIDEO_MIMETYPES
} from '../../types/AnalyticsApi';
import { CommonDataProviderOptions } from '../../types/DataProvider';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';
import DashboardDataProvider from '../DataProviders/DashboardDataProvider';
import FilesDataProvider from '../DataProviders/FilesDataProvider';
import MessagesDataProvider from '../DataProviders/MessagesDataProvider';
import UsersDataProvider from '../DataProviders/UsersDataProvider';
import LineChartContainer from '../LineChart/LineChartContainer';
import ChartSectionContainer from '../Section/ChartSectionContainer';
import SectionChart from '../Section/SectionChart';
import SectionHeader from '../Section/SectionHeader';
import DashboardConversationsChart from './DashboardConversationsChart';
import DashboardRollingAUChart from './DashboardRollingAUChart';

interface DashboardDataProviderOptions extends CommonDataProviderOptions {
    interval: TimeInterval;
}

interface DashboardLineChartsProps {
    countryCode: string[] | null;
    from: string | null;
    to: string | null;
    interval: TimeInterval;
    companyId: string[] | null;
    departmentId: string[] | null;
    page?: number;
    permissions: PermissionsState;
}

const DashboardLineCharts: React.FC<DashboardLineChartsProps> = (props) => {
    let { page, children, ...opts } = props;
    let options: DashboardDataProviderOptions = opts;

    let isSuperAdmin = props.permissions.isSuperAdmin;

    const deltaFromDate = moment(options.from).subtract(1, options.interval).toISOString();

    if (props.page === 1) {
        return (
            <React.Fragment>
                {isSuperAdmin && (
                    <>
                        <ChartSectionContainer>
                            <SectionHeader>Documents Created</SectionHeader>
                            <SectionChart>
                                <FilesDataProvider
                                    options={{
                                        ...props,
                                        mimeType: DOCUMENT_MIMETYPES
                                    }}
                                >
                                    <LineChartContainer
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 75,
                                            bottom: 40
                                        }}
                                        colors={['#f4ff81']}
                                    />
                                </FilesDataProvider>
                            </SectionChart>
                        </ChartSectionContainer>

                        <ChartSectionContainer>
                            <SectionHeader>Documents Sent</SectionHeader>
                            <SectionChart>
                                <MessagesDataProvider
                                    options={{
                                        ...props,
                                        type: [MessageType.PatientFile]
                                    }}
                                >
                                    <LineChartContainer
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 75,
                                            bottom: 40
                                        }}
                                        colors={['#ff8a80']}
                                    />
                                </MessagesDataProvider>
                            </SectionChart>
                        </ChartSectionContainer>
                    </>
                )}

                <ChartSectionContainer>
                    <SectionHeader>Documents Exchanged</SectionHeader>
                    <SectionChart>
                        <MessagesDataProvider
                            options={{
                                ...props,
                                type: [MessageType.PatientFile],
                                metric: MessagesSentMetric.SentTo
                            }}
                        >
                            <LineChartContainer
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 75,
                                    bottom: 40
                                }}
                                colors={['#5cefff']}
                            />
                        </MessagesDataProvider>
                    </SectionChart>
                </ChartSectionContainer>

                {isSuperAdmin && (
                    <ChartSectionContainer>
                        <SectionHeader>Dashboard Users</SectionHeader>
                        <SectionChart>
                            <DashboardDataProvider
                                options={{
                                    ...props
                                }}
                            >
                                <LineChartContainer
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 75,
                                        bottom: 40
                                    }}
                                    colors={['#5cefff']}
                                />
                            </DashboardDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>
                )}
            </React.Fragment>
        );
    }

    if (props.page === 2) {
        return (
            <React.Fragment>
                <ChartSectionContainer>
                    <SectionHeader>Total Users</SectionHeader>
                    <SectionChart>
                        <UsersDataProvider options={options} throttleMilliseconds={5000}>
                            <LineChartContainer
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 75,
                                    bottom: 40
                                }}
                                colors={['#f4ff81']}
                            />
                        </UsersDataProvider>
                    </SectionChart>
                </ChartSectionContainer>

                {isSuperAdmin && (
                    <>
                        <ChartSectionContainer>
                            <SectionHeader>Δ Total Users</SectionHeader>
                            <SectionChart>
                                <UsersDataProvider
                                    options={{
                                        ...options,
                                        from: deltaFromDate
                                    }}
                                    throttleMilliseconds={5000}
                                >
                                    <LineChartContainer
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 75,
                                            bottom: 40
                                        }}
                                        colors={['#f4ff81']}
                                        diverging
                                    />
                                </UsersDataProvider>
                            </SectionChart>
                        </ChartSectionContainer>

                        <DashboardRollingAUChart {...options} />

                        <ChartSectionContainer>
                            <SectionHeader>DAU/MAU</SectionHeader>
                            <SectionChart>
                                <UsersDataProvider
                                    options={{
                                        ...options,
                                        interval: 'M',
                                        numeratorInterval: 'd',
                                        rolling: true,
                                        percent: true
                                    }}
                                    active={true}
                                    throttleMilliseconds={5000}
                                >
                                    <LineChartContainer
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 75,
                                            bottom: 40
                                        }}
                                        tickValue={10}
                                        tickFormat={'%-d/%m/%Y'}
                                        tooltipDateFormat={'dddd d/mm/yyyy'}
                                        colors={['#f4ff81']}
                                        decimalFractionAsPercentage
                                    />
                                </UsersDataProvider>
                            </SectionChart>
                        </ChartSectionContainer>

                        <ChartSectionContainer>
                            <SectionHeader>WAU/MAU</SectionHeader>
                            <SectionChart>
                                <UsersDataProvider
                                    options={{
                                        ...options,
                                        interval: 'M',
                                        numeratorInterval: 'w',
                                        rolling: true,
                                        percent: true
                                    }}
                                    active={true}
                                    throttleMilliseconds={5000}
                                >
                                    <LineChartContainer
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 75,
                                            bottom: 40
                                        }}
                                        tickValue={10}
                                        tickFormat={'%-d/%m/%Y'}
                                        tooltipDateFormat={'dddd d/mm/yyyy'}
                                        colors={['#f4ff81']}
                                        decimalFractionAsPercentage
                                    />
                                </UsersDataProvider>
                            </SectionChart>
                        </ChartSectionContainer>

                        <DashboardConversationsChart {...options} total />
                        <DashboardConversationsChart {...{ ...options, from: deltaFromDate }} />
                    </>
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {isSuperAdmin && (
                <ChartSectionContainer>
                    <SectionHeader>Messages Sent</SectionHeader>
                    <SectionChart>
                        <MessagesDataProvider
                            options={{
                                ...props,
                                type: [MessageType.NULL]
                            }}
                        >
                            <LineChartContainer
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 75,
                                    bottom: 40
                                }}
                                colors={['#5cefff']}
                            />
                        </MessagesDataProvider>
                    </SectionChart>
                </ChartSectionContainer>
            )}

            <ChartSectionContainer>
                <SectionHeader>Messages Exchanged</SectionHeader>
                <SectionChart>
                    <MessagesDataProvider
                        options={{
                            ...props,
                            type: [MessageType.NULL],
                            metric: MessagesSentMetric.SentTo
                        }}
                    >
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            colors={['#5cefff']}
                        />
                    </MessagesDataProvider>
                </SectionChart>
            </ChartSectionContainer>

            {isSuperAdmin && (
                <>
                    <ChartSectionContainer>
                        <SectionHeader>Photos Captured</SectionHeader>
                        <SectionChart>
                            <FilesDataProvider
                                options={{
                                    ...props,
                                    mimeType: IMAGE_MIMETYPES
                                }}
                            >
                                <LineChartContainer
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 75,
                                        bottom: 40
                                    }}
                                    colors={['#ffeda5']}
                                />
                            </FilesDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>

                    <ChartSectionContainer>
                        <SectionHeader>Photos Sent</SectionHeader>
                        <SectionChart>
                            <MessagesDataProvider
                                options={{
                                    ...props,
                                    type: [MessageType.Photo]
                                }}
                            >
                                <LineChartContainer
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 75,
                                        bottom: 40
                                    }}
                                    colors={['#d9a5ff']}
                                />
                            </MessagesDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>
                </>
            )}

            <ChartSectionContainer>
                <SectionHeader>Photos Exchanged</SectionHeader>
                <SectionChart>
                    <MessagesDataProvider
                        options={{
                            ...props,
                            type: [MessageType.Photo],
                            metric: MessagesSentMetric.SentTo
                        }}
                    >
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            colors={['#5cefff']}
                        />
                    </MessagesDataProvider>
                </SectionChart>
            </ChartSectionContainer>

            {isSuperAdmin && (
                <>
                    <ChartSectionContainer>
                        <SectionHeader>Videos Captured</SectionHeader>
                        <SectionChart>
                            <FilesDataProvider
                                options={{
                                    ...props,
                                    mimeType: VIDEO_MIMETYPES
                                }}
                            >
                                <LineChartContainer
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 75,
                                        bottom: 40
                                    }}
                                    colors={['#ffeda5']}
                                />
                            </FilesDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>
                    <ChartSectionContainer>
                        <SectionHeader>Videos Sent</SectionHeader>
                        <SectionChart>
                            <MessagesDataProvider
                                options={{
                                    ...props,
                                    type: [MessageType.Video]
                                }}
                            >
                                <LineChartContainer
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 75,
                                        bottom: 40
                                    }}
                                    colors={['#d9a5ff']}
                                />
                            </MessagesDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>
                </>
            )}

            <ChartSectionContainer>
                <SectionHeader>Videos Exchanged</SectionHeader>
                <SectionChart>
                    <MessagesDataProvider
                        options={{
                            ...props,
                            type: [MessageType.Video],
                            metric: MessagesSentMetric.SentTo
                        }}
                    >
                        <LineChartContainer
                            margin={{
                                top: 10,
                                right: 30,
                                left: 75,
                                bottom: 40
                            }}
                            colors={['#5cefff']}
                        />
                    </MessagesDataProvider>
                </SectionChart>
            </ChartSectionContainer>
        </React.Fragment>
    );
};

DashboardLineCharts.defaultProps = {
    page: 0
};

function mapStateToProps(state: StoreState) {
    return {
        permissions: getPermissions(state)
    };
}

export default connect(mapStateToProps)(DashboardLineCharts);
