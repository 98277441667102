import { Grid } from '@material-ui/core';
import React from 'react';
import { InviteMetric, InviteType, Referrer } from '../../types/AnalyticsApi';
import ConnectionsDataProvider from '../DataProviders/ConnectionsDataProvider';
import InvitesDataProvider from '../DataProviders/InvitesDataProvider';
import UsersDataProvider from '../DataProviders/UsersDataProvider';
import MetricContainer from '../Metric/MetricContainer';
import Panel from '../Panel/Panel';
import PanelContent from '../Panel/PanelContent';
import PanelTitle from '../Panel/PanelTitle';

interface DashboardInvitesMetricsProps {
    countryCode: string[] | null;
    from: string | null;
    to: string | null;
    companyId: string[] | null;
    departmentId: string[] | null;
}

const DashboardInvitesMetrics: React.FC<DashboardInvitesMetricsProps> = (props) => {
    return (
        <React.Fragment>
            <Grid container item spacing={3} justify="space-between">
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of email invites sent within the selected time range'}>
                            <PanelTitle>Email Invites Sent</PanelTitle>
                            <PanelContent>
                                <InvitesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: InviteType.Email
                                    }}
                                >
                                    <MetricContainer />
                                </InvitesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of email invites sent to existing users within the selected time range'
                            }
                        >
                            <PanelTitle>Existing User Email Invites Sent</PanelTitle>
                            <PanelContent>
                                <InvitesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        isExistingUser: true,
                                        type: InviteType.Email
                                    }}
                                >
                                    <MetricContainer />
                                </InvitesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={'Number of email invites sent to new users within the selected time range'}
                        >
                            <PanelTitle>New User Email Invites Sent</PanelTitle>
                            <PanelContent>
                                <InvitesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        isExistingUser: false,
                                        type: InviteType.Email
                                    }}
                                >
                                    <MetricContainer />
                                </InvitesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={'Number of users onboarded via an email invite within 7 days of the invite being sent within the selected time range'}
                        >
                            <PanelTitle>Users Onboarded Via Email Invite</PanelTitle>
                            <PanelContent>
                                <UsersDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        referrer: Referrer.Email,
                                        acceptedInviteWithinSeconds: 86400 * 7
                                    }}
                                >
                                    <MetricContainer />
                                </UsersDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of users who onboarded via an email invite that connected with the person who invited them within 24 hours within the selected time range'
                            }
                        >
                            <PanelTitle>Users Onboarded Via Email Invite That Connected</PanelTitle>
                            <PanelContent>
                                <ConnectionsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        connectionType: ['Accepted'],
                                        timeSinceInviteeJoinedSeconds: 86400, // 24 hours
                                        cardinality: true,
                                        inviteeReferrer: Referrer.Email
                                    }}
                                >
                                    <MetricContainer />
                                </ConnectionsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>

                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Percentage of invites that resulted in a user onboarding within 7 days within the selected time range'}>
                            <PanelTitle>Percentage Of Invites That Resulted In A User Onboarding</PanelTitle>
                            <PanelContent>
                                <InvitesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        inviteeAcceptedInviteWithinSeconds: 86400 * 7, // 7 days
                                        metric: InviteMetric.PercentageOnboarded
                                    }}
                                >
                                    <MetricContainer percentage />
                                </InvitesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default DashboardInvitesMetrics;
