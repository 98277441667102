import React from 'react';
import { GET_TOTAL_IDENTITIES_VERIFIED_ENDPOINT } from '../../types/AnalyticsApi';
import CrossSectionalData from '../../types/CrossSectionalData';
import { IdentitiesOptions, DataProviderOptions } from '../../types/DataProvider';
import DataProvider, { OptionalDataProviderProps } from './DataProvider';

interface IdentitiesDataProviderProps extends OptionalDataProviderProps {
    children: any;
    options?: IdentitiesOptions;
    crossSection?: boolean;
}
const IdentitiesDataProvider: React.FC<IdentitiesDataProviderProps> = ({
    children,
    options,
    crossSection,
    ...other
}) => {
    const crossSectionalResponseHandler = (res: any, options: DataProviderOptions): CrossSectionalData => {
        let crossSectionalData: CrossSectionalData = [];

        crossSectionalData.push({
            key: 'all',
            value: res.data
        });

        return crossSectionalData;
    };

    return (
        <DataProvider
            dataFormat={'CROSS_SECTION'}
            src={GET_TOTAL_IDENTITIES_VERIFIED_ENDPOINT}
            options={options}
            responseHandler={crossSectionalResponseHandler}
            updateOnEventStrings={['IDENTITY_VERIFIED', 'IDENTITY_UNVERIFIED']}
            {...other}
        >
            {children}
        </DataProvider>
    );
};

export default IdentitiesDataProvider;
