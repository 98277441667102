import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import matchSorter from 'match-sorter';
import React, { ChangeEvent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputRoot: {
            height: '38px',
            width: '239px',
            padding: '0 0 0 6px !important'
        }
    })
);

export interface FilterSelectOption<T> {
    displayName: string;
    value: T;
}

export interface FilterSelectProps<T> {
    className?: any;
    label?: string;
    value: FilterSelectOption<T> | null;
    options: FilterSelectOption<T>[];
    setFilter: (value: T | null) => void;
}

const FilterSelect = <T,>(props: FilterSelectProps<T>) => {
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<{}>, option: FilterSelectOption<T> | null) => {
        props.setFilter(option === null ? null : option.value);
    };

    const filterOptions = (options: FilterSelectOption<T>[], { inputValue }: { inputValue: string }) => {
        return matchSorter(options, inputValue, {
            keys: ['displayName']
        });
    };

    return (
        <Autocomplete
            className={props.className}
            options={props.options}
            getOptionLabel={(option) => option.displayName}
            onChange={handleChange}
            multiple={false}
            autoSelect={false}
            value={props.value}
            filterOptions={filterOptions}
            classes={{
                inputRoot: classes.inputRoot
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={props.label}
                    placeholder="All"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            )}
        />
    );
};

export default FilterSelect;
