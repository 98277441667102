import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';
import CountrySelect from './CountrySelect';
import IdentityVerificationStatusSelect from './IdentityVerificationStatusSelect';
import ProfessionVerificationStatusSelect from './ProfessionVerificationStatusSelect';
import WorkplaceVerificationStatusSelect from './WorkplaceVerificationStatusSelect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlsContainer: {
            margin: '15px 0 5px 0',
            '& $control': {
                paddingLeft: 0,
                paddingRight: '16px'
            }
        },
        control: {}
    })
);

interface GlobalControlsProps {
    permissions: PermissionsState;
}

/**
 * Controls which modify global state.
 */
const GlobalControls: React.FC<GlobalControlsProps> = ({ permissions }) => {
    const classes = useStyles();

    let displayControls = permissions.isSuperAdmin || permissions.companyIds.length > 1;
    if (!displayControls) {
        // Don't render anything
        return null;
    }

    return (
        <Grid container className={classes.controlsContainer} spacing={2}>
            <Grid item className={classes.control}>
                <ProfessionVerificationStatusSelect />
            </Grid>

            <Grid item className={classes.control}>
                <IdentityVerificationStatusSelect />
            </Grid>

            <Grid item className={classes.control}>
                <WorkplaceVerificationStatusSelect />
            </Grid>

            <Grid item className={classes.control}>
                <CountrySelect />
            </Grid>
        </Grid>
    );
};

function mapStateToProps(state: StoreState) {
    return {
        permissions: getPermissions(state)
    };
}

export default connect(mapStateToProps)(GlobalControls);
