import React from 'react';
import { GET_INVITES_ENDPOINT, GET_TOTAL_INVITES_ENDPOINT } from '../../types/AnalyticsApi';
import CrossSectionalData from '../../types/CrossSectionalData';
import { DataProviderOptions, InvitesOptions } from '../../types/DataProvider';
import TimeSeriesData from '../../types/TimeseriesData';
import { getCompanyOrDepartmentName } from '../../utils/getCompanyOrDepartmentName';
import DataProvider, { OptionalDataProviderProps } from './DataProvider';

interface InvitesDataProviderProps extends OptionalDataProviderProps {
    children: any;
    options?: InvitesOptions;
    crossSection?: boolean;
}

const timeSeriesResponseHandler = (res: any, options: DataProviderOptions): TimeSeriesData => {
    let timeSeriesData: TimeSeriesData = [];

    for (let item of res.data) {
        if (!(item.value instanceof Array)) {
            timeSeriesData.push({
                key: item.key,
                value: [
                    {
                        key: 'All',
                        value: item.value
                    }
                ]
            });
        } else {
            // Replace IDs with their corresponding name if possible
            for (let subItem of item.value) {
                subItem.key = getCompanyOrDepartmentName(subItem.key);
            }

            timeSeriesData.push(item);
        }
    }

    return timeSeriesData;
};

const crossSectionalResponseHandler = (res: any, options: DataProviderOptions): CrossSectionalData => {
    let crossSectionalData: CrossSectionalData = [];
    let totals: {
        [id: string]: number;
    } = {};

    if (!options.groupBy) {
        crossSectionalData.push({
            key: 'all',
            value: res.data
        });
    } else {
        // Accumulate the value of the groups from the response
        for (let item of res.data) {
            totals[item.key] = item.value;
        }

        for (let key in totals) {
            let name = getCompanyOrDepartmentName(key);

            crossSectionalData.push({
                key: name,
                value: totals[key]
            });
        }
    }

    return crossSectionalData;
};

const InvitesDataProvider: React.FC<InvitesDataProviderProps> = ({ children, options, crossSection, ...other }) => {
    return (
        <DataProvider
            dataFormat={crossSection ? 'CROSS_SECTION' : 'TIME_SERIES'}
            src={crossSection ? GET_TOTAL_INVITES_ENDPOINT : GET_INVITES_ENDPOINT}
            options={options}
            responseHandler={crossSection ? crossSectionalResponseHandler : timeSeriesResponseHandler}
            updateOnEventStrings={['INVITE_SENT']}
            {...other}
        >
            {children}
        </DataProvider>
    );
};

export default InvitesDataProvider;
