import { HandledData } from '../../types/CompositeDataProvider';
import { UsersOptions } from '../../types/DataProvider';
import {
    ChartOptionsKey, ChartOptionsKeyMappings, PageOptionsKey, PageOptionsKeyMappings,



    POST_REPORT_ENDPOINT, ReportOptions,

    ReportOptionsKey, ReportOptionsKeyMappings
} from '../../types/ReportsApi';
import { getCompanyOrDepartmentName } from '../../utils/getCompanyOrDepartmentName';

const dateFormat = require('dateformat');

export const createRequestBody = (reportOptions: ReportOptions) => {
    let options: any = {};

    // Map names for report options
    for (let key in reportOptions) {
        let mappedKey: any = ReportOptionsKeyMappings[key as ReportOptionsKey];

        let isArray = Array.isArray(reportOptions[key as ReportOptionsKey]);
        options[mappedKey] = isArray ? [] : reportOptions[key as ReportOptionsKey];
    }

    // Map names for page options and chart options
    for (let i = 0; i < reportOptions.pages.length; i++) {
        let page = reportOptions.pages[i];
        let optionsPage: any = {};
        options.pages.push(optionsPage);

        // Copy page options
        for (let key in page) {
            let mappedKey = PageOptionsKeyMappings[key as PageOptionsKey];
            let isArray = Array.isArray(page[key as PageOptionsKey]);
            optionsPage[mappedKey] = isArray ? [] : page[key as PageOptionsKey];
        }

        // Map names for chart options
        for (let j = 0; j < page.charts.length; j++) {
            let chart = page.charts[j];
            let optionsChart: any = {};
            optionsPage.charts.push(optionsChart);

            // Copy chart options
            for (let key in chart) {
                let mappedKey = ChartOptionsKeyMappings[key as ChartOptionsKey];
                optionsChart[mappedKey] = chart[key as ChartOptionsKey];
            }
        }
    }

    return JSON.stringify(options);
};

export const generateReport = async (reportOptions: ReportOptions, abortController: AbortController) => {
    let fetchOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: createRequestBody(reportOptions),
        signal: abortController.signal
    };

    let res = await fetch(POST_REPORT_ENDPOINT, fetchOptions);

    if (!res.ok) {
        throw new Error(`Error generating report`);
    }

    return await res.blob();
};

export const formatKeys = (handledData: HandledData[]) => {
    let dateKeyFormat = '';
    for (let elem of handledData) {
        let data = elem.data;

        if ((elem.options as UsersOptions).rolling) {
            // Rolling stats are always calculated per day
            dateKeyFormat = 'd/mm/yyyy';
        } else {
            switch (elem.options.interval) {
                case 'h':
                    // For hours there are cases where we may display the same hours on two days (the current and
                    // the previous day so we need to include the day so these values can be distinguished
                    dateKeyFormat = 'hTT';
                    break;
                case 'd':
                    dateKeyFormat = 'd mmm';
                    break;
                case 'w':
                    dateKeyFormat = 'd mmm';
                    break;
                case 'M':
                    dateKeyFormat = 'mmm yyyy';
                    break;
                case 'y':
                    dateKeyFormat = 'yyyy';
                    break;
            }
        }

        // Format dates
        for (let dataPoint of data) {
            dataPoint.key = dateFormat(dataPoint.key, dateKeyFormat);

            // Replace ids with their corresponding name
            if (Array.isArray(dataPoint.value)) {
                for (let subDataPoint of dataPoint.value) {
                    subDataPoint.key = getCompanyOrDepartmentName(subDataPoint.key);
                }
            }
        }
    }
};

export const getMappedData = (handledData: HandledData[]) => {
    let mappedData: {
        [x: string]: any;
    } = {};

    for (let item of handledData) {
        mappedData[item.id] = item.data;
    }

    return mappedData;
};