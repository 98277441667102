import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { getCompanies } from '../redux/reducers/companiesReducer';
import { CompaniesState } from '../types/Companies';
import { StoreState } from '../types/Store';
import GlobalControls from './Controls/GlobalControls';
import PageClock from './PageClock';
import PageHeader from './PageHeader';

interface ILayoutProps {
    children: React.ReactNode;
    companies: CompaniesState;
    suffix?: string;
}

const Layout: React.FC<ILayoutProps> = ({ children, companies, suffix }) => {
    return (
        <Container maxWidth="xl">
            <Box px={'50px'} pt={'20px'} pb={0}>
                <Grid container direction="column">
                    <Grid item>
                        <PageHeader>
                            {companies.selectedCompany ? companies.selectedCompany.name : 'Celo'} {' ' + suffix!}
                        </PageHeader>
                    </Grid>

                    <Grid item>
                        <PageClock />
                    </Grid>

                    <Grid item>
                        <GlobalControls />
                    </Grid>

                    <Grid item>{children}</Grid>
                </Grid>
            </Box>
        </Container>
    );
};

Layout.defaultProps = {
    suffix: 'Dashboard'
};

const mapStateToProps = (state: StoreState) => ({
    companies: getCompanies(state)
});

export default connect(mapStateToProps)(Layout);
