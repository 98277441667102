import { GET_ACTIVE_USERS_ENDPOINT } from '../../../types/AnalyticsApi';
import { DataPoint, HandledData, Source, TimeSeriesDataPoint } from '../../../types/CompositeDataProvider';
import { UsersOptions } from '../../../types/DataProvider';
import { formatKeys, getMappedData } from '../lib';
import ChartBase, { ChartOptions } from './ChartBase';

class RollingActiveUsersChart extends ChartBase {
    private static DAU = 'DAU';
    private static WAU = 'WAU';
    private static MAU = 'MAU';

    private usersOptions: UsersOptions;

    constructor(filters: ChartOptions, usersOptions: UsersOptions) {
        super(filters);
        this.usersOptions = usersOptions;
        this.setTitle('Active Users (Rolling)');
        this.setLegends(true);
        this.setValueLabels(false);
    }

    protected getSources(): Promise<Source[]> {
        let options = {
            ...this.filters,
            ...this.usersOptions
        };
        let sources: Source[] = [
            {
                id: RollingActiveUsersChart.DAU,
                src: GET_ACTIVE_USERS_ENDPOINT,
                options: {
                    ...options,
                    interval: 'd',
                    rolling: true
                },
                handler: (res) => res.data
            },
            {
                id: RollingActiveUsersChart.WAU,
                src: GET_ACTIVE_USERS_ENDPOINT,
                options: {
                    ...options,
                    interval: 'w',
                    rolling: true
                },
                handler: (res) => res.data
            },
            {
                id: RollingActiveUsersChart.MAU,
                src: GET_ACTIVE_USERS_ENDPOINT,
                options: {
                    ...options,
                    interval: 'M',
                    rolling: true
                },
                handler: (res) => res.data
            }
        ];
        return Promise.resolve(sources);
    }

    protected parseRespose(data: HandledData[]): Promise<TimeSeriesDataPoint[]> {
        formatKeys(data);
        let mappedData = getMappedData(data);
        let mergedData = this.mergeActiveUsersData(
            mappedData[RollingActiveUsersChart.DAU],
            mappedData[RollingActiveUsersChart.WAU],
            mappedData[RollingActiveUsersChart.MAU]
        );
        return Promise.resolve(mergedData);
    }

    private mergeActiveUsersData(dau: TimeSeriesDataPoint[], wau: TimeSeriesDataPoint[], mau: TimeSeriesDataPoint[]) {
        let mergedData: TimeSeriesDataPoint[] = [];
        for (let i = 0; i < dau.length; i++) {
            let subgroups: DataPoint[] = [];
            let dataPoint: TimeSeriesDataPoint = {
                key: dau[i].key,
                value: subgroups
            };

            subgroups.push({ key: RollingActiveUsersChart.DAU, value: dau[i].value as number });
            subgroups.push({ key: RollingActiveUsersChart.WAU, value: wau[i].value as number });
            subgroups.push({ key: RollingActiveUsersChart.MAU, value: mau[i].value as number });

            mergedData.push(dataPoint);
        }
        return mergedData;
    }
}

export default RollingActiveUsersChart;
