import { CountryState, COUNTRY_SELECTION, SelectCountryAction } from '../../types/Countries';
import { StoreState } from '../../types/Store';

const initialState: CountryState = {
    selectedCountries: null
};

const countryReducer = (state = initialState, action: SelectCountryAction): CountryState => {
    switch (action.type) {
        case COUNTRY_SELECTION:
            return {
                selectedCountries: action.countries
            };
        default:
            return state;
    }
};

export default countryReducer;

export const getSelectedCountries = (state: StoreState) => state.country.selectedCountries;
