import { createUserManager } from 'redux-oidc';
import { UserManagerSettings } from 'oidc-client';

const userManagerConfig: UserManagerSettings = {
    authority: process.env.REACT_APP_AUTHORITY,
    redirect_uri: `${window.origin}/signin-oidc`,
    silent_redirect_uri: `${window.origin}/silent-renew.html`,
    post_logout_redirect_uri: `${window.origin}/signout-callback-oidc`,
    client_id: process.env.REACT_APP_CLIENT_ID,
    scope: 'openid celo_analytics_api',
    response_type: 'code',
    automaticSilentRenew: true
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
