import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterIdentityVerificationStatus } from '../../redux/actions/filterActions';
import { getIdentityVerificationStatus } from '../../redux/reducers/filtersReducer';
import { IdentityVerificationStatus } from '../../types/AnalyticsApi';
import FilterSelect, { FilterSelectOption } from './FilterSelect';

const options: FilterSelectOption<IdentityVerificationStatus>[] = [
    {
        displayName: 'Unverified',
        value: IdentityVerificationStatus.Unverified
    },
    {
        displayName: 'Verified',
        value: IdentityVerificationStatus.Verified
    }
];

interface IdentityVerificationStatusSelectProps {
    className?: any;
    identityVerificationStatus: IdentityVerificationStatus | null;
    filterIdentityVerificationStatus: (identityVerificationStatus: IdentityVerificationStatus | null) => void;
}

const IdentityVerificationStatusSelect: React.FC<IdentityVerificationStatusSelectProps> = ({
    className,
    identityVerificationStatus,
    filterIdentityVerificationStatus
}) => {
    let value = options.find((option) => option.value === identityVerificationStatus) || null;
    return (
        <FilterSelect
            label="Identity Verification Status"
            className={className}
            value={value}
            options={options}
            setFilter={filterIdentityVerificationStatus}
        />
    );
};

const mapStateToProps = (state: any) => ({
    identityVerificationStatus: getIdentityVerificationStatus(state)
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            filterIdentityVerificationStatus: filterIdentityVerificationStatus
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentityVerificationStatusSelect);
