import React from 'react';
import Center from '../Center';
import useStyles from './styles';

interface SectionChartProps {
    children: React.ReactNode;
}

/**
 * Container for a chart in a section (e.g. a barchart).
 */
const SectionChart: React.FC<SectionChartProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.sectionChartContainer}>
            <Center>{children}</Center>
        </div>
    );
};

export default SectionChart;
