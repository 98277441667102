import { createStyles, makeStyles, Paper, Theme, Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';

interface PanelProps {
    children?: React.ReactNode;
    tooltipProps?: Omit<TooltipProps, 'children'>;
    tooltipTitle?: TooltipProps['title'];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            padding: '15px 30px',
            borderRadius: '10px',
            backgroundColor: '#111111',
            minWidth: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.up('sm')]: {
                minHeight: '156px',
                height: '100%'
            },
            [theme.breakpoints.up('md')]: {
                minHeight: '135px'
            }
        }
    })
);

const Panel: React.FC<PanelProps> = ({ tooltipProps, tooltipTitle, children }) => {
    const classes = useStyles();

    let content = <Paper className={classes.panel}>{children}</Paper>;

    if (tooltipTitle) {
        if (tooltipProps) {
            tooltipProps = { ...tooltipProps, title: tooltipTitle };
        } else {
            tooltipProps = { title: tooltipTitle };
        }
    }

    if (tooltipProps) {
        return <Tooltip {...tooltipProps}>{content}</Tooltip>;
    }
    return content;
};

export default Panel;
