import React from 'react';
import CrossSectionalData from '../../types/CrossSectionalData';
import TimeSeriesData from '../../types/TimeseriesData';
import ChartContainer, { ChartProps } from '../ChartContainer';
import BarChart from './BarChart';

export interface BarChartContainerProps extends ChartProps {
    data?: TimeSeriesData | CrossSectionalData;
    mode?: 'stacked' | 'grouped';
    diverging?: boolean;
    crossSection?: boolean;
}

const BarChartContainer: React.FC<BarChartContainerProps> = (props) => {
    const { data, diverging, crossSection } = props;

    return (
        <ChartContainer {...props}>
            {data && <BarChart data={data} diverging={diverging!} crossSection={crossSection!} />}
        </ChartContainer>
    );
};

BarChartContainer.defaultProps = {
    mode: 'stacked',
    xLegend: '',
    yLegend: '',
    diverging: false,
    crossSection: false
};

export default BarChartContainer;
