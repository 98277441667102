import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, List, ListItem } from '@material-ui/core';
import React from 'react';
import { CreateUserProfileUrl } from '../../types/SuperAdmin';
import CenteredProgress from '../CenteredProgress';
import ErrorMessage from '../ErrorMessage';

export interface HeatMapDialogProps {
    userIds?: string[] | null;
    error?: Error | null;
    open: boolean;
    onClose: () => void;
}

const HeatMapDialog: React.FC<HeatMapDialogProps> = ({ userIds, error, open, onClose }) => {
    if (!open) return null;

    let content = <CenteredProgress />;

    if (error) {
        content = (
            <ErrorMessage>
                <pre>{error.toString()}</pre>
            </ErrorMessage>
        );
    } else if (userIds) {
        content = (
            <List style={{ maxHeight: 'min(80vh, 300px)' }}>
                {userIds?.map((userId) => (
                    <ListItem key={userId}>
                        <Link color="textPrimary" href={CreateUserProfileUrl(userId.toLowerCase())}>
                            {userId.toLowerCase()}
                        </Link>
                    </ListItem>
                ))}
            </List>
        );
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>UserIds</DialogTitle>
            <DialogContent style={{ minWidth: '370px', padding: '16px 32px' }}>{content}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HeatMapDialog;
