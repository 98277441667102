import moment from 'moment-timezone';
import store from '../../redux/store';
import { GET_COHORTS_ENDPOINT } from '../../types/AnalyticsApi';
import { Cohort } from '../../types/Cohort';
import { CrossSectionDataPoint, HandledData, Source, TimeSeriesDataPoint } from '../../types/CompositeDataProvider';
import { CohortsOptions, DataProviderOptions } from '../../types/DataProvider';
import removeNullValues from '../../utils/removeNullValues';
import { createUrl } from '../../utils/urls';

interface AnalyticsApiResponse {
    status: number;
    message: string;
    success: boolean;
    data: null | number | TimeSeriesDataPoint[] | CrossSectionDataPoint[] | Cohort[];
}

/**
 * Fetches data from the given sources. Resolves with an array of HandledData objects.
 */
export const fetchStats = async (
    abortController: AbortController,
    sources: Source[],
    options: DataProviderOptions
): Promise<HandledData[]> => {
    const state = store.getState();

    const fetchOptions: RequestInit = {
        signal: abortController.signal,
        headers: {
            Authorization: 'Bearer ' + state.oidc.user?.access_token
        }
    };

    const fetchData = async (source: Source): Promise<HandledData> => {
        const mergedOptions: DataProviderOptions = removeNullValues({
            ...options,
            ...source.options,
            utcOffset: moment.tz.guess()
        });
        let url = createUrl(source.src, mergedOptions);
        let handledData: HandledData = {
            data: null,
            error: null,
            id: source.id,
            options: mergedOptions,
            src: source.src
        };
        try {
            let response = await fetch(url, fetchOptions);
            let apiResponse: AnalyticsApiResponse = await response.json();
            handledData.data = source.handler(apiResponse, mergedOptions);
            return Promise.resolve(handledData);
        } catch (e) {
            handledData.error = e;
            return Promise.reject(handledData);
        }
    };

    let promises = sources.map((source) => fetchData(source));
    return Promise.all(promises);
};

interface CohortsReponse extends AnalyticsApiResponse {
    data: Cohort[];
}

export const fetchCohorts = async (abortController: AbortController, options: CohortsOptions): Promise<Cohort[]> => {
    let sources: Source[] = [
        {
            src: GET_COHORTS_ENDPOINT,
            id: 'COHORTS',
            handler: (res: CohortsReponse) => res.data,
            options
        }
    ];

    let results: HandledData[];
    try {
        results = await fetchStats(abortController, sources, options);
    } catch (e) {
        return Promise.reject(e);
    }

    let data: Cohort[] = results[0].data;

    data = data.map((cohort) => ({
        startDate: new Date(cohort.startDate),
        stopDate: new Date(cohort.stopDate),
        activity: cohort.activity.map((kvp) => ({
            key: new Date(kvp.key),
            value: kvp.value
        }))
    }));

    return data;
};
