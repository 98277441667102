import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import matchSorter from 'match-sorter';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { getCompanies } from '../../redux/reducers/companiesReducer';
import { CompaniesState } from '../../types/Companies';
import { Company, Department } from '../../types/CompanyData';

interface DepartmentSelectProps {
    companies: CompaniesState;
    company: Company;
    value: string | null;
    onChange: (department: Department | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            height: '38px',
            width: '177px'
        },
        inputRoot: {
            height: '38px',
            width: '177px',
            padding: '0 0 0 6px !important'
        }
    })
);

const filterOptions = (options: Department[], { inputValue }: { inputValue: string }) => {
    return matchSorter(options, inputValue, {
        keys: ['name', 'id']
    });
};

const getOptions = (company: Company) => {
    company.departments.sort((a, b) => a.name.localeCompare(b.name));
    let options = [
        {
            id: 'NULL',
            isDeleted: false,
            name: 'No department'
        },
        ...company.departments.filter((elem) => !elem.isDeleted)
    ];
    return options;
};

const DepartmentSelect: React.FC<DepartmentSelectProps> = ({ companies, company, value, onChange }) => {
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<{}>, value: Department | null) => {
        if (onChange) {
            onChange(value);
        }
    };
    let options: any[] = [];
    if (company && companies.companyDictionary) {
        options = getOptions(company);
    }

    return (
        <React.Fragment>
            <Autocomplete
                options={options}
                getOptionLabel={(option: Department) => (option.name ? option.name : '')}
                onChange={handleChange}
                multiple={false}
                autoSelect={false}
                value={value ? options.find((item) => item.id === value) : ''}
                filterOptions={filterOptions}
                classes={{
                    inputRoot: classes.inputRoot
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="All Departments" />}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    companies: getCompanies(state)
});

export default connect(mapStateToProps)(DepartmentSelect);
