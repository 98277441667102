// If running locally requests are proxied to the location specified in package.json
const apiBaseUrl = process.env.REACT_APP_ANALYTICS_API_URL;

export const GET_COMPANIES_ENDPOINT = `${apiBaseUrl}/api/Companies`;

export const GET_MESSAGES_ENDPOINT = `${apiBaseUrl}/api/Messages`;
export const GET_TOTAL_MESSAGES_ENDPOINT = `${apiBaseUrl}/api/Messages?total=true`;

export const GET_FILES_ENDPOINT = `${apiBaseUrl}/api/Files`;
export const GET_TOTAL_FILES_ENDPOINT = `${apiBaseUrl}/api/Files?total=true`;

export const GET_USERS_ENDPOINT = `${apiBaseUrl}/api/Users`;
export const GET_TOTAL_USERS_ENDPOINT = `${apiBaseUrl}/api/Users?total=true`;

export const GET_ACTIVE_USERS_ENDPOINT = `${apiBaseUrl}/api/Users?active=true`;
export const GET_TOTAL_ACTIVE_USERS_ENDPOINT = `${apiBaseUrl}/api/Users?total=true&active=true`;
export const GET_USER_ENTITIES_ENDPOINT = `${apiBaseUrl}/api/Users?entities=true`;

export const GET_CONNECTIONS_ENDPOINT = `${apiBaseUrl}/api/Connections`;
export const GET_TOTAL_CONNECTIONS_ENDPOINT = `${apiBaseUrl}/api/Connections?total=true`;

export const GET_TOTAL_PROFESSIONS_VERIFIED_ENDPOINT = `${apiBaseUrl}/api/Professions?total=true`;

export const GET_TOTAL_IDENTITIES_VERIFIED_ENDPOINT = `${apiBaseUrl}/api/Identities?total=true`;

export const GET_TOTAL_WORKPLACES_VERIFIED_ENDPOINT = `${apiBaseUrl}/api/Workplaces?total=true`;

export const GET_INVITES_ENDPOINT = `${apiBaseUrl}/api/Invites`;
export const GET_TOTAL_INVITES_ENDPOINT = `${apiBaseUrl}/api/Invites?total=true`;

export const GET_FEED_ENDPOINT = `${apiBaseUrl}/api/Feed`;

export const GET_COHORTS_ENDPOINT = `${apiBaseUrl}/api/Cohorts`;

export const GET_CONVERSATIONS_ENDPOINT = `${apiBaseUrl}/api/Conversations`;
export const GET_TOTAL_CONVERSATIONS_ENDPOINT = `${apiBaseUrl}/api/Conversations?total=true`;

export const GET_DASHBOARD_ENDPOINT = `${apiBaseUrl}/api/Dashboard`;
export const GET_TOTAL_DASHBOARD_ENDPOINT = `${apiBaseUrl}/api/Dashboard?total=true`;

// Do not include the '/negotiate' path on this url
export const NEGOTIATE_BASE_URL = `${apiBaseUrl}/api`;

export const PRODUCTION_TEST_COMPANY_IDS = [
    '5ca5b5ae-1fb3-4496-8a4c-34777a6bf0b3',
    '3520402d-d874-4430-8e03-f5c4e9e9dd31',
    '513a9997-6da7-45a1-bc9d-e28ba13eb164',
    '75d7379b-8822-4cef-aed1-b0f072bfbce9',
    '921ec1bf-c6b6-433c-8649-a1513d79e92e',
    'a5cacb12-91ba-4f93-b16c-6ef204de4b2b',
    'a856f3b0-677a-43a3-af3e-d115989ef092',
    '65c56a1e-662f-4810-abed-ffb6d0d824d9',
    'ee994ef8-7365-4140-980e-9f48b38added',
    '17768cfb-e4bc-4182-8150-80f3ea179531',
    'fcee77f3-cb0e-4d43-a9d9-48cb3f92ce3c',
    'c2692e98-4f50-46f8-998f-f2adbc1651b4',
    'cde72e8a-8def-459f-a718-40b32ef59d9d',
    'e0767ad7-822d-47aa-b205-e78a5234c3f5'
];

export type AnalyticsEndpoint =
    | typeof GET_COMPANIES_ENDPOINT
    | typeof GET_MESSAGES_ENDPOINT
    | typeof GET_TOTAL_MESSAGES_ENDPOINT
    | typeof GET_FILES_ENDPOINT
    | typeof GET_TOTAL_FILES_ENDPOINT
    | typeof GET_USERS_ENDPOINT
    | typeof GET_TOTAL_USERS_ENDPOINT
    | typeof GET_ACTIVE_USERS_ENDPOINT
    | typeof GET_TOTAL_ACTIVE_USERS_ENDPOINT
    | typeof GET_CONNECTIONS_ENDPOINT
    | typeof GET_TOTAL_CONNECTIONS_ENDPOINT
    | typeof GET_INVITES_ENDPOINT
    | typeof GET_TOTAL_INVITES_ENDPOINT
    | typeof GET_FEED_ENDPOINT
    | typeof GET_CONVERSATIONS_ENDPOINT
    | typeof GET_TOTAL_CONVERSATIONS_ENDPOINT;

export type TimeInterval = 's' | 'm' | 'h' | 'd' | 'w' | 'M' | 'y';

export const TIME_INTERVAL_SECONDS: {
    [key in TimeInterval]: number;
} = {
    s: 1,
    m: 60,
    h: 3600,
    d: 86400,
    w: 604800,
    M: 2.628e6,
    y: 3.154e7
};

export const IMAGE_MIMETYPES = ['image/jpeg', 'image/png', 'image/tiff'];
export const VIDEO_MIMETYPES = ['video/mp4'];
export const DOCUMENT_MIMETYPES = ['application/pdf'];

export type GroupingMethod = 'companyId' | 'departmentId';

export const MESSAGE_SENT = 'MESSAGE_SENT';
export const COMPANY_REGISTERED = 'COMPANY_REGISTERED';
export const FILE_CREATED = 'FILE_CREATED';
export const WORKSPACE_CREATED = 'WORKSPACE_CREATED';

export const USER_JOINED = 'USER_JOINED';
export const USER_LEFT = 'USER_LEFT';
export const USER_JOINED_COMPANY = 'USER_JOINED_COMPANY';
export const USER_LEFT_COMPANY = 'USER_LEFT_COMPANY';
export const USER_JOINED_DEPARTMENT = 'USER_JOINED_DEPARTMENT';
export const USER_LEFT_DEPARTMENT = 'USER_LEFT_DEPARTMENT';

export const CONNECTION_REQUEST_SENT = 'CONNECTION_REQUEST_SENT';
export const CONNECTION_REQUEST_ACCEPTED = 'CONNECTION_REQUEST_ACCEPTED';
export const CONNECTION_REQUEST_DECLINED = 'CONNECTION_REQUEST_DECLINED';
export const CONNECTION_REMOVED = 'CONNECTION_REMOVED';

export const IDENTITY_VERIFIED = 'IDENTITY_VERIFIED';
export const IDENTITY_UNVERIFIED = 'IDENTITY_UNVERIFIED';

export const PROFESSION_VERIFIED = 'PROFESSION_VERIFIED';
export const PROFESSION_UNVERIFIED = 'PROFESSION_UNVERIFIED';

export const COMPANY_VERIFICATION_STATUS_UPDATED = 'COMPANY_VERIFICATION_STATUS_UPDATED';

export const USER_ONLINE = 'USER_ONLINE';
export const USER_OFFLINE = 'USER_OFFLINE';

export const INVITE_SENT = 'INVITE_SENT';

export const CONVERSATION_CREATED = 'CONVERSATION_CREATED';
export const CONVERSATION_INVITE_ACCEPTED = 'CONVERSATION_INVITE_ACCEPTED';

export const DEBUG = 'DEBUG';

export const EVENT_STRINGS: EventString[] = [
    MESSAGE_SENT,
    COMPANY_REGISTERED,
    FILE_CREATED,
    WORKSPACE_CREATED,
    USER_JOINED,
    USER_LEFT,
    USER_JOINED_COMPANY,
    USER_LEFT_COMPANY,
    USER_JOINED_DEPARTMENT,
    USER_LEFT_DEPARTMENT,
    USER_JOINED_COMPANY,
    CONNECTION_REQUEST_SENT,
    CONNECTION_REQUEST_ACCEPTED,
    CONNECTION_REQUEST_DECLINED,
    CONNECTION_REMOVED,
    IDENTITY_VERIFIED,
    IDENTITY_UNVERIFIED,
    PROFESSION_VERIFIED,
    PROFESSION_UNVERIFIED,
    COMPANY_VERIFICATION_STATUS_UPDATED,
    USER_ONLINE,
    USER_OFFLINE,
    INVITE_SENT,
    CONVERSATION_CREATED,
    CONVERSATION_INVITE_ACCEPTED,
    DEBUG
];

export type EventString =
    | typeof MESSAGE_SENT
    | typeof COMPANY_REGISTERED
    | typeof FILE_CREATED
    | typeof WORKSPACE_CREATED
    | typeof USER_LEFT
    | typeof USER_JOINED
    | typeof USER_JOINED_COMPANY
    | typeof USER_LEFT_COMPANY
    | typeof USER_JOINED_DEPARTMENT
    | typeof USER_LEFT_DEPARTMENT
    | typeof CONNECTION_REQUEST_SENT
    | typeof CONNECTION_REQUEST_ACCEPTED
    | typeof CONNECTION_REQUEST_DECLINED
    | typeof CONNECTION_REMOVED
    | typeof IDENTITY_VERIFIED
    | typeof IDENTITY_UNVERIFIED
    | typeof PROFESSION_VERIFIED
    | typeof PROFESSION_UNVERIFIED
    | typeof COMPANY_VERIFICATION_STATUS_UPDATED
    | typeof USER_ONLINE
    | typeof USER_OFFLINE
    | typeof INVITE_SENT
    | typeof CONVERSATION_CREATED
    | typeof CONVERSATION_INVITE_ACCEPTED
    | typeof DEBUG;

export enum MessageType {
    NULL,
    ConversationStarted,
    ParticipantAdded,
    ParticipantLeft,
    ParticipantRemoved,
    PatientDataChanged,
    PatientFile,
    Photo,
    Video,
    UidMismatch
}

export type ConversationType = 'Chat' | 'Group' | 'Case';

export type ConnectionType = 'Sent' | 'Accepted' | 'Declined' | 'Removed';

export enum ProfessionVerificationStatus {
    VerificationNotRequired = -200,
    NoRecord = -100,
    Unverified = 0,
    Pending = 1,
    Verified = 2,
    MoreInfoRequired = 3
}

export enum IdentityVerificationStatus {
    Unverified = 0,
    Verified = 1
}

export enum WorkplaceVerificationStatus {
    Unverified = 0,
    Pending = 1,
    Verified = 2
}

export enum WorkplaceMetric {
    Verified = 0
}

export enum WorkspaceType {
    Workspace = 0,
    Company = 1
}

export enum InviteType {
    Email = 0
}

export enum InviteMetric {
    PercentageOnboarded = 0,
    AverageNumberOfInvitesSentPerInviter = 1
}

export enum UsersJoinedMetric {
    PercentageInvited = 0,
    Warm = 1,
    Cold = 2,
    PercentageJoinedWorkplace = 3
}

export enum MessagesSentMetric {
    SentTo = 0
}

export enum Referrer {
    Email
}

export enum ConversationMetric {
    InvitesAccepted = 0
}

export enum JoinMethod {
    Unknown = 0,
    Admin = 1,
    InviteLink = 2,
    Email = 3,
    Creation = 4
}

export interface Workplace {
    companyId: string;
    departmentId?: string;
}

export interface EventDTO {
    eventId: string;
    eventString: EventString;
    timestamp: Date;
    countryCode?: string;
    userId?: string;
    workplaces?: Workplace[];
    companyId?: string;
    departmentId?: string;
    type?: MessageType;
    mimeType?: string;
    conversationType?: ConversationType;
    joinOnMethod?: JoinMethod;
    identityVerificationStatus?: IdentityVerificationStatus;
    professionVerificationStatus?: ProfessionVerificationStatus;
    workplaceVerificationStatus?: WorkplaceVerificationStatus;
}

export interface DatabaseUpdate {
    eventStrings: EventString[];
}
