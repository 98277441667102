import React from 'react';
import { TimeSeriesDataPoint } from '../../types/CompositeDataProvider';
import ChartContainer, { ChartProps } from '../ChartContainer';
import ErrorMessage from '../ErrorMessage';
import LineChart from './LineChart';

export interface LineChartContainerProps extends ChartProps {
    data?: TimeSeriesDataPoint[];
    mode?: 'stacked' | 'grouped';
    diverging?: boolean;
    colors?: string[];
    decimalFractionAsPercentage?: boolean;
    fractionDigits?: number;
    tickValue?: string | number;
    tickFormat?: string;
    tooltipDateFormat?: string;
}

const LineChartContainer: React.FC<LineChartContainerProps> = (props) => {
    let { data, diverging } = props;

    // This is a placeholder until I decide on a way to show a timeseries graph where all 'y' values are zero
    if (data && data.length === 0) {
        return <ErrorMessage>No data available.</ErrorMessage>;
    }

    if (data) {
        data = data.map((elem) => {
            return {
                key: new Date(elem.key),
                value: elem.value
            };
        });
    }

    return (
        <ChartContainer {...props}>
            {data && (
                <LineChart
                    data={data}
                    diverging={diverging!}
                    yAxisRange={props.decimalFractionAsPercentage ? [0, 100] : null}
                    tickValue={props.tickValue}
                    tickFormat={props.tickFormat}
                    tooltipDateFormat={props.tooltipDateFormat}
                />
            )}
        </ChartContainer>
    );
};

LineChartContainer.defaultProps = {
    mode: 'stacked',
    xLegend: '',
    yLegend: '',
    diverging: false,
    colors: ['#5cefff', '#ffeda5', '#d9a5ff']
};

export default LineChartContainer;
