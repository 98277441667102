export interface AccessToken {
    role?: string | string[];
}

export const SET_SUPER_ADMIN_STATUS = 'SET_SUPER_ADMIN_STATUS';
export const SET_COMPANY_PERMISSIONS = 'SET_COMPANY_PERMISSIONS';

export interface PermissionsState {
    isSuperAdmin: boolean;
    companyIds: string[];
}

interface SetSuperAdminStatusAction {
    type: typeof SET_SUPER_ADMIN_STATUS;
    isSuperAdmin: boolean;
}

interface SetCompanyPermissionsAction {
    type: typeof SET_COMPANY_PERMISSIONS;
    companyIds: string[];
}

export type SetPermissionsActionType = SetSuperAdminStatusAction | SetCompanyPermissionsAction;
