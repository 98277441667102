import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import {
    ConversationMetric,
    DOCUMENT_MIMETYPES,
    IMAGE_MIMETYPES,
    MessagesSentMetric,
    MessageType,
    VIDEO_MIMETYPES,
    WorkplaceMetric
} from '../../types/AnalyticsApi';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';
import ConnectionsDataProvider from '../DataProviders/ConnectionsDataProvider';
import ConversationsDataProvider from '../DataProviders/ConversationsDataProvider';
import DashboardDataProvider from '../DataProviders/DashboardDataProvider';
import FilesDataProvider from '../DataProviders/FilesDataProvider';
import IdentitiesDataProvider from '../DataProviders/IdentitiesDataProvider';
import MessagesDataProvider from '../DataProviders/MessagesDataProvider';
import ProfessionsDataProvider from '../DataProviders/ProfessionsDataProvider';
import UsersDataProvider from '../DataProviders/UsersDataProvider';
import WorkplacesDataProvider from '../DataProviders/WorkplacesDataProvider';
import MetricContainer from '../Metric/MetricContainer';
import Panel from '../Panel/Panel';
import PanelContent from '../Panel/PanelContent';
import PanelTitle from '../Panel/PanelTitle';

interface DashboardMetricsProps {
    countryCode: string[] | null;
    from: string | null;
    to: string | null;
    companyId: string[] | null;
    departmentId: string[] | null;
    permissions: PermissionsState;
}

const DashboardMetrics: React.FC<DashboardMetricsProps> = (props) => {
    let isSuperAdmin = props.permissions.isSuperAdmin;

    if (!isSuperAdmin) {
        return (
            <React.Fragment>
                <Grid container item spacing={3} justify="space-between">
                    <Grid container item spacing={3} justify="space-between">
                        <Grid item xs>
                            <Panel tooltipTitle={'Number of users who connected within the selected time range'}>
                                <PanelTitle>Active users</PanelTitle>
                                <PanelContent>
                                    <UsersDataProvider
                                        active
                                        crossSection
                                        options={{
                                            ...props
                                        }}
                                    >
                                        <MetricContainer />
                                    </UsersDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                        <Grid item xs>
                            <Panel
                                tooltipTitle={
                                    'Number of messages exchanged with other users within the selected time range'
                                }
                            >
                                <PanelTitle>Messages Exchanged</PanelTitle>
                                <PanelContent>
                                    <MessagesDataProvider
                                        crossSection
                                        options={{
                                            ...props,
                                            type: [MessageType.NULL],
                                            metric: MessagesSentMetric.SentTo
                                        }}
                                    >
                                        <MetricContainer />
                                    </MessagesDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                        <Grid item xs>
                            <Panel
                                tooltipTitle={
                                    'Number of photos exchanged with other users within the selected time range'
                                }
                            >
                                <PanelTitle>Photos Exchanged</PanelTitle>
                                <PanelContent>
                                    <MessagesDataProvider
                                        crossSection
                                        options={{
                                            ...props,
                                            type: [MessageType.Photo],
                                            metric: MessagesSentMetric.SentTo
                                        }}
                                    >
                                        <MetricContainer />
                                    </MessagesDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                    </Grid>

                    <Grid container item spacing={3} justify="space-between">
                        <Grid item xs>
                            <Panel
                                tooltipTitle={
                                    'Number of videos exchanged with other users within the selected time range'
                                }
                            >
                                <PanelTitle>Videos Exchanged</PanelTitle>
                                <PanelContent>
                                    <MessagesDataProvider
                                        crossSection
                                        options={{
                                            ...props,
                                            type: [MessageType.Video],
                                            metric: MessagesSentMetric.SentTo
                                        }}
                                    >
                                        <MetricContainer />
                                    </MessagesDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                        <Grid item xs>
                            <Panel
                                tooltipTitle={
                                    'Number of documents exchanged with other users within the selected time range'
                                }
                            >
                                <PanelTitle>Documents Exchanged</PanelTitle>
                                <PanelContent>
                                    <MessagesDataProvider
                                        crossSection
                                        options={{
                                            ...props,
                                            type: [MessageType.PatientFile],
                                            metric: MessagesSentMetric.SentTo
                                        }}
                                    >
                                        <MetricContainer />
                                    </MessagesDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                    </Grid>

                    <Grid container item spacing={3} justify="space-between">
                        <Grid item xs>
                            <Panel tooltipTitle={'Number of connection requests sent within the selected time range'}>
                                <PanelTitle>Connections Sent</PanelTitle>
                                <PanelContent>
                                    <ConnectionsDataProvider
                                        crossSection
                                        options={{
                                            ...props,
                                            connectionType: ['Sent']
                                        }}
                                    >
                                        <MetricContainer />
                                    </ConnectionsDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                        <Grid item xs>
                            <Panel
                                tooltipTitle={'Number of connection requests accepted within the selected time range'}
                            >
                                <PanelTitle>Connections Accepted</PanelTitle>
                                <PanelContent>
                                    <ConnectionsDataProvider
                                        crossSection
                                        options={{
                                            ...props,
                                            connectionType: ['Accepted']
                                        }}
                                    >
                                        <MetricContainer />
                                    </ConnectionsDataProvider>
                                </PanelContent>
                            </Panel>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Grid container item spacing={3} justify="space-between">
                <Grid container item spacing={3}>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of users who connected within the selected time range'}>
                            <PanelTitle>Active users</PanelTitle>
                            <PanelContent>
                                <UsersDataProvider
                                    active
                                    crossSection
                                    options={{
                                        ...props
                                    }}
                                >
                                    <MetricContainer />
                                </UsersDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Change in the number of users with a verified identity in the selected time range'
                            }
                        >
                            <PanelTitle>Δ Verified Identities</PanelTitle>
                            <PanelContent>
                                <IdentitiesDataProvider
                                    crossSection
                                    options={{
                                        ...props
                                    }}
                                >
                                    <MetricContainer showSign />
                                </IdentitiesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Change in the number of users with a verified profession in the selected time range'
                            }
                        >
                            <PanelTitle>Δ Verified Professions</PanelTitle>
                            <PanelContent>
                                <ProfessionsDataProvider
                                    crossSection
                                    options={{
                                        ...props
                                    }}
                                >
                                    <MetricContainer showSign />
                                </ProfessionsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Change in the number of users with a verified workspace in the selected time range'
                            }
                        >
                            <PanelTitle>Δ Verified Workspaces</PanelTitle>
                            <PanelContent>
                                <WorkplacesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        metric: WorkplaceMetric.Verified
                                    }}
                                >
                                    <MetricContainer showSign />
                                </WorkplacesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of users who made a request to the dashboard in the selected time range'
                            }
                        >
                            <PanelTitle>Dashboard Users</PanelTitle>
                            <PanelContent>
                                <DashboardDataProvider
                                    crossSection
                                    options={{
                                        ...props
                                    }}
                                >
                                    <MetricContainer />
                                </DashboardDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>

                <Grid container item spacing={3} justify="space-between">
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of messages sent within the selected time range'}>
                            <PanelTitle>Messages Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: [MessageType.NULL]
                                    }}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of messages exchanged with other users within the selected time range'
                            }
                        >
                            <PanelTitle>Messages Exchanged</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: [MessageType.NULL],
                                        metric: MessagesSentMetric.SentTo
                                    }}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of photos captured within the selected time range'}>
                            <PanelTitle>Photos Captured</PanelTitle>
                            <PanelContent>
                                <FilesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        mimeType: IMAGE_MIMETYPES
                                    }}
                                >
                                    <MetricContainer />
                                </FilesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of photos sent within the selected time range'}>
                            <PanelTitle>Photos Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: [MessageType.Photo]
                                    }}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={'Number of photos exchanged with other users within the selected time range'}
                        >
                            <PanelTitle>Photos Exchanged</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: [MessageType.Photo],
                                        metric: MessagesSentMetric.SentTo
                                    }}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>

                <Grid container item spacing={3} justify="space-between">
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of videos captured within the selected time range'}>
                            <PanelTitle>Videos Captured</PanelTitle>
                            <PanelContent>
                                <FilesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        mimeType: VIDEO_MIMETYPES
                                    }}
                                >
                                    <MetricContainer />
                                </FilesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of videos sent within the selected time range'}>
                            <PanelTitle>Videos Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: [MessageType.Video]
                                    }}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={'Number of videos exchanged with other users within the selected time range'}
                        >
                            <PanelTitle>Videos Exchanged</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: [MessageType.Video],
                                        metric: MessagesSentMetric.SentTo
                                    }}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of documents created within the selected time range'}>
                            <PanelTitle>Documents Created</PanelTitle>
                            <PanelContent>
                                <FilesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        mimeType: DOCUMENT_MIMETYPES
                                    }}
                                >
                                    <MetricContainer />
                                </FilesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of documents sent within the selected time range'}>
                            <PanelTitle>Documents Sent</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: [MessageType.PatientFile]
                                    }}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of documents exchanged with other users within the selected time range'
                            }
                        >
                            <PanelTitle>Documents Exchanged</PanelTitle>
                            <PanelContent>
                                <MessagesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        type: [MessageType.PatientFile],
                                        metric: MessagesSentMetric.SentTo
                                    }}
                                >
                                    <MetricContainer />
                                </MessagesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>

                <Grid container item spacing={3} justify="space-between">
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of connection requests sent within the selected time range'}>
                            <PanelTitle>Connections Sent</PanelTitle>
                            <PanelContent>
                                <ConnectionsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        connectionType: ['Sent']
                                    }}
                                >
                                    <MetricContainer />
                                </ConnectionsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of connection requests accepted within the selected time range'}>
                            <PanelTitle>Connections Accepted</PanelTitle>
                            <PanelContent>
                                <ConnectionsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        connectionType: ['Accepted']
                                    }}
                                >
                                    <MetricContainer />
                                </ConnectionsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>

                <Grid container item spacing={3} justify="space-between">
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of workspaces created within the selected time range'}>
                            <PanelTitle>Workspaces Created</PanelTitle>
                            <PanelContent>
                                <WorkplacesDataProvider
                                    crossSection
                                    options={{
                                        ...props
                                    }}
                                >
                                    <MetricContainer />
                                </WorkplacesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of workspaces created by non-admin users within the selected time range'
                            }
                        >
                            <PanelTitle>Workspaces Created By Users</PanelTitle>
                            <PanelContent>
                                <WorkplacesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        superAdmin: false
                                    }}
                                >
                                    <MetricContainer />
                                </WorkplacesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={'Number of workspaces created by super admins within the selected time range'}
                        >
                            <PanelTitle>Workspaces Created By Admins</PanelTitle>
                            <PanelContent>
                                <WorkplacesDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        superAdmin: true
                                    }}
                                >
                                    <MetricContainer />
                                </WorkplacesDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>

                <Grid container item spacing={3} justify="space-between">
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of group invites accepted within the selected time range'}>
                            <PanelTitle>Group Invites Accepted</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        metric: ConversationMetric.InvitesAccepted
                                    }}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of group invites accepted within 24 hours of onboarding within the selected time'
                            }
                        >
                            <PanelTitle>Group Invites Accepted By New Users</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        metric: ConversationMetric.InvitesAccepted,
                                        timeSinceUserJoinedSeconds: 24 * 60 * 60
                                    }}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={
                                'Number of group invites accepted after 24 hours have passed since onboarding within the selected time'
                            }
                        >
                            <PanelTitle>Group Invites Accepted By Existing Users</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        metric: ConversationMetric.InvitesAccepted,
                                        timeSinceUserJoinedSeconds: 24 * 60 * 60,
                                        invertTimeSinceUserJoinedSeconds: true
                                    }}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>

                <Grid container item spacing={3} justify="space-between">
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of conversations created within the selected time range'}>
                            <PanelTitle>Conversations Created</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...props
                                    }}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel
                            tooltipTitle={'Number of one-on-one conversations created within the selected time range'}
                        >
                            <PanelTitle>Chats Created</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        conversationType: ['Chat']
                                    }}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of group conversations created within the selected time range'}>
                            <PanelTitle>Groups Created</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        conversationType: ['Group']
                                    }}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                    <Grid item xs>
                        <Panel tooltipTitle={'Number of cases created within the selected time range'}>
                            <PanelTitle>Cases Created</PanelTitle>
                            <PanelContent>
                                <ConversationsDataProvider
                                    crossSection
                                    options={{
                                        ...props,
                                        conversationType: ['Case']
                                    }}
                                >
                                    <MetricContainer />
                                </ConversationsDataProvider>
                            </PanelContent>
                        </Panel>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

function mapStateToProps(state: StoreState) {
    return {
        permissions: getPermissions(state)
    };
}

export default connect(mapStateToProps)(DashboardMetrics);
