import { createStyles, makeStyles, Theme as MUITheme } from '@material-ui/core';
import { Theme } from '@nivo/core';
import { Datum, ResponsiveLine, Serie, SliceTooltipProps } from '@nivo/line';
import React from 'react';
import { DataPoint, TimeSeriesDataPoint } from '../../types/CompositeDataProvider';
import { LineChartContainerProps } from './LineChartContainer';

const numeral = require('numeral');
const dateFormat = require('dateformat');

export interface LineChartProps extends LineChartContainerProps {
    data: TimeSeriesDataPoint[];
    diverging: boolean;
    yAxisRange?: number[] | null;
}

const getKeys = (data: TimeSeriesDataPoint[]) => {
    let keys: string[] = [];

    // Check if data contains TimeSeriesData<number>
    for (let item of data) {
        if (!(item.value instanceof Array)) {
            keys.push('all');
            return keys;
        }
    }

    // Add the key of every sub item to our keys
    for (let item of data) {
        let value = item.value as DataPoint[];

        if (value.length === 0) {
            continue;
        }

        for (let subItem of value) {
            if (!keys.includes(subItem.key)) {
                keys.push(subItem.key);
            }
        }
    }

    return keys;
};

const getTimestamps = (data: TimeSeriesDataPoint[]) => {
    let keys: number[] = [];

    for (let item of data) {
        keys.push(item.key.getTime());
    }

    return keys;
};

const getFormattedData = (data: TimeSeriesDataPoint[], diverging: boolean): Serie[] => {
    let dataSets: {
        [id: string]: Serie;
    } = {};

    let dataSetTimestamps: {
        [id: string]: {
            [timestamp: number]: any;
        };
    } = {};

    let lineChartData: Serie[] = [];
    let timestamps = getTimestamps(data);
    let keys = getKeys(data);

    for (let key of keys) {
        dataSets[key] = {
            id: key,
            data: []
        };
        dataSetTimestamps[key] = {};
    }

    // Check if data contains TimeSeriesData<number>
    for (let item of data) {
        // Check if this is subgrouped time series data
        if (item.value instanceof Array) {
            for (let subItem of item.value as DataPoint[]) {
                // Data is an array of date string: {id: number}[] pairs
                let dataItem: Datum = {
                    x: item.key,
                    y: subItem.value
                };
                let timestamp = item.key.getTime();
                dataSets[subItem.key]!.data.push(dataItem);
                dataSetTimestamps[subItem.key][timestamp] = true;
            }
        } else {
            // Data is an array of date string: number pairs
            let dataItem: Datum = {
                x: item.key,
                y: item.value
            };

            dataSets['All'] = {
                id: 'All',
                data: [dataItem]
            };
        }
    }

    for (let key in dataSets) {
        // Replace gaps in data with zeroes
        for (let timestamp of timestamps) {
            if (!dataSetTimestamps[key][timestamp]) {
                dataSets[key].data.push({
                    x: new Date(timestamp),
                    y: 0
                });
            }
        }
        dataSets[key].data.sort((a, b) => {
            return a.x! < b.x! ? -1 : 1;
        });
        lineChartData.push(dataSets[key]);
    }

    if (diverging) {
        let lastValueDict: {
            [id: string]: number;
        } = {};

        for (let key of keys) {
            lastValueDict[key] = 0;
        }

        for (let dataSet of lineChartData) {
            if (dataSet.data.length < 2) {
                throw new Error('Insufficient data to calculate delta');
            }

            for (let item of dataSet.data) {
                let lastValue = lastValueDict[dataSet.id];
                lastValueDict[dataSet.id] = item.y as number;
                item.y = (item.y as number) - lastValue;
            }
        }

        // The very first value of each dataSet has a change of zero (as there's nothing before it to compare to)
        // so we remove it as it's innacurate to display it
        for (let dataSet of lineChartData) {
            dataSet.data = dataSet.data.slice(1);
        }
    }

    return lineChartData;
};

const customSliceTooltip = (props: SliceTooltipProps, tooltipDateFormat: string) => {
    const slice = props.slice;
    let xLabel: string | null = null;

    if (slice.points.length > 0) {
        xLabel = dateFormat(slice.points[0].data.x, tooltipDateFormat);
    }

    return (
        <div
            style={{
                background: 'black',
                padding: '9px 12px',
                borderRadius: '10px'
            }}
        >
            <div>{xLabel}</div>
            {slice.points.map((point) => (
                <div
                    key={point.id}
                    style={{
                        color: point.serieColor,
                        padding: '3px 0'
                    }}
                >
                    <strong>{point.serieId}</strong> [{point.data.yFormatted}]
                </div>
            ))}
        </div>
    );
};

const theme: Theme = {
    axis: {
        ticks: {
            text: {
                fill: '#ffffff'
            }
        }
    },
    dots: {
        text: {
            fill: '#ffffff'
        }
    }
};

const useStyles = makeStyles((theme: MUITheme) =>
    createStyles({
        chartPanelContainer: {
            flex: 1,
            height: '100%',
            width: '100%',
            position: 'relative'
        },
        chartBackground: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 30,
            left: 0,
            backgroundColor: '#111111',
            padding: '0 0 40px 75px',
            backgroundClip: 'content-box'
        },
        chartContainer: {
            position: 'absolute',
            width: '100%',
            height: '100%'
        }
    })
);

/**
 * A line chart.
 *
 * Note: the chart must be placed within a container which has a height and width set.
 */
const LineChart: React.FC<LineChartProps> = ({
    data,
    xLegend,
    yLegend,
    margin,
    diverging,
    dataProviderOptions,
    colors,
    yAxisRange,
    ...props
}) => {
    const classes = useStyles();
    let formattedData = getFormattedData(data, diverging);
    let adjustedMargin = Object.assign({}, margin);

    let maxValue = Number.MIN_VALUE;
    let minValue = Number.MAX_VALUE;
    for (let dataSet of formattedData) {
        maxValue = Math.max(...dataSet.data.map((elem) => elem.y as number), maxValue);
        minValue = Math.min(...dataSet.data.map((elem) => elem.y as number), minValue);
    }

    let yAxisMax = maxValue + Math.max((maxValue - minValue) * 0.1, 1);

    let tickValue: string | number = 'every year';
    let tickFormat = '%b %d, %Y';
    let tooltipDateFormat = 'd mmm yyyy';
    if (dataProviderOptions && dataProviderOptions.interval) {
        switch (dataProviderOptions.interval) {
            case 'h':
                tickValue = 'every hour';
                tickFormat = '%-I%p';
                tooltipDateFormat = 'hTT';
                break;
            case 'd':
                tickValue = 10;
                tickFormat = '%-d %b';
                tooltipDateFormat = 'd mmm';
                break;
            case 'w':
                tickValue = 'every week';
                tickFormat = 'Week starting %-d %b';
                tooltipDateFormat = 'd mmm';
                break;
            case 'M':
                tickValue = 'every month';
                tickFormat = '%b';
                tooltipDateFormat = 'mmm yyyy';
                break;
            case 'y':
                tickValue = 'every year';
                tickFormat = '%b %Y';
                tooltipDateFormat = 'yyyy';
                break;
        }
    }

    tickValue = props.tickValue || tickValue;
    tickFormat = props.tickFormat || tickFormat;
    tooltipDateFormat = props.tooltipDateFormat || tooltipDateFormat;

    let lastTickY: string | null = null;

    return (
        <div className={classes.chartPanelContainer}>
            <div className={classes.chartBackground} />

            <div className={classes.chartContainer}>
                <ResponsiveLine
                    data={formattedData}
                    margin={adjustedMargin}
                    xScale={{
                        type: 'time',
                        // format: '%Y-%m-%dT%H:%M:%SZ',
                        precision: 'second'
                    }}
                    // xFormat="time:%Y-%m-%dT%H:%M:%SZ"
                    yScale={{
                        type: 'linear',
                        min: yAxisRange ? yAxisRange[0] : 'auto',
                        max: yAxisRange ? yAxisRange[1] : yAxisMax
                    }}
                    axisBottom={{
                        format: tickFormat,
                        legend: xLegend,
                        legendPosition: 'middle',
                        legendOffset: 40,
                        tickSize: 0,
                        tickPadding: 15,
                        tickValues: tickValue
                    }}
                    axisLeft={{
                        format: (value) => {
                            // Only display whole numbers
                            let label = numeral((value as number) - 0.5).format('0');

                            // Don't display this tick if it has the same value as the last one after being formatted
                            if (label === lastTickY) {
                                label = '';
                            } else {
                                lastTickY = label;
                            }

                            return label;
                        },
                        tickSize: 0,
                        tickPadding: 25,
                        legend: yLegend,
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                    theme={theme}
                    animate={true}
                    useMesh={false}
                    enableGridX={false}
                    enableGridY={false}
                    enablePoints={false}
                    pointSize={0}
                    pointBorderWidth={0}
                    enablePointLabel={true}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    enableSlices="x"
                    colors={colors}
                    sliceTooltip={(props: SliceTooltipProps) => customSliceTooltip(props, tooltipDateFormat)}
                    markers={
                        !diverging
                            ? undefined
                            : [
                                  {
                                      axis: 'y',
                                      value: 0,
                                      lineStyle: { stroke: '#333333', strokeWidth: 0.5 }
                                  }
                              ]
                    }
                />
            </div>
        </div>
    );
};

LineChart.defaultProps = {
    margin: {
        top: 60,
        left: 60,
        right: 60,
        bottom: 60
    }
};

export default LineChart;
