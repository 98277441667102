import React from 'react';
import { CrossSectionDataPoint } from '../../types/CompositeDataProvider';
import ChartContainer, { ChartProps } from '../ChartContainer';
import Metric from './Metric';

export interface MetricContainerProps extends ChartProps {
    decimalFractionAsPercentage?: boolean;
    percentage?: boolean;
    fractionDigits?: number;
    showSign?: boolean;
    inline?: boolean;
    disableLoadingIndicator?: boolean;
}

/**
 * Container for a single data point.
 *
 * MetricContainer expects the given data to contain a single entry with a string or numeric value.
 */
const MetricContainer: React.FC<MetricContainerProps> = (props) => {
    const { data, dataFormat } = props;
    let value: string | number;

    if (dataFormat !== 'CROSS_SECTION') {
        throw new TypeError('MetricContainer data must be of type CrossSectionDataPoint[]');
    }

    let crossSectionData = data as CrossSectionDataPoint[];

    if (!crossSectionData?.length || crossSectionData[0].value === null) {
        value = 0;
    } else {
        value = crossSectionData[0].value as number;
    }

    if (props.decimalFractionAsPercentage) {
        value *= 100;
        value = value.toFixed(props.fractionDigits);
    }

    return (
        <ChartContainer {...props} metric>
            {crossSectionData && (
                <Metric
                    value={value}
                    percentage={props.decimalFractionAsPercentage || props.percentage}
                    showSign={props.showSign}
                />
            )}
        </ChartContainer>
    );
};

MetricContainer.defaultProps = {
    fractionDigits: 0
};

export default MetricContainer;
