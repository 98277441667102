import { CircularProgress } from '@material-ui/core';
import React from 'react';
import Center from './Center';

export interface CenteredProgressProps {
    vertical?: boolean;
    horizontal?: boolean;
}

/**
 * Progress indicator centered within it's parent.
 */
const CenteredProgress: React.FC<CenteredProgressProps> = ({ vertical, horizontal }) => {
    return (
        <Center vertical={vertical} horizontal={horizontal}>
            <CircularProgress />
        </Center>
    );
};

export default CenteredProgress;
