import EventStream from '../notification/EventStream';

// export interface MessageStreamState extends EventStream {}

export type MessageStreamState = EventStream | null;

export const INITIALIZE_MESSAGE_STREAM = 'INITIALIZE_MESSAGE_STREAM';

interface InitializeMessageStreamAction {
    type: typeof INITIALIZE_MESSAGE_STREAM;
    messageStream: EventStream;
}

export type MessageStreamActionTypes = InitializeMessageStreamAction;
