import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import React, { useState } from 'react';

export interface SnackbarProps {
    message: string;
    severity: Color;
    autoHideDuration?: number;
}

const Snackbar: React.FC<SnackbarProps> = ({ message, severity, autoHideDuration }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MuiSnackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'top'
            }}
        >
            <Alert severity={severity} onClose={handleClose}>
                {message}
            </Alert>
        </MuiSnackbar>
    );
};

export default Snackbar;
