import React from 'react';
import { GET_MESSAGES_ENDPOINT, GET_TOTAL_MESSAGES_ENDPOINT } from '../../types/AnalyticsApi';
import CrossSectionalData from '../../types/CrossSectionalData';
import { DataProviderOptions, MessagesOptions } from '../../types/DataProvider';
import TimeSeriesData from '../../types/TimeseriesData';
import { getCompanyOrDepartmentName } from '../../utils/getCompanyOrDepartmentName';
import DataProvider, { OptionalDataProviderProps } from './DataProvider';

interface MessagesDataProviderProps extends OptionalDataProviderProps {
    children: any;
    options?: MessagesOptions;
    crossSection?: boolean;
}

const timeSeriesResponseHandler = (res: any, options: DataProviderOptions): TimeSeriesData => {
    let timeSeriesData: TimeSeriesData = [];
    let isAllZero = true;

    for (let item of res.data) {
        if (!(item.value instanceof Array)) {
            if (item.value !== 0) {
                isAllZero = false;
            }

            timeSeriesData.push({
                key: item.key,
                value: [
                    {
                        key: 'All',
                        value: item.value
                    }
                ]
            });
        } else {
            // Replace IDs with their corresponding name if possible
            for (let subItem of item.value) {
                if (subItem.value !== 0) {
                    isAllZero = false;
                }

                subItem.key = getCompanyOrDepartmentName(subItem.key);
            }

            timeSeriesData.push(item);
        }
    }

    if (isAllZero) {
        return [];
    }

    return timeSeriesData;
};

const MessagesDataProvider: React.FC<MessagesDataProviderProps> = ({ children, options, crossSection, ...other }) => {
    const crossSectionalResponseHandler = (res: any, options: DataProviderOptions): CrossSectionalData => {
        let crossSectionalData: CrossSectionalData = [];
        let totals: {
            [id: string]: number;
        } = {};

        if (!options.groupBy) {
            crossSectionalData.push({
                key: 'all',
                value: res.data
            });
        } else {
            // Accumulate the value of the groups from the response
            for (let item of res.data) {
                totals[item.key] = item.value;
            }

            for (let key in totals) {
                let name = getCompanyOrDepartmentName(key);

                crossSectionalData.push({
                    key: name,
                    value: totals[key]
                });
            }
        }

        return crossSectionalData;
    };

    return (
        <DataProvider
            dataFormat={crossSection ? 'CROSS_SECTION' : 'TIME_SERIES'}
            src={crossSection ? GET_TOTAL_MESSAGES_ENDPOINT : GET_MESSAGES_ENDPOINT}
            options={options}
            responseHandler={crossSection ? crossSectionalResponseHandler : timeSeriesResponseHandler}
            updateOnEventStrings={['MESSAGE_SENT']}
            {...other}
        >
            {children}
        </DataProvider>
    );
};

export default MessagesDataProvider;
