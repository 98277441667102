import { Button, createStyles, Grid, makeStyles, MenuItem, Select, TextField, Theme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DatePicker, DatePickerView } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { unitOfTime } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { TimeInterval } from '../../types/AnalyticsApi';
import getFromAndToDate from '../../utils/getFromAndToDate';

const dateFormat = require('dateformat');

const TimeRanges: TimeRange[] = [
    'Overall',
    'Today',
    'This week',
    'Last 24 hours',
    'Last 7 days',
    'Last 14 days',
    'Last 30 days',
    'Last 60 days',
    'Last 365 days',
    'Last...',
    'Day',
    'Week',
    'Month',
    'Year',
    'Custom'
];

export type TimeRange =
    | 'Overall'
    | 'Today'
    | 'This week'
    | 'Last 24 hours'
    | 'Last 7 days'
    | 'Last 14 days'
    | 'Last 30 days'
    | 'Last 60 days'
    | 'Last 365 days'
    | 'Last...'
    | 'Day'
    | 'Week'
    | 'Month'
    | 'Year'
    | 'Custom';

export const RelativeTimeRanges: TimeRange[] = [
    'Today',
    'This week',
    'Last 24 hours',
    'Last 7 days',
    'Last 14 days',
    'Last 30 days',
    'Last 60 days',
    'Last 365 days',
    'Last...'
];

export const TimeRangeInterval: {
    [key in TimeRange]: TimeInterval;
} = {
    Overall: 'M',
    Today: 'h',
    'This week': 'd',
    'Last 24 hours': 'h',
    'Last 7 days': 'd',
    'Last 14 days': 'd',
    'Last 30 days': 'd',
    'Last 60 days': 'd',
    'Last 365 days': 'M',
    'Last...': 'd',
    Day: 'h',
    Week: 'd',
    Month: 'd',
    Year: 'M',
    Custom: 'M'
};

export const MinDate = new Date(2016, 0, 1);
export const MaxDate = new Date();

/**
 * These options use an interval that's determined by the length of time that's been selected
 */
export const CustomTimeRangeOptions: TimeRange[] = ['Last...', 'Custom'];

export type TimeRangeUnit = 'Days' | 'Weeks' | 'Months' | 'Years';

export const RelativeTimeRangeUnits: TimeRangeUnit[] = ['Days', 'Weeks', 'Months', 'Years'];

const RelativeTimeRangeMappings: {
    [key in TimeRangeUnit]: unitOfTime.DurationConstructor;
} = {
    Days: 'days',
    Weeks: 'weeks',
    Months: 'months',
    Years: 'years'
};

export const SelectableTimeRanges: TimeRange[] = ['Day', 'Week', 'Month', 'Year', 'Custom'];

export interface TimeRangeSelectProps {
    onChange?: (from: Date | null, to: Date | null, value: TimeInterval) => void;
    updateInterval?: number;
}

interface TimeRangeSelectState {
    value: TimeRange;
    startDate: Date | null;
    stopDate: Date | null;
    interval: TimeInterval;
    relativeTimeRangeValue: number;
    relativeTimeRangeUnit: TimeRangeUnit;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            height: '38px',
            width: '177px'
        },
        label: {
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff',
            marginLeft: '15px'
        },
        inputRoot: {
            padding: 0,
            width: '140px',
            textAlign: 'end'
        },
        inputRootWeek: {
            padding: 0,
            width: '250px',
            textAlign: 'end'
        },
        textField: {
            width: '100px'
        }
    })
);

const TimeRangeSelect: React.FC<TimeRangeSelectProps> = ({ onChange, updateInterval }) => {
    const [state, setState] = useState<TimeRangeSelectState>({
        value: 'Last 24 hours',
        startDate: new Date(Date.now()),
        stopDate: null,
        // stopDate: new Date(Date.now()),
        interval: 'd',
        relativeTimeRangeValue: 7,
        relativeTimeRangeUnit: 'Days'
    });
    const classes = useStyles();

    const getFromAndToDateWrapper = useCallback(
        (start?: Date | null, stop?: Date | null, value?: TimeRange) => {
            let from: Date | null = start || state.startDate;
            let to: Date | null = stop || state.stopDate;

            let timeRange = value || state.value;

            let relativeTimeRangeUnit = RelativeTimeRangeMappings[state.relativeTimeRangeUnit];

            return getFromAndToDate(timeRange, from, to, state.relativeTimeRangeValue, relativeTimeRangeUnit);
        },
        [state.value, state.startDate, state.stopDate, state.relativeTimeRangeUnit, state.relativeTimeRangeValue]
    );

    const getInterval = useCallback(
        (value?: TimeRange, start?: Date | null, stop?: Date | null): TimeInterval => {
            let timeRange = value || state.value;
            let startDate = start || state.startDate || MinDate;
            let stopDate = stop || state.stopDate || new Date();

            if (!CustomTimeRangeOptions.includes(timeRange)) {
                return TimeRangeInterval[timeRange];
            }
            // Compute interval based on the length of time between the start and stop dates
            // This is not 100% accurate due to timezones and other time related properties, but it's a good enough approximation
            let days = (stopDate.getTime() - startDate.getTime()) / 8.64e7;
            let years = days / 365;

            if (days < 1.5) {
                return 'h';
            } else if (days <= 90) {
                return 'd';
            } else if (years <= 2) {
                return 'M';
            }
            return 'y';
        },
        [state.value, state.startDate, state.stopDate]
    );

    /**
     * Calls onChange (if given) with the current state's values
     */
    const update = useCallback(() => {
        if (onChange) {
            let { from, to } = getFromAndToDateWrapper(state.startDate, state.stopDate, state.value);
            let interval = getInterval(state.value, from, to);
            if (onChange) {
                onChange(from, to, interval);
            }
        }
    }, [getFromAndToDateWrapper, getInterval, onChange, state.startDate, state.stopDate, state.value]);

    const handleSelectChange = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        let value = e.target.value as TimeRange;

        // When the selection changes we may need to update our start and stop dates
        let { from, to } = getFromAndToDateWrapper(null, null, value);
        let interval = getInterval(value, from, to);

        setState((prevState: TimeRangeSelectState) => {
            return {
                ...prevState,
                startDate: from,
                stopDate: to,
                value,
                interval
            };
        });
    };

    const handleDateChange = (field: string, date: MaterialUiPickersDate) => {
        if (!date) {
            return;
        }

        let start = state.startDate;
        let stop = state.stopDate;

        if (field === 'startDate') {
            start = date.toDate();
        } else if (field === 'stopDate') {
            stop = date.toDate();
        }

        let { from, to } = getFromAndToDateWrapper(start, stop);
        let interval = getInterval(state.value, from, to);

        // We don't want to store 'null' dates
        let newStartDate = from ? from : state.startDate;
        let newStopDate = to ? to : state.stopDate;

        setState((prevState: TimeRangeSelectState) => ({
            ...prevState,
            startDate: newStartDate,
            stopDate: newStopDate,
            interval
        }));
    };

    const handleRelativeTimeRangeInput = (e: any) => {
        const value = parseInt(e.target.value);

        if (isNaN(value) || value < 1) {
            return;
        }

        setState((prevState: TimeRangeSelectState) => ({
            ...prevState,
            relativeTimeRangeValue: value
        }));
    };

    const handleRelativeTimeRangeSelect = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        let value = e.target.value as TimeRangeUnit;

        setState((prevState: TimeRangeSelectState) => {
            return {
                ...prevState,
                relativeTimeRangeUnit: value
            };
        });
    };

    const handleRelativeTimeRangeClick = () => {
        update();
    };

    const handleStartDateChange = (date: MaterialUiPickersDate) => {
        handleDateChange('startDate', date);
    };

    const handleStopDateChange = (date: MaterialUiPickersDate) => {
        handleDateChange('stopDate', date);
    };

    const formatLabel = (date: MaterialUiPickersDate, invalidLabel: string) => {
        if (date) {
            let format = 'd mmm yyyy';
            switch (state.value) {
                case 'Week':
                    format = '"Week starting" d mmm yyyy';
                    break;
                case 'Month':
                    format = 'mmmm yyyy';
                    break;
                case 'Year':
                    format = 'yyyy';
                    break;
            }

            return dateFormat(date.toDate(), format);
        }
        return 'Invalid date';
    };

    // Call onChange whenever state values that affect it change
    useEffect(() => {
        update();
        // We don't want to include update() in our dependency array as it would create a rendering loop
        // eslint-disable-next-line
    }, [state.value, state.startDate, state.stopDate, state.interval]);

    // Update range every once in awhile for anchored time ranges (so that they're still anchored to the current time)
    useEffect(() => {
        // The only states that require us to update the range is are relative time ranges
        // as they are anchored to the current time
        if (!RelativeTimeRanges.includes(state.value)) {
            return;
        }

        const timeout = setInterval(() => {
            update();
        }, updateInterval);

        return () => {
            clearTimeout(timeout);
        };
        // We don't want to include update() in our dependency array as it would create a rendering loop
        // eslint-disable-next-line
    }, [updateInterval, state.value]);

    let menuItems: JSX.Element[] = [];
    for (let item of TimeRanges) {
        menuItems.push(
            <MenuItem value={item} key={item}>
                {item}
            </MenuItem>
        );
    }

    let views: DatePickerView[] = ['date'];
    if (state.value === 'Month') {
        views = ['month'];
    } else if (state.value === 'Year') {
        views = ['year'];
    }

    const isCustomAbsoluteDateRange = state.value === 'Custom';
    const isCustomRelativeDateRange = state.value === 'Last...';
    const isSelectableDateRange = SelectableTimeRanges.includes(state.value) && !isCustomRelativeDateRange;

    return (
        <React.Fragment>
            <Grid container alignItems="center">
                <Grid item>
                    <Select
                        value={state.value}
                        onChange={handleSelectChange}
                        variant="outlined"
                        className={classes.select}
                    >
                        {menuItems}
                    </Select>
                </Grid>

                {isCustomRelativeDateRange && (
                    <React.Fragment>
                        <Grid item>
                            <TextField
                                type="number"
                                value={state.relativeTimeRangeValue}
                                onChange={handleRelativeTimeRangeInput}
                                className={classes.textField}
                                InputProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item style={{ marginLeft: '15px' }}>
                            <Select
                                value={state.relativeTimeRangeUnit}
                                onChange={handleRelativeTimeRangeSelect}
                                variant="outlined"
                                className={classes.select}
                            >
                                {RelativeTimeRangeUnits.map((item) => (
                                    <MenuItem value={item} key={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item style={{ marginLeft: '15px' }}>
                            <Button variant="outlined" onClick={handleRelativeTimeRangeClick}>
                                APPLY
                            </Button>
                        </Grid>
                    </React.Fragment>
                )}

                {isSelectableDateRange && (
                    <Grid item>
                        {isCustomAbsoluteDateRange && <span className={classes.label}>From:</span>}
                        <DatePicker
                            allowKeyboardControl
                            minDate={MinDate}
                            minDateMessage={null}
                            maxDate={MaxDate}
                            variant="inline"
                            autoOk
                            views={views}
                            value={state.startDate}
                            onChange={handleStartDateChange}
                            InputProps={{
                                classes: {
                                    root: classes.label,
                                    input: state.value === 'Week' ? classes.inputRootWeek : classes.inputRoot
                                },
                                endAdornment: <ArrowDropDownIcon />
                            }}
                            labelFunc={formatLabel}
                        />
                    </Grid>
                )}

                {isCustomAbsoluteDateRange && (
                    <Grid item style={{ marginLeft: '15px' }}>
                        <span className={classes.label}>To:</span>

                        <DatePicker
                            allowKeyboardControl
                            minDate={MinDate}
                            minDateMessage={null}
                            maxDate={MaxDate}
                            variant="inline"
                            autoOk
                            views={views}
                            value={state.stopDate}
                            onChange={handleStopDateChange}
                            InputProps={{
                                classes: {
                                    root: classes.label,
                                    input: classes.inputRoot
                                },
                                endAdornment: <ArrowDropDownIcon />
                            }}
                            labelFunc={formatLabel}
                        />
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
};

TimeRangeSelect.defaultProps = {
    updateInterval: 1800000
};

export default TimeRangeSelect;
