import CompanyData, { Company, Department } from './CompanyData';

export const FETCH_COMPANIES_IS_LOADING = 'FETCH_MESSAGES_IS_LOADING';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'FETCH_MESSAGES_ERROR';

export const COMPANY_SELECTION = 'COMPANY_SELECTION';
export const DEPARTMENT_SELECTION = 'DEPARTMENT_SELECTION';

export interface CompanyDictionary {
    [companyID: string]: Company;
}

export interface DepartmentDictionary {
    [departmentID: string]: Department;
}

export interface CompaniesState {
    status: number | null;
    data: CompanyData | null;
    isLoading: boolean | null;
    companyDictionary: CompanyDictionary | null;
    departmentDictionary: DepartmentDictionary | null;
    selectedCompany: Company | null;
    selectedDepartment: Department | null;
}

interface FetchCompaniesIsLoadingAction {
    type: typeof FETCH_COMPANIES_IS_LOADING;
}

interface FetchCompaniesSuccessAction {
    type: typeof FETCH_COMPANIES_SUCCESS;
    status: number;
    data: CompanyData;
    companyDictionary: CompanyDictionary;
    departmentDictionary: DepartmentDictionary;
}

interface FetchCompaniesErrorAction {
    type: typeof FETCH_COMPANIES_ERROR;
    status: number;
}

export type FetchCompaniesActionType =
    | FetchCompaniesIsLoadingAction
    | FetchCompaniesSuccessAction
    | FetchCompaniesErrorAction;

interface CompanySelectionAction {
    type: typeof COMPANY_SELECTION;
    company: Company | null;
}

interface DepartmentSelectionAction {
    type: typeof DEPARTMENT_SELECTION;
    department: Department | null;
}

export type SelectCompanyActionType = CompanySelectionAction | DepartmentSelectionAction;
