import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import React from 'react';

const dateFormat = require('dateformat');

export interface FeedItemProps {
    text: string;
    time: Date;
    key?: any;
    icon?: any;
}

// Several of these styles are borrowed from https://material-ui.com/components/app-bar/#PrimarySearchAppBar.js
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '15px',
            width: '100%'
        },
        messageClassName: {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff',
            paddingBottom: '7px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        messageHighlightClassName: {
            fontWeight: 'bold',
            color: '#53b2fc'
        },
        timestampClassname: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff'
        },
        description: {
            marginLeft: '27px',
            width: 'calc(100% - 27px - 24px)'
        }
    })
);

const FeedItem: React.FC<FeedItemProps> = ({ text, time, icon }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {icon ? icon : null}
            <div className={classes.description}>
                <div className={classes.messageClassName}>{text}</div>
                <Tooltip title={dateFormat(time, 'dddd, mmmm d, yyyy @ h:MM:ss.l TT Z')}>
                    <span className={classes.timestampClassname}>{dateFormat(time, 'hh:MM TT')}</span>
                </Tooltip>
            </div>
        </div>
    );
};

export default FeedItem;
