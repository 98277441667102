import { DOCUMENT_MIMETYPES, IMAGE_MIMETYPES, MessageType } from '../../../types/AnalyticsApi';
import Chart from '../Chart';
import { ChartOptions } from '../Charts/ChartBase';
import FilesCreatedChart from '../Charts/FilesCreatedChart';
import MessagesSentChart from '../Charts/MessagesSentChart';
import UserChart from '../Charts/UsersChart';
import Page from '../Page';
import Report from '../Report';
import FilteredReportBuilder from './FilteredReportBuilder';

/**
 * Used to build a report with the following charts:
 *
 * - Total users (Cumulative sum)
 * - Messages sent
 * - Photos sent
 * - Photos captured
 * - Documents sent
 * - Documents created
 * - Messages sent per company/department
 * - Photos captured per company/department
 * - Photos sent per company/department
 * - Documents created per company/department
 * - Documents sent per company/department
 */
class WorkplaceReportBuilder extends FilteredReportBuilder {
    private report: Report = new Report();

    constructor(filters: ChartOptions) {
        super(filters);
        this.report.setHeading(this.heading).setDescription(this.generateDescription());
    }

    public build(): Report {
        let pages: Chart[][] = [
            [new UserChart(this.filters, {}).setTitle('Total Users')],
            [
                new MessagesSentChart(this.filters, {
                    type: [MessageType.NULL]
                }).setTitle('Messages Sent'),
                new FilesCreatedChart(this.filters, {
                    mimeType: IMAGE_MIMETYPES
                }).setTitle('Photos Captured'),
                new MessagesSentChart(this.filters, {
                    type: [MessageType.Photo]
                }).setTitle('Photos Sent')
            ],
            [
                new FilesCreatedChart(this.filters, {
                    mimeType: DOCUMENT_MIMETYPES
                }).setTitle('Documents Created'),
                new MessagesSentChart(this.filters, {
                    type: [MessageType.PatientFile]
                }).setTitle('Documents Sent')
            ],
            [
                new MessagesSentChart(this.groupedFilters, {
                    type: [MessageType.NULL]
                }).setTitle('Messages Sent' + this.groupingSuffix),
                new FilesCreatedChart(this.groupedFilters, {
                    mimeType: IMAGE_MIMETYPES
                }).setTitle('Photos Captured' + this.groupingSuffix),
                new MessagesSentChart(this.groupedFilters, {
                    type: [MessageType.Photo]
                }).setTitle('Photos Sent' + this.groupingSuffix)
            ],
            [
                new FilesCreatedChart(this.groupedFilters, {
                    mimeType: DOCUMENT_MIMETYPES
                }).setTitle('Documents Created' + this.groupingSuffix),
                new MessagesSentChart(this.groupedFilters, {
                    type: [MessageType.PatientFile]
                }).setTitle('Documents Sent' + this.groupingSuffix)
            ]
        ];

        pages.forEach((page) => {
            let p = new Page();
            page.forEach((chart) => p.addChart(chart));
            this.report.addPage(p);
        });

        return this.report;
    }
}

export default WorkplaceReportBuilder;
