import { SetPermissionsActionType, SET_COMPANY_PERMISSIONS, SET_SUPER_ADMIN_STATUS } from '../../types/Permissions';

export const setSuperAdminStatusAction = (isSuperAdmin: boolean): SetPermissionsActionType => {
    return {
        type: SET_SUPER_ADMIN_STATUS,
        isSuperAdmin
    };
};

export const setCompanyPermissionsAction = (companyIds: string[]): SetPermissionsActionType => {
    return {
        type: SET_COMPANY_PERMISSIONS,
        companyIds: companyIds.map((id) => id.toUpperCase())
    };
};

export const setSuperAdminStatus = (isSuperAdmin: boolean) => {
    return (dispatch: (action: object) => void) => {
        dispatch(setSuperAdminStatusAction(isSuperAdmin));
    };
};

export const setCompanyPermissions = (companyIds: string[]) => {
    return (dispatch: (action: object) => void) => {
        dispatch(setCompanyPermissionsAction(companyIds));
    };
};
