import React from 'react';
import { GET_DASHBOARD_ENDPOINT, GET_TOTAL_DASHBOARD_ENDPOINT } from '../../types/AnalyticsApi';
import CrossSectionalData from '../../types/CrossSectionalData';
import { DashboardOptions, DataProviderOptions } from '../../types/DataProvider';
import TimeSeriesData from '../../types/TimeseriesData';
import DataProvider, { OptionalDataProviderProps } from './DataProvider';

interface DashboardDataProviderProps extends OptionalDataProviderProps {
    children: any;
    options?: DashboardOptions;
    crossSection?: boolean;
}

const DashboardDataProvider: React.FC<DashboardDataProviderProps> = ({ children, options, crossSection, ...other }) => {
    const crossSectionResponseHandler = (res: any, options: DataProviderOptions): CrossSectionalData => {
        let crossSectionalData: CrossSectionalData = [];

        crossSectionalData.push({
            key: 'all',
            value: res.data
        });

        return crossSectionalData;
    };

    const timeSeriesResponseHandler = (res: any, options: DataProviderOptions): TimeSeriesData => {
        let timeSeriesData: TimeSeriesData = [];

        for (let item of res.data) {
            timeSeriesData.push({
                key: item.key,
                value: [
                    {
                        key: 'All',
                        value: item.value
                    }
                ]
            });
        }

        return timeSeriesData;
    };

    return (
        <DataProvider
            dataFormat={crossSection ? 'CROSS_SECTION' : 'TIME_SERIES'}
            src={crossSection ? GET_TOTAL_DASHBOARD_ENDPOINT : GET_DASHBOARD_ENDPOINT}
            options={options}
            responseHandler={crossSection ? crossSectionResponseHandler : timeSeriesResponseHandler}
            {...other}
        >
            {children}
        </DataProvider>
    );
};

export default DashboardDataProvider;
