import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider, processSilentRenew } from 'redux-oidc';
import App from './App';
import './index.css';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import userManager from './utils/userManager';
import appInfo from '../package.json';
import { clearSignOutFlag } from './components/Auth';

clearSignOutFlag();

const versionText = 'Version ' + appInfo.version;

console.log('-'.repeat(versionText.length) + '\n\n' + versionText + '\n\n' + '-'.repeat(versionText.length));

// Silent renew idea from: https://github.com/facebook/create-react-app/issues/4480#issuecomment-411444774
if (window.location.pathname === '/silent-renew.html') {
    processSilentRenew();
} else {
    ReactDOM.render(
        <Provider store={store}>
            <OidcProvider store={store} userManager={userManager}>
                <App />
            </OidcProvider>
        </Provider>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
