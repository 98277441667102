import { saveAs } from 'file-saver';
import { PageOptions, ReportOptions } from '../../types/ReportsApi';
import { generateReport } from './lib';
import Page from './Page';

class Report {
    private heading?: string;
    private description?: string;
    private pages: Page[] = [];

    public setHeading(heading: string) {
        this.heading = heading;
        return this;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public addPage(page: Page) {
        this.pages.push(page);
        return this;
    }

    public async save(filename: string, abortController: AbortController) {
        let options = await this.getReportOptions(abortController)
        let report = await generateReport(options, abortController)
        saveAs(report, filename);
        return this;
    }

    public async getReportOptions(abortController: AbortController): Promise<ReportOptions> {
        let pageOptions = await this.getPageOptions(abortController);
        let options: ReportOptions = {
            heading: this.heading,
            description: this.description,
            pages: pageOptions
        };
        return options;
    }

    private getPageOptions(abortController: AbortController): Promise<PageOptions[]> {
        let promises = this.pages.map((page) => page.getPageOptions(abortController));
        return Promise.all(promises);
    }
}

export default Report;