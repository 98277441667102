const baseUrl = process.env.REACT_APP_SUPERADMIN_URL;

const USER_PROFILE_URL = `${baseUrl}/user/`;
const WORKPLACE_URL = `${baseUrl}/workplaces/`;

export const CreateUserProfileUrl = (userId: string) => {
    return USER_PROFILE_URL + userId;
};

export const CreateWorkplaceUrl = (companyId: string) => {
    return WORKPLACE_URL + companyId + '?page=info';
};
