import { INITIALIZE_MESSAGE_STREAM, MessageStreamState } from '../../types/MessageStream';
import { StoreState } from '../../types/Store';

const initialState: MessageStreamState = null;

const messageStreamReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case INITIALIZE_MESSAGE_STREAM:
            return action.messageStream;
        default:
            return state;
    }
};

export default messageStreamReducer;

export const getMessageStream = (state: StoreState) => state.messageStream;
