import { TimeSeriesDataPoint } from '../../types/CompositeDataProvider';
import { ChartOptions, ChartType } from '../../types/ReportsApi';

abstract class Chart {
    private title?: string;
    private description?: string;
    private percentage?: boolean;
    private xAxisLabel?: string;
    private yAxisLabel?: string;
    private legends?: boolean;
    private legendFontSize?: number;
    private valueLabels?: boolean = true;
    private valueLabelFontSize?: number;
    private chartType?: ChartType;
    private yAxisMinValue?: number;
    private yAxisMaxValue?: number;
    private barWidth?: number;

    public setTitle(title: string) {
        this.title = title;
        return this;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public setPercentage(percentage: boolean) {
        this.percentage = percentage;
        return this;
    }

    public setXAxisLabel(label: string) {
        this.xAxisLabel = label;
        return this;
    }

    public setYAxisLabel(label: string) {
        this.yAxisLabel = label;
        return this;
    }

    public setLegends(legends: boolean = true) {
        this.legends = legends;
        return this;
    }

    public setLegendFontSize(size: number) {
        this.legendFontSize = size;
        return this;
    }

    public setValueLabels(valueLabels: boolean = true) {
        this.valueLabels = valueLabels;
        return this;
    }

    public setValueLabelFontSize(size: number) {
        this.valueLabelFontSize = size;
        return this;
    }

    public setChartType(chartType: ChartType) {
        this.chartType = chartType;
        return this;
    }

    public setYAxisMinValue(value: number) {
        this.yAxisMinValue = value;
        return this;
    }

    public setYAxisMaxValue(value: number) {
        this.yAxisMaxValue = value;
        return this;
    }

    public setBarWidth(width: number) {
        this.barWidth = width;
        return this;
    }

    public async getChartOptions(abortController: AbortController): Promise<ChartOptions> {
        let data = await this.getData(abortController);
        return {
            data,
            title: this.title,
            description: this.description,
            percentage: this.percentage,
            xAxisLabel: this.xAxisLabel,
            yAxisLabel: this.yAxisLabel,
            legends: this.legends,
            legendFontSize: this.legendFontSize,
            valueLabels: this.valueLabels,
            valueLabelFontSize: this.valueLabelFontSize,
            chartType: this.chartType,
            yAxisMinValue: this.yAxisMinValue,
            yAxisMaxValue: this.yAxisMaxValue,
            barWidth: this.barWidth
        };
    }

    /**
     * Returns an array of data points to be displayed on this chart.
     */
    protected abstract getData(abortController: AbortController): Promise<TimeSeriesDataPoint[]>;
}

export default Chart;
