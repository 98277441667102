import {
    createStyles,
    Drawer,
    fade,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    TextField,
    Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import matchSorter from 'match-sorter';
import React, { ChangeEvent, useEffect, useState } from 'react';
import ReactList from 'react-list';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCompany } from '../../redux/actions/selectCompanyActions';
import { getCompanies } from '../../redux/reducers/companiesReducer';
import { getSelectedCountries } from '../../redux/reducers/countryReducer';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import { CompaniesState } from '../../types/Companies';
import { Company } from '../../types/CompanyData';
import { Country } from '../../types/Countries';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';
import CenteredProgress from '../CenteredProgress';

interface CompanyDrawerProps {
    selectCompany: (company: Company | null) => void;
    companies: CompaniesState;
    countries: Country[] | null;
    permissions: PermissionsState;
}

interface CompanyDrawerState {
    open: boolean;
    selectedCompanyId: string | null;
    selectedCompany: Company | null;
    inputValue: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        companyDrawer: {
            width: '355px',
            padding: '0 0 68px 0'
        },
        companyList: {
            marginTop: '98px',
            padding: 0
        },
        companyListItem: {
            padding: '10px 39px'
        },
        companyListItemText: {
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff'
        },
        companyDrawerSearch: {
            padding: '10px 39px',
            marginBottom: '10px',
            pointerEvents: 'all'
        },
        companyDrawerInputRoot: {
            height: '38px',
            width: '100%',
            backgroundColor: fade(theme.palette.common.white, 0.05),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.1)
            }
        },
        searchIcon: {
            display: 'flex',
            alignItems: 'center',
            paddingRight: '5px'
        },
        spacer: {
            position: 'fixed',
            paddingTop: '30px',
            minHeight: '70px',
            width: '100%',
            backgroundColor: 'inherit',
            zIndex: theme.zIndex.drawer + 1,
            pointerEvents: 'none'
        },
        closeIcon: {},
        closeButton: {
            top: '5px',
            right: '10px',
            'z-index': theme.zIndex.drawer + 2,
            position: 'absolute'
        }
    })
);

const CompanyDrawer: React.FC<CompanyDrawerProps> = ({ companies, selectCompany, countries, permissions }) => {
    const [state, setState] = useState<CompanyDrawerState>({
        open: false,
        selectedCompanyId: null,
        selectedCompany: null,
        inputValue: ''
    });
    const classes = useStyles();

    useEffect(() => {
        setState((prevState: CompanyDrawerState) => ({
            ...prevState,
            selectedCompanyId: companies.selectedCompany?.id ?? null,
            selectedCompany: companies.selectedCompany
        }));
    }, [companies.selectedCompany]);

    const toggleCompanyDrawer = (e: any) => {
        setState((prevState: CompanyDrawerState) => ({
            ...prevState,
            open: !prevState.open
        }));
    };

    const handleCompanyListClick = (e: any, id: string | null, company: Company | null) => {
        selectCompany(company);
        setState((prevState: CompanyDrawerState) => ({
            ...prevState,
            open: false,
            selectedCompanyId: id,
            selectedCompany: company
        }));
    };

    const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setState((prevState: CompanyDrawerState) => ({
            ...prevState,
            inputValue: value
        }));
    };

    let drawerContent;
    if ((companies && companies.isLoading) || companies.data == null) {
        drawerContent = <CenteredProgress />;
    } else {
        let matchingItems = companies.data;

        // Create items
        if (companies && companies.data) {
            let filtered = companies.data;

            if (countries) {
                let countryCodes = countries.map((country) => country.code?.toUpperCase());
                filtered = filtered.filter((company) => {
                    return company.countryCode && countryCodes.includes(company.countryCode.toUpperCase());
                });
            }

            matchingItems = matchSorter(filtered, state.inputValue.trim(), {
                keys: ['name', 'id']
            });
        }

        const RenderRow = (index: number) => {
            let company = matchingItems[index];
            return (
                <ListItem
                    className={classes.companyListItem}
                    button
                    onClick={(e: any) => handleCompanyListClick(e, company.id, company)}
                    selected={state.selectedCompanyId === company.id}
                    key={company.id}
                >
                    <ListItemText primary={company.name} />
                </ListItem>
            );
        };

        drawerContent = (
            <React.Fragment>
                <IconButton onClick={toggleCompanyDrawer} size={'small'} className={classes.closeButton}>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>

                <div className={classes.spacer}>
                    <TextField
                        id="outlined-name"
                        placeholder="Search..."
                        value={state.inputValue}
                        onChange={handleInput}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                root: classes.companyDrawerInputRoot
                            },
                            startAdornment: (
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                            )
                        }}
                        className={classes.companyDrawerSearch}
                    />
                </div>

                <List className={classes.companyList}>
                    {permissions.isSuperAdmin && (
                        <ListItem
                            className={classes.companyListItem}
                            button
                            onClick={(e: any) => handleCompanyListClick(e, null, null)}
                            selected={state.selectedCompanyId === null}
                        >
                            <ListItemText primary="Celo" className={classes.companyListItemText} />
                        </ListItem>
                    )}
                </List>

                <ReactList
                    itemRenderer={RenderRow}
                    length={matchingItems.length}
                    type="simple"
                    minSize={25}
                    threshold={500}
                    pageSize={50}
                />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <IconButton onClick={toggleCompanyDrawer}>
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={state.open}
                onClose={toggleCompanyDrawer}
                classes={{
                    paper: classes.companyDrawer
                }}
            >
                {drawerContent}
            </Drawer>
        </React.Fragment>
    );
};

const mapStateToProps = (state: StoreState) => ({
    companies: getCompanies(state),
    countries: getSelectedCountries(state),
    permissions: getPermissions(state)
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            selectCompany: selectCompany
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDrawer);
