import { GET_COMPANIES_ENDPOINT } from '../../types/AnalyticsApi';
import {
    CompanyDictionary,
    DepartmentDictionary,
    FetchCompaniesActionType,
    FETCH_COMPANIES_ERROR,
    FETCH_COMPANIES_IS_LOADING,
    FETCH_COMPANIES_SUCCESS
} from '../../types/Companies';
import CompanyData, { Company } from '../../types/CompanyData';
import { StoreState } from '../../types/Store';
import { getCompanies } from '../reducers/companiesReducer';
import { getPermissions } from '../reducers/permissionsReducer';
import { selectCompany } from './selectCompanyActions';

export const fetchCompaniesIsLoadingAction = (): FetchCompaniesActionType => {
    return {
        type: FETCH_COMPANIES_IS_LOADING
    };
};

export const fetchCompaniesSuccessAction = (
    status: number,
    data: CompanyData,
    companyDictionary: CompanyDictionary,
    departmentDictionary: DepartmentDictionary
): FetchCompaniesActionType => {
    return {
        type: FETCH_COMPANIES_SUCCESS,
        status,
        data,
        companyDictionary,
        departmentDictionary
    };
};

export const fetchCompaniesError = (status: number): FetchCompaniesActionType => {
    return {
        type: FETCH_COMPANIES_ERROR,
        status
    };
};

const createCompaniesDictionary = (data: Company[]): CompanyDictionary => {
    let dict: any = {};

    for (let company of data) {
        dict[company.id] = company;
    }

    return dict;
};

const createDepartmentsDictionary = (data: Company[]): DepartmentDictionary => {
    let dict: any = {};

    for (let company of data) {
        for (let department of company.departments) {
            dict[department.id] = department;
        }
    }

    return dict;
};

export const fetchCompanies = () => {
    return (dispatch: (action: object) => void, getState: () => StoreState) => {
        let state = getState();
        let permissions = getPermissions(state);
        let companies = getCompanies(state);

        if (companies.isLoading || companies.data) {
            return;
        }

        dispatch(fetchCompaniesIsLoadingAction());
        fetch(GET_COMPANIES_ENDPOINT, {
            headers: {
                Authorization: 'Bearer ' + (state.oidc.user ? state.oidc.user.access_token : '')
            }
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(fetchCompaniesError(res.status));
                    throw res.statusText;
                }
                return res.json();
            })
            .then((res) => {
                res.data.sort((a: Company, b: Company) => a.name.localeCompare(b.name));
                let companyDictionary = createCompaniesDictionary(res.data);
                let departmentDictionary = createDepartmentsDictionary(res.data);

                // Automatically select the first company this user is authorized to view if they are not a super admin
                if (!permissions.isSuperAdmin && permissions.companyIds.length > 0) {
                    let companyId = permissions.companyIds[0];
                    dispatch(selectCompany(companyDictionary[companyId]));
                }

                dispatch(fetchCompaniesSuccessAction(res.status, res.data, companyDictionary, departmentDictionary));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export default fetchCompanies;
