import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import companiesReducer from './companiesReducer';
import countryReducer from './countryReducer';
import filtersReducer from './filtersReducer';
import messageStreamReducer from './messageStreamReducer';
import permissionsReducer from './permissionsReducer';

export const rootReducer = combineReducers({
    companies: companiesReducer,
    messageStream: messageStreamReducer,
    filters: filtersReducer,
    oidc: oidcReducer,
    country: countryReducer,
    permissions: permissionsReducer
});
