import moment from 'moment';
import React, { useEffect, useState } from 'react';
import UsersDataProvider from '../DataProviders/UsersDataProvider';
import MetricContainer from '../Metric/MetricContainer';

interface DashboardUsersOnlineMetricProps {
    countryCode: string[] | null;
    companyId: string[] | null;
    departmentId: string[] | null;
    updateInterval?: number;
}

interface DashboardUsersOnlineMetricState {
    time: Date;
    isFirstLoad: boolean;
}

const DashboardUsersOnlineMetric: React.FC<DashboardUsersOnlineMetricProps> = (props) => {
    const [state, setState] = useState<DashboardUsersOnlineMetricState>({
        time: new Date(),
        isFirstLoad: false
    });

    const options = {
        countryCode: props.countryCode,
        companyId: props.companyId,
        departmentId: props.departmentId
    };

    // Update range every once in awhile if needed
    useEffect(() => {
        if (!props.updateInterval) return;

        const timeout = setInterval(() => {
            setState((prevState: DashboardUsersOnlineMetricState) => ({
                time: new Date(),
                isFirstLoad: true
            }));
        }, props.updateInterval);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.updateInterval]);

    return (
        <UsersDataProvider
            active
            crossSection
            options={{
                ...options,
                from: moment(state.time).subtract(60, 'seconds').toDate().toISOString()
            }}
            forcedUpdateIntervalMilliseconds={null}
        >
            <MetricContainer renderLoadingIndicator={() => '-'} disableLoadingIndicator={state.isFirstLoad} />
        </UsersDataProvider>
    );
};

DashboardUsersOnlineMetric.defaultProps = {
    updateInterval: 5000 // 5 seconds
};

export default DashboardUsersOnlineMetric;
