import React from 'react';
import { AnalyticsEndpoint, EventString } from '../../types/AnalyticsApi';
import { DataFormat } from '../../types/CompositeDataProvider';
import CrossSectionalData from '../../types/CrossSectionalData';
import { DataProviderOptions } from '../../types/DataProvider';
import TimeSeriesData from '../../types/TimeseriesData';
import CompositeDataProvider from './CompositeDataProvider';

export interface RequiredDataProviderProps {
    dataFormat: DataFormat;
    src: AnalyticsEndpoint;
    responseHandler: (res: any, options: DataProviderOptions) => TimeSeriesData | CrossSectionalData;
    debug?: boolean;
}

export interface OptionalDataProviderProps {
    children?: any;
    options?: DataProviderOptions;
    updateOnEventStrings?: EventString[];
    throttleMilliseconds?: number;
    forcedUpdateIntervalMilliseconds?: number | null;
}

export interface DataProviderProps extends RequiredDataProviderProps, OptionalDataProviderProps {}

const DataProvider: React.FC<DataProviderProps> = (props) => {
    const { children, src, responseHandler, ...other } = props;
    return (
        <CompositeDataProvider
            merger={(handledData) => {
                // We can assume that there's only one data source as this component only allows one to be specified
                let data = handledData[0].data;
                let parsedData = responseHandler(data, props.options ? props.options : {});
                return parsedData;
            }}
            sources={[
                {
                    id: 'dataSource',
                    handler: (res) => res,
                    src
                }
            ]}
            {...other}
        >
            {children}
        </CompositeDataProvider>
    );
};

export default DataProvider;
