import {
    GroupingMethod,
    IdentityVerificationStatus,
    ProfessionVerificationStatus,
    TimeInterval,
    WorkplaceVerificationStatus
} from './AnalyticsApi';

export const FILTER_FROM = 'SET_FILTER_FROM';
export const FILTER_TO = 'SET_FILTER_TO';
export const FILTER_INTERVAL = 'SET_FILTER_INTERVAL';
export const FILTER_GROUP_BY = 'SET_FILTER_GROUP_BY';

export const FILTER_PROFESSION_VERIFICATION_STATUS = 'FILTER_PROFESSION_VERIFICATION_STATUS';
export const FILTER_IDENTITY_VERIFICATION_STATUS = 'FILTER_IDENTITY_VERIFICATION_STATUS';
export const FILTER_WORKPLACE_VERIFICATION_STATUS = 'FILTER_WORKPLACE_VERIFICATION_STATUS';

export interface FiltersState {
    from: string | null;
    to: string | null;
    interval: TimeInterval | null;
    groupBy: GroupingMethod | null;
    professionVerificationStatuses: ProfessionVerificationStatus[] | null;
    identityVerificationStatuses: IdentityVerificationStatus[] | null;
    workplaceVerificationStatuses: WorkplaceVerificationStatus[] | null;
}

export interface FilterFromAction {
    type: typeof FILTER_FROM;
    from: string | null;
}

export interface FilterToAction {
    type: typeof FILTER_TO;
    to: string | null;
}

export interface FilterIntervalAction {
    type: typeof FILTER_INTERVAL;
    interval: TimeInterval | null;
}

export interface FilterGroupByAction {
    type: typeof FILTER_GROUP_BY;
    groupBy: GroupingMethod | null;
}

export interface FilterProfessionVerificationStatusesAction {
    type: typeof FILTER_PROFESSION_VERIFICATION_STATUS;
    professionVerificationStatuses: ProfessionVerificationStatus[] | null;
}

export interface FilterIdentityVerificationStatusesAction {
    type: typeof FILTER_IDENTITY_VERIFICATION_STATUS;
    identityVerificationStatuses: IdentityVerificationStatus[] | null;
}

export interface FilterWorkplaceVerificationStatusesAction {
    type: typeof FILTER_WORKPLACE_VERIFICATION_STATUS;
    workplaceVerificationStatuses: WorkplaceVerificationStatus[] | null;
}

export type FilterActionTypes =
    | FilterFromAction
    | FilterToAction
    | FilterIntervalAction
    | FilterGroupByAction
    | FilterProfessionVerificationStatusesAction
    | FilterIdentityVerificationStatusesAction
    | FilterWorkplaceVerificationStatusesAction;
