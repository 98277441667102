import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterWorkplaceVerificationStatus } from '../../redux/actions/filterActions';
import { getWorkplaceVerificationStatus } from '../../redux/reducers/filtersReducer';
import { WorkplaceVerificationStatus } from '../../types/AnalyticsApi';
import FilterSelect, { FilterSelectOption } from './FilterSelect';

const options: FilterSelectOption<WorkplaceVerificationStatus>[] = [
    {
        displayName: 'Unverified',
        value: WorkplaceVerificationStatus.Unverified
    },
    {
        displayName: 'Pending',
        value: WorkplaceVerificationStatus.Pending
    },
    {
        displayName: 'Verified',
        value: WorkplaceVerificationStatus.Verified
    }
];

interface WorkplaceVerificationStatusSelectProps {
    className?: any;
    workplaceVerificationStatus: WorkplaceVerificationStatus | null;
    filterWorkplaceVerificationStatus: (workplaceVerificationStatus: WorkplaceVerificationStatus | null) => void;
}

const WorkplaceVerificationStatusSelect: React.FC<WorkplaceVerificationStatusSelectProps> = ({
    className,
    workplaceVerificationStatus,
    filterWorkplaceVerificationStatus
}) => {
    let value = options.find((option) => option.value === workplaceVerificationStatus) || null;
    return (
        <FilterSelect
            label="Workplace Verification Status"
            className={className}
            value={value}
            options={options}
            setFilter={filterWorkplaceVerificationStatus}
        />
    );
};

const mapStateToProps = (state: any) => ({
    workplaceVerificationStatus: getWorkplaceVerificationStatus(state)
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            filterWorkplaceVerificationStatus: filterWorkplaceVerificationStatus
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceVerificationStatusSelect);
