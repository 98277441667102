import { COMPANY_SELECTION, DEPARTMENT_SELECTION, SelectCompanyActionType } from '../../types/Companies';
import { Department, Company } from '../../types/CompanyData';

export const selectDepartmentAction = (department: Department | null): SelectCompanyActionType => {
    return {
        type: DEPARTMENT_SELECTION,
        department
    };
};

export const selectCompanyAction = (company: Company | null): SelectCompanyActionType => {
    return {
        type: COMPANY_SELECTION,
        company
    };
};

export const selectDepartment = (department: Department | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(selectDepartmentAction(department));
    };
};

export const selectCompany = (company: Company | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(selectCompanyAction(company));
    };
};
