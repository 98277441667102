import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserState } from 'redux-oidc';
import EventStream from '../../notification/EventStream';
import { initializeMessageStream } from '../../redux/actions/messageStreamActions';
import { getMessageStream } from '../../redux/reducers/messageStreamReducer';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';

interface MessageStreamProviderProps {
    children: any;
    messageStream: EventStream | null;
    initializeMessageStream: (token: string) => void;
    permissions?: PermissionsState;
    oidc: UserState;
}

const MessageStreamProvider: React.FC<MessageStreamProviderProps> = ({
    children,
    messageStream,
    initializeMessageStream,
    permissions,
    oidc
}) => {
    useEffect(() => {
        if (!oidc.isLoadingUser && permissions?.isSuperAdmin && oidc.user?.access_token) {
            initializeMessageStream(oidc.user?.access_token);
        }
    }, [initializeMessageStream, messageStream, permissions, oidc.isLoadingUser, oidc.user?.access_token]);

    return (
        <React.Fragment>
            {React.Children.map(children, (child: any) => {
                return React.cloneElement(child, {
                    messageStream: messageStream
                });
            })}
        </React.Fragment>
    );
};

const mapStateToProps = (state: StoreState) => ({
    messageStream: getMessageStream(state),
    permissions: getPermissions(state),
    oidc: state.oidc
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            initializeMessageStream: initializeMessageStream
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageStreamProvider);
