import React from 'react';
import { GET_USERS_ENDPOINT, UsersJoinedMetric } from '../../types/AnalyticsApi';
import { DataPoint, HandledData, TimeSeriesDataPoint } from '../../types/CompositeDataProvider';
import CompositeDataProvider from '../DataProviders/CompositeDataProvider';
import Legend from '../Legends/Legend';
import LineChartContainer from '../LineChart/LineChartContainer';
import ChartSectionContainer from '../Section/ChartSectionContainer';
import SectionChart from '../Section/SectionChart';
import SectionHeader from '../Section/SectionHeader';

interface DashboardNewUserStatsChartProps {
    countryCode?: string[] | null;
    from?: string | null;
    to?: string | null;
    companyId?: string[] | null;
    departmentId?: string[] | null;
}

const addToInterval = (intervals: Map<number, DataPoint[]>, date: number, key: string, value: number) => {
    if (!intervals.has(date)) {
        intervals.set(date, []);
    }

    intervals.get(date)?.push({
        key,
        value
    });
};

const dataMerger = (dataSets: HandledData[]) => {
    let mergedData: TimeSeriesDataPoint[] = [];
    let intervals = new Map<number, DataPoint[]>();

    for (let dataSet of dataSets) {
        if (!dataSet.data) {
            continue;
        }

        for (let data of dataSet.data) {
            addToInterval(intervals, new Date(Date.parse(data.key)).getTime(), dataSet.id, data.value);
        }
    }

    intervals.forEach((value, key) => {
        mergedData.push({
            key: new Date(key),
            value
        });
    });

    return mergedData;
};

const DashboardNewUserStatsChart: React.FC<DashboardNewUserStatsChartProps> = (props) => {
    return (
        <ChartSectionContainer>
            <SectionHeader>New Cold Users vs New Warm Users</SectionHeader>

            <Legend
                items={[
                    {
                        name: 'Cold Users',
                        color: '#ffeda5'
                    },
                    {
                        name: 'Warm Users',
                        color: '#5cefff'
                    }
                ]}
            />

            <SectionChart>
                {/* Three series line chart */}
                <CompositeDataProvider
                    dataFormat="TIME_SERIES"
                    sources={[
                        {
                            id: 'Warm',
                            src: GET_USERS_ENDPOINT,
                            options: {
                                metric: UsersJoinedMetric.Warm,
                                timeSinceUserJoinedSeconds: 86400 // 24 hours
                            },
                            handler: (res: any) => res.data
                        },
                        {
                            id: 'Cold',
                            src: GET_USERS_ENDPOINT,
                            options: {
                                metric: UsersJoinedMetric.Cold,
                                timeSinceUserJoinedSeconds: 86400 // 24 hours
                            },
                            handler: (res: any) => res.data
                        }
                    ]}
                    merger={dataMerger}
                    options={{
                        ...props
                    }}
                    updateOnEventStrings={['USER_JOINED', 'USER_JOINED_COMPANY']}
                    throttleMilliseconds={10000}
                >
                    <LineChartContainer
                        margin={{
                            top: 10,
                            right: 30,
                            left: 75,
                            bottom: 40
                        }}
                    />
                </CompositeDataProvider>
            </SectionChart>
        </ChartSectionContainer>
    );
};

export default DashboardNewUserStatsChart;
