import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface ErrorMessageProps {
    children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 300,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ffffff'
        }
    })
);

/**
 * Renders an error message using a consistent format.
 */
const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => {
    const classes = useStyles();
    return <span className={classes.error}>{children}</span>;
};

export default ErrorMessage;
