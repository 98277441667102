import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface PageHeaderProps {
    children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageHeader: {
            marginBottom: '5px',
            fontFamily: 'Roboto',
            fontSize: '36px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#49a9fc',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    })
);

/**
 * Renders a page header (the thing that goes at the top of the page).
 */
const PageHeader: React.FC<PageHeaderProps> = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.pageHeader}>{children}</div>;
};

export default PageHeader;
