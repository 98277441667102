import moment from 'moment-timezone';
import { User } from 'oidc-client';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EventStream from '../../notification/EventStream';
import { getCompanies } from '../../redux/reducers/companiesReducer';
import { getSelectedCountries } from '../../redux/reducers/countryReducer';
import { getFilterOptions } from '../../redux/reducers/filtersReducer';
import { getMessageStream } from '../../redux/reducers/messageStreamReducer';
import { fetchCohorts } from '../../services/stats/lib';
import { Cohort } from '../../types/Cohort';
import { CompaniesState } from '../../types/Companies';
import { HandledData } from '../../types/CompositeDataProvider';
import { Country } from '../../types/Countries';
import { CohortsOptions, CommonDataProviderOptions, DataProviderOptions } from '../../types/DataProvider';
import { StoreState } from '../../types/Store';
import removeNullValues from '../../utils/removeNullValues';

interface ICohortsDataProviderProps {
    children: any;
    user?: User;
    options?: DataProviderOptions;
    countries: Country[] | null;
    globalFilters: CommonDataProviderOptions;
    companies: CompaniesState;
    messageStream: EventStream | null;
}

interface ICohortsDataProviderState {
    loading: boolean;
    isFirstLoad: boolean;
    error: Error | null;
    data: Cohort[] | null;
}

const CohortsDataProvider: React.FC<ICohortsDataProviderProps> = ({
    children,
    user,
    options,
    globalFilters,
    countries,
    companies,
    messageStream
}) => {
    const [state, setState] = useState<ICohortsDataProviderState>({
        loading: true,
        isFirstLoad: true,
        error: null,
        data: null
    });

    const fetchData = useCallback(
        (abortController: AbortController) => {
            const countryCodes = countries?.map((country) => country.code);

            const queryParams: CohortsOptions = removeNullValues({
                ...globalFilters,
                countryCode: countryCodes,
                companyId: companies.selectedCompany?.id,
                departmentId: companies.selectedDepartment?.id,
                ...options,
                utcOffset: moment.tz.guess()
            });

            setState((prevState) => ({
                ...prevState,
                loading: true
            }));

            fetchCohorts(abortController, queryParams)
                .then((cohorts) => {
                    setState({
                        loading: false,
                        isFirstLoad: false,
                        error: null,
                        data: cohorts
                    });
                })
                .catch((e: HandledData) => {
                    if (e.error?.code === 20) {
                        // Abort error
                        return;
                    }
                    setState({
                        loading: false,
                        isFirstLoad: false,
                        error: e.error ?? null,
                        data: null
                    });
                });
        },
        [options, globalFilters, companies, countries]
    );

    useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController);
        return () => {
            abortController.abort();
        };
    }, [fetchData, options, globalFilters, companies, countries]);

    return (
        <>
            {React.Children.map(children, (child: any) => {
                return React.cloneElement(child, {
                    ...state
                });
            })}
        </>
    );
};

const mapStateToProps = (state: StoreState) => ({
    user: state.oidc.user,
    countries: getSelectedCountries(state),
    globalFilters: getFilterOptions(state),
    companies: getCompanies(state),
    messageStream: getMessageStream(state)
});

export default connect(mapStateToProps)(CohortsDataProvider);
