import {
    GroupingMethod,
    IdentityVerificationStatus,
    ProfessionVerificationStatus,
    TimeInterval,
    WorkplaceVerificationStatus
} from '../../types/AnalyticsApi';
import {
    FilterFromAction,
    FilterGroupByAction,
    FilterIdentityVerificationStatusesAction,
    FilterIntervalAction,
    FilterProfessionVerificationStatusesAction,
    FilterToAction,
    FilterWorkplaceVerificationStatusesAction,
    FILTER_FROM,
    FILTER_GROUP_BY,
    FILTER_IDENTITY_VERIFICATION_STATUS,
    FILTER_INTERVAL,
    FILTER_PROFESSION_VERIFICATION_STATUS,
    FILTER_TO,
    FILTER_WORKPLACE_VERIFICATION_STATUS
} from '../../types/Filters';

export const filterFromAction = (from: string | null): FilterFromAction => {
    return {
        type: FILTER_FROM,
        from
    };
};

export const filterToAction = (to: string | null): FilterToAction => {
    return {
        type: FILTER_TO,
        to
    };
};

export const filterIntervalAction = (interval: TimeInterval | null): FilterIntervalAction => {
    return {
        type: FILTER_INTERVAL,
        interval
    };
};

export const filterGroupByAction = (groupBy: GroupingMethod | null): FilterGroupByAction => {
    return {
        type: FILTER_GROUP_BY,
        groupBy
    };
};

export const filterProfessionVerificationStatusesAction = (
    professionVerificationStatuses: ProfessionVerificationStatus[] | null
): FilterProfessionVerificationStatusesAction => {
    return {
        type: FILTER_PROFESSION_VERIFICATION_STATUS,
        professionVerificationStatuses
    };
};

export const filterIdentityVerificationStatusesAction = (
    identityVerificationStatuses: IdentityVerificationStatus[] | null
): FilterIdentityVerificationStatusesAction => {
    return {
        type: FILTER_IDENTITY_VERIFICATION_STATUS,
        identityVerificationStatuses
    };
};

export const filterWorkplaceVerificationStatusesAction = (
    workplaceVerificationStatuses: WorkplaceVerificationStatus[] | null
): FilterWorkplaceVerificationStatusesAction => {
    return {
        type: FILTER_WORKPLACE_VERIFICATION_STATUS,
        workplaceVerificationStatuses
    };
};

export const filterFrom = (from: string | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(filterFromAction(from));
    };
};

export const filterTo = (to: string | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(filterToAction(to));
    };
};

export const filterInterval = (interval: TimeInterval | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(filterIntervalAction(interval));
    };
};

export const filterGroupBy = (groupBy: GroupingMethod | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(filterGroupByAction(groupBy));
    };
};

const filterProfessionVerificationStatuses = (
    professionVerificationStatuses: ProfessionVerificationStatus[] | null
) => {
    return (dispatch: (action: object) => void) => {
        dispatch(filterProfessionVerificationStatusesAction(professionVerificationStatuses));
    };
};

const filterIdentityVerificationStatuses = (identityVerificationStatuses: IdentityVerificationStatus[] | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(filterIdentityVerificationStatusesAction(identityVerificationStatuses));
    };
};

const filterWorkplaceVerificationStatuses = (workplaceVerificationStatuses: WorkplaceVerificationStatus[] | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(filterWorkplaceVerificationStatusesAction(workplaceVerificationStatuses));
    };
};

const AcceptsSingleValue = <TIn, TOut>(func: (arr: TIn[] | null) => TOut) => {
    return (value: TIn | null) => func(value === null ? null : [value]);
};

export const filterProfessionVerificationStatus = AcceptsSingleValue(filterProfessionVerificationStatuses);
export const filterIdentityVerificationStatus = AcceptsSingleValue(filterIdentityVerificationStatuses);
export const filterWorkplaceVerificationStatus = AcceptsSingleValue(filterWorkplaceVerificationStatuses);
