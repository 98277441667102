import React from 'react';
import { GET_CONNECTIONS_ENDPOINT, GET_TOTAL_CONNECTIONS_ENDPOINT } from '../../types/AnalyticsApi';
import CrossSectionalData from '../../types/CrossSectionalData';
import { ConnectionsOptions, DataProviderOptions } from '../../types/DataProvider';
import TimeSeriesData from '../../types/TimeseriesData';
import { getCompanyOrDepartmentName } from '../../utils/getCompanyOrDepartmentName';
import DataProvider, { OptionalDataProviderProps } from './DataProvider';

interface ConnectionsDataProviderProps extends OptionalDataProviderProps {
    children: any;
    options?: ConnectionsOptions;
    crossSection?: boolean;
}

const timeSeriesResponseHandler = (res: any, options: DataProviderOptions): TimeSeriesData => {
    let timeSeriesData: TimeSeriesData = [];

    for (let item of res.data) {
        if (!(item.value instanceof Array)) {
            timeSeriesData.push({
                key: item.key,
                value: [
                    {
                        key: 'All',
                        value: item.value
                    }
                ]
            });
        } else {
            // Replace IDs with their corresponding name if possible
            for (let subItem of item.value) {
                subItem.key = getCompanyOrDepartmentName(subItem.key);
            }

            timeSeriesData.push(item);
        }
    }

    return timeSeriesData;
};

const ConnectionsDataProvider: React.FC<ConnectionsDataProviderProps> = ({
    children,
    options,
    crossSection,
    ...other
}) => {
    const crossSectionalResponseHandler = (res: any, options: DataProviderOptions): CrossSectionalData => {
        let crossSectionalData: CrossSectionalData = [];

        crossSectionalData.push({
            key: 'all',
            value: res.data
        });

        return crossSectionalData;
    };

    return (
        <DataProvider
            dataFormat={crossSection ? 'CROSS_SECTION' : 'TIME_SERIES'}
            src={crossSection ? GET_TOTAL_CONNECTIONS_ENDPOINT : GET_CONNECTIONS_ENDPOINT}
            options={options}
            responseHandler={crossSection ? crossSectionalResponseHandler : timeSeriesResponseHandler}
            updateOnEventStrings={[
                'CONNECTION_REQUEST_SENT',
                'CONNECTION_REQUEST_ACCEPTED',
                'CONNECTION_REQUEST_DECLINED',
                'CONNECTION_REMOVED'
            ]}
            {...other}
        >
            {children}
        </DataProvider>
    );
};

export default ConnectionsDataProvider;
