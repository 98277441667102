import { GroupingMethod } from '../../../types/AnalyticsApi';
import { getCompanyOrDepartmentName } from '../../../utils/getCompanyOrDepartmentName';
import { ChartOptions } from '../Charts/ChartBase';
import Report from '../Report';
import ReportBuilder from './ReportBuilder';

abstract class FilteredReportBuilder extends ReportBuilder {
    protected heading: string;
    protected groupedFilters: ChartOptions;
    protected groupingSuffix: string = "";
    protected filters: ChartOptions = {
        interval: 'd'
    };

    constructor(filters: ChartOptions) {
        super();

        this.filters = {
            ...this.filters,
            ...filters,
            groupBy: null
        };

        let groupingMethod: GroupingMethod | null = null;
        if (!this.filters.companyId) {
            groupingMethod = 'companyId';
            this.groupingSuffix = ' Per Company';
        } else if (!this.filters.departmentId) {
            groupingMethod = 'departmentId';
            this.groupingSuffix = ' Per Department'
        }

        this.groupedFilters = {
            ...filters,
            groupBy: groupingMethod
        };

        this.heading = this.filters.companyId ? getCompanyOrDepartmentName(this.filters.companyId[0]) : 'Celo';
    }

    public abstract build(): Report;
}

export default FilteredReportBuilder;
