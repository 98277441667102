import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchCompanies from '../../redux/actions/fetchCompaniesActions';
import { getCompanies } from '../../redux/reducers/companiesReducer';
import { CompaniesState } from '../../types/Companies';
import CenteredProgress from '../CenteredProgress';

interface CompaniesDataProviderProps {
    children: any;
    companies: CompaniesState;
    fetchCompanies: () => void;
}

const CompaniesDataProvider: React.FC<CompaniesDataProviderProps> = ({ children, companies, fetchCompanies }) => {
    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    if (companies.isLoading === null || companies.isLoading) {
        return <CenteredProgress />;
    }

    return (
        <React.Fragment>
            {React.Children.map(children, (child: any) => {
                return React.cloneElement(child, {
                    data: companies.data,
                    companies
                });
            })}
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    companies: getCompanies(state)
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            fetchCompanies: fetchCompanies
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesDataProvider);
