import React, { useEffect, useState } from 'react';

const dateFormat = require('dateformat');

export interface ClockProps {}

interface ClockState {
    time: Date;
}

const Clock: React.FC<ClockProps> = () => {
    const [state, setState] = useState<ClockState>({
        time: new Date(Date.now())
    });

    useEffect(() => {
        const timeout: any = setInterval(() => {
            setState((prevState: ClockState) => {
                return {
                    ...prevState,
                    time: new Date(Date.now())
                };
            });
        }, 1000);

        setState((prevState: ClockState) => {
            return {
                ...prevState
            };
        });

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // Today is 17 Feb 2020, 11:08 AM
    let text = 'Today is ' + dateFormat(state.time, 'd mmmm yyyy, h:MM TT');

    return <React.Fragment>{text}</React.Fragment>;
};

export default Clock;
