import React from 'react';
import Odometer from 'react-odometerjs';
import { MetricContainerProps } from './MetricContainer';

export interface MetricProps extends MetricContainerProps {
    value: string | number;
}

/**
 * Displays a string or numeric value.
 */
const Metric: React.FC<MetricProps> = ({ value, percentage, showSign }) => {
    let classNames = [];

    if (percentage) {
        classNames.push('odometer-percentage');
    }

    if (showSign && value > 0) {
        classNames.push('odometer-positive');
    }

    return (
        <span className={classNames.join(' ')}>
            <Odometer value={Number(value)} format="(,ddd)" duration={800} />
        </span>
    );
};

Metric.defaultProps = {};

export default Metric;
