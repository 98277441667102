import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterProfessionVerificationStatus } from '../../redux/actions/filterActions';
import { getProfessionVerificationStatus } from '../../redux/reducers/filtersReducer';
import { ProfessionVerificationStatus } from '../../types/AnalyticsApi';
import FilterSelect, { FilterSelectOption } from './FilterSelect';

const options: FilterSelectOption<ProfessionVerificationStatus>[] = [
    {
        displayName: 'Verified',
        value: ProfessionVerificationStatus.Verified
    },
    {
        displayName: 'Not required',
        value: ProfessionVerificationStatus.VerificationNotRequired
    },
    {
        displayName: 'Unverified',
        value: ProfessionVerificationStatus.Unverified
    },
    {
        displayName: 'Pending',
        value: ProfessionVerificationStatus.Pending
    },
    {
        displayName: 'More information required',
        value: ProfessionVerificationStatus.MoreInfoRequired
    },
    {
        displayName: 'No attempt made',
        value: ProfessionVerificationStatus.NoRecord
    }
];

interface ProfessionVerificationStatusSelectProps {
    className?: any;
    professionVerificationStatus: ProfessionVerificationStatus | null;
    filterProfessionVerificationStatus: (professionVerificationStatus: ProfessionVerificationStatus | null) => void;
}

const ProfessionVerificationStatusSelect: React.FC<ProfessionVerificationStatusSelectProps> = ({
    className,
    professionVerificationStatus,
    filterProfessionVerificationStatus
}) => {
    let value = options.find((option) => option.value === professionVerificationStatus) || null;
    return (
        <FilterSelect
            label="Profession Verification Status"
            className={className}
            value={value}
            options={options}
            setFilter={filterProfessionVerificationStatus}
        />
    );
};

const mapStateToProps = (state: any) => ({
    professionVerificationStatus: getProfessionVerificationStatus(state)
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            filterProfessionVerificationStatus: filterProfessionVerificationStatus
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionVerificationStatusSelect);
