import React from 'react';
import { connect } from 'react-redux';
import { getPermissions } from '../../redux/reducers/permissionsReducer';
import {
    DOCUMENT_MIMETYPES,
    GroupingMethod,
    IMAGE_MIMETYPES,
    MessagesSentMetric,
    MessageType,
    TimeInterval,
    VIDEO_MIMETYPES
} from '../../types/AnalyticsApi';
import { PermissionsState } from '../../types/Permissions';
import { StoreState } from '../../types/Store';
import BarChartContainer from '../BarChart/BarChartContainer';
import FilesDataProvider from '../DataProviders/FilesDataProvider';
import MessagesDataProvider from '../DataProviders/MessagesDataProvider';
import ChartSectionContainer from '../Section/ChartSectionContainer';
import SectionChart from '../Section/SectionChart';
import SectionHeader from '../Section/SectionHeader';

interface DashboardBarChartsProps {
    countryCode: string[] | null;
    from: string | null;
    to: string | null;
    interval: TimeInterval;
    companyId: string[] | null;
    departmentId: string[] | null;
    page?: number;
    permissions: PermissionsState;
}

const DashboardBarCharts: React.FC<DashboardBarChartsProps> = (props) => {
    let chartTitleDescriptor = '';
    let selectedCompanyIds: string[] | null = props.companyId;
    let selectedDepartmentIds: string[] | null = props.departmentId;

    let groupingMethod: GroupingMethod | null = null;
    if (!selectedCompanyIds) {
        groupingMethod = 'companyId';
        chartTitleDescriptor = ' Per Company';
    } else if (!selectedDepartmentIds) {
        groupingMethod = 'departmentId';
        chartTitleDescriptor = ' Per Department';
    }

    let isSuperAdmin = props.permissions.isSuperAdmin;

    if (props.page === 1) {
        return (
            <React.Fragment>
                {isSuperAdmin && (
                    <>
                        <ChartSectionContainer>
                            <SectionHeader>Documents Created{chartTitleDescriptor}</SectionHeader>
                            <SectionChart>
                                <FilesDataProvider
                                    options={{
                                        ...props,
                                        groupBy: groupingMethod,
                                        companyId: selectedCompanyIds,
                                        departmentId: selectedDepartmentIds,
                                        mimeType: DOCUMENT_MIMETYPES
                                    }}
                                >
                                    <BarChartContainer />
                                </FilesDataProvider>
                            </SectionChart>
                        </ChartSectionContainer>

                        <ChartSectionContainer>
                            <SectionHeader>Documents Sent{chartTitleDescriptor}</SectionHeader>
                            <SectionChart>
                                <MessagesDataProvider
                                    options={{
                                        ...props,
                                        groupBy: groupingMethod,
                                        companyId: selectedCompanyIds,
                                        departmentId: selectedDepartmentIds,
                                        type: [MessageType.PatientFile]
                                    }}
                                >
                                    <BarChartContainer />
                                </MessagesDataProvider>
                            </SectionChart>
                        </ChartSectionContainer>
                    </>
                )}

                <ChartSectionContainer>
                    <SectionHeader>Documents Exchanged{chartTitleDescriptor}</SectionHeader>
                    <SectionChart>
                        <MessagesDataProvider
                            options={{
                                ...props,
                                groupBy: groupingMethod,
                                companyId: selectedCompanyIds,
                                departmentId: selectedDepartmentIds,
                                type: [MessageType.PatientFile],
                                metric: MessagesSentMetric.SentTo
                            }}
                        >
                            <BarChartContainer />
                        </MessagesDataProvider>
                    </SectionChart>
                </ChartSectionContainer>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {isSuperAdmin && (
                <ChartSectionContainer>
                    <SectionHeader>Messages Sent{chartTitleDescriptor}</SectionHeader>
                    <SectionChart>
                        <MessagesDataProvider
                            options={{
                                ...props,
                                groupBy: groupingMethod,
                                companyId: selectedCompanyIds,
                                departmentId: selectedDepartmentIds,
                                type: [MessageType.NULL]
                            }}
                        >
                            <BarChartContainer />
                        </MessagesDataProvider>
                    </SectionChart>
                </ChartSectionContainer>
            )}

            <ChartSectionContainer>
                <SectionHeader>Messages Exchanged{chartTitleDescriptor}</SectionHeader>
                <SectionChart>
                    <MessagesDataProvider
                        options={{
                            ...props,
                            groupBy: groupingMethod,
                            companyId: selectedCompanyIds,
                            departmentId: selectedDepartmentIds,
                            type: [MessageType.NULL],
                            metric: MessagesSentMetric.SentTo
                        }}
                    >
                        <BarChartContainer />
                    </MessagesDataProvider>
                </SectionChart>
            </ChartSectionContainer>

            {isSuperAdmin && (
                <>
                    <ChartSectionContainer>
                        <SectionHeader>Photos Captured{chartTitleDescriptor}</SectionHeader>
                        <SectionChart>
                            <FilesDataProvider
                                options={{
                                    ...props,
                                    groupBy: groupingMethod,
                                    companyId: selectedCompanyIds,
                                    departmentId: selectedDepartmentIds,
                                    mimeType: IMAGE_MIMETYPES
                                }}
                            >
                                <BarChartContainer />
                            </FilesDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>

                    <ChartSectionContainer>
                        <SectionHeader>Photos Sent{chartTitleDescriptor}</SectionHeader>
                        <SectionChart>
                            <MessagesDataProvider
                                options={{
                                    ...props,
                                    groupBy: groupingMethod,
                                    companyId: selectedCompanyIds,
                                    departmentId: selectedDepartmentIds,
                                    type: [MessageType.Photo]
                                }}
                            >
                                <BarChartContainer />
                            </MessagesDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>
                </>
            )}

            <ChartSectionContainer>
                <SectionHeader>Photos Exchanged{chartTitleDescriptor}</SectionHeader>
                <SectionChart>
                    <MessagesDataProvider
                        options={{
                            ...props,
                            groupBy: groupingMethod,
                            companyId: selectedCompanyIds,
                            departmentId: selectedDepartmentIds,
                            type: [MessageType.Photo],
                            metric: MessagesSentMetric.SentTo
                        }}
                    >
                        <BarChartContainer />
                    </MessagesDataProvider>
                </SectionChart>
            </ChartSectionContainer>

            {isSuperAdmin && (
                <>
                    <ChartSectionContainer>
                        <SectionHeader>Videos Captured{chartTitleDescriptor}</SectionHeader>
                        <SectionChart>
                            <FilesDataProvider
                                options={{
                                    ...props,
                                    groupBy: groupingMethod,
                                    companyId: selectedCompanyIds,
                                    departmentId: selectedDepartmentIds,
                                    mimeType: VIDEO_MIMETYPES
                                }}
                            >
                                <BarChartContainer />
                            </FilesDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>

                    <ChartSectionContainer>
                        <SectionHeader>Videos Sent{chartTitleDescriptor}</SectionHeader>
                        <SectionChart>
                            <MessagesDataProvider
                                options={{
                                    ...props,
                                    groupBy: groupingMethod,
                                    companyId: selectedCompanyIds,
                                    departmentId: selectedDepartmentIds,
                                    type: [MessageType.Video]
                                }}
                            >
                                <BarChartContainer />
                            </MessagesDataProvider>
                        </SectionChart>
                    </ChartSectionContainer>
                </>
            )}

            <ChartSectionContainer>
                <SectionHeader>Videos Exchanged{chartTitleDescriptor}</SectionHeader>
                <SectionChart>
                    <MessagesDataProvider
                        options={{
                            ...props,
                            groupBy: groupingMethod,
                            companyId: selectedCompanyIds,
                            departmentId: selectedDepartmentIds,
                            type: [MessageType.Video],
                            metric: MessagesSentMetric.SentTo
                        }}
                    >
                        <BarChartContainer />
                    </MessagesDataProvider>
                </SectionChart>
            </ChartSectionContainer>
        </React.Fragment>
    );
};

DashboardBarCharts.defaultProps = {
    page: 0
};

function mapStateToProps(state: StoreState) {
    return {
        permissions: getPermissions(state)
    };
}

export default connect(mapStateToProps)(DashboardBarCharts);
