import { Country, COUNTRY_SELECTION, SelectCountryAction } from '../../types/Countries';

export const selectCountriesAction = (countries: Country[] | null): SelectCountryAction => {
    return {
        type: COUNTRY_SELECTION,
        countries
    };
};

export const selectCountries = (countries: Country[] | null) => {
    return (dispatch: (action: object) => void) => {
        dispatch(selectCountriesAction(countries));
    };
};
