import { PageOptions, ChartOptions } from "../../types/ReportsApi";
import Chart from "./Chart";

class Page {
    private charts: Chart[] = [];
    private width: number;
    private height: number;
    private dpi: number;

    constructor(width: number = 14.33, height: number = 8.5, dpi: number = 72) {
        this.width = width;
        this.height = height;
        this.dpi = dpi;
    }

    public addChart(chart: Chart) {
        this.charts.push(chart);
    }

    public async getPageOptions(abortController: AbortController): Promise<PageOptions> {
        let charts = await this.getChartOptions(abortController);
        return {
            charts,
            dpi: this.dpi,
            height: this.height,
            width: this.width
        };
    }

    private async getChartOptions(abortController: AbortController): Promise<ChartOptions[]> {
        let promises = this.charts.map((chart) => chart.getChartOptions(abortController));
        return Promise.all(promises);
    }
}

export default Page;